import { useDropzone } from 'react-dropzone'

import styled from 'styled-components'
import Image from './Image'
import { useEffect } from 'react'

const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isFocused) {
    return '#2196f3'
  }
  return '#eeeeee'
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 10px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: black;
  font-weight: bold;
  font-size: 1.4rem;
  outline: none;
  transition: border 0.24s ease-in-out;
`

function DropBox ({
  onDrop,
  acceptType,
  isImage,
  thumbanailControl,
  setThumbanailControl,
  setThumbnailFile,
  clickToRemove
}) {
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragAccept,
    isFocused,
    isDragReject
  } = useDropzone({
    multiple: false,
    accept: acceptType,
    onDrop,
    noClick: true,
    noKeyboard: true
  })

  const removeSelectedFile = () => {
    setThumbanailControl({
      showError: true,
      errorMessage: '',
      showImage: false,
      imageData: null
    })
    if(setThumbnailFile){
      setThumbnailFile(null)
    }
    clickToRemove()
  }

  useEffect(() => {
console.log("=============> 123123123", thumbanailControl)
  },[])

  return (
    <>
      <section className='dropbox'>
        <Container
          className='dropbox'
          {...getRootProps({ isDragAccept, isFocused, isDragReject })}
        >
          {thumbanailControl && thumbanailControl?.showImage ? (
            isImage ? (
              <>
                <Image
                  image={thumbanailControl?.imageData}
                  width={'100%'}
                  height={'100%'}
                />
                <button
                  type='button'
                  className='btn'
                  onClick={removeSelectedFile}
                >
                  Click to remove 
                </button>
              </>
            ) : (
              <>File Name</>
            )
          ) : (
            <>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here</p>
              <button type='button' className='btn' onClick={open}>
                Click to select file
              </button>
            </>
          )}
        </Container>
      </section>
      {/* <aside>
        <h4>List</h4>
        <p>{lists}</p>
      </aside> */}
    </>
  )
}

export default DropBox
