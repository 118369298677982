import { useDispatch, useSelector } from 'react-redux'
import NoDataImg from '../../../assets/img/no-data.gif'
import * as React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import NavBar from '../../structure/NavBar'
import { NavBarTop } from '../../structure/NavBarTop'
import VerifiedIcon from '@mui/icons-material/Verified'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { useEffect, useState } from 'react'
import { NormalUserFilters } from './NormalUserFilters'
import { PaidUserFilters } from './PaidUserFilters'
import { NormalUserTable } from './NormalUserTable'
import { PaidUserTable } from './PaidUserTable'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast } from '../../constants/toaster'
import { useParams } from 'react-router-dom'
import { PlayVideoInModal } from '../../custom/PlayVideoInModal'
import Utils from '../../constants/Utils'

function a11yProps (index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

export const UserDetails = props => {
  const [showPlayVideoModal, setShowPlayVideoModal] = useState(false)
  const [videoSrc, setVideoSrc] = useState('')
  const [userDetailsData, setUserDetailsData] = useState({})
  const [recentUserVideos, setRecentUserVideos] = useState([])
  const userData = useSelector(state => state?.userData)
  const dispatch = useDispatch()
  const { id } = useParams()
  useEffect(() => {
    dispatch(updateTopHeader('User Manager'))
    console.log('123123', id)
    if (id) {
      getUserDetails()
      getUserRecentVideos()
    }
  }, [id])

  const getUserDetails = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.userDetails + '/' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        console.log(response?.data)
        setUserDetailsData(response?.data)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const getUserRecentVideos = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.userRecentTop3Videos + '?userId=' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        console.log(response?.data)
        setRecentUserVideos(response?.data)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
        <div className='container-fluid mt--7'>
          <div className='row'>
            <div className='col-md-8'>
              <div className='card'>
                <div className='card-body'>
                  <p className='text-uppercase text-sm'>User Information</p>
                  <div className='row'>
                    <div className='col-md-2' style={{ textAlign: 'left' }}>
                      <label
                        htmlFor='example-text-input'
                        className='form-control-label'
                      >
                        Username
                      </label>
                      <p>{userDetailsData?.userName}</p>
                    </div>
                    <div className='col-md-2' style={{ textAlign: 'left' }}>
                      <label
                        htmlFor='example-text-input'
                        className='form-control-label'
                      >
                        USER ID
                      </label>
                      <p>{userDetailsData?.chatUserId}</p>
                    </div>
                    <div className='col-md-2' style={{ textAlign: 'left' }}>
                      <label
                        htmlFor='example-text-input'
                        className='form-control-label'
                      >
                        Full Name
                      </label>
                      <p>{userDetailsData?.fullName}</p>
                    </div>
                    <div className='col-md-3' style={{ textAlign: 'left' }}>
                      <label
                        htmlFor='example-text-input'
                        className='form-control-label'
                      >
                        Email
                      </label>
                      <p>{userDetailsData?.email}</p>
                    </div>
                    <div className='col-md-3' style={{ textAlign: 'left' }}>
                      <label
                        htmlFor='example-text-input'
                        className='form-control-label'
                      >
                        Mobile
                      </label>
                      <p>
                        {userDetailsData?.ext} {userDetailsData?.contactNumber}
                      </p>
                    </div>
                    <div className='col-md-2' style={{ textAlign: 'left' }}>
                      <label
                        htmlFor='example-text-input'
                        className='form-control-label'
                      >
                        Gender
                      </label>
                      <p>{userDetailsData?.gender}</p>
                    </div>
                    <div className='col-md-2' style={{ textAlign: 'left' }}>
                      <label
                        htmlFor='example-text-input'
                        className='form-control-label'
                      >
                        Date Of Birth
                      </label>
                      <p>{userDetailsData?.dob}</p>
                    </div>
                    <div className='col-md-2' style={{ textAlign: 'left' }}>
                      <label
                        htmlFor='example-text-input'
                        className='form-control-label'
                      >
                        Date Of Registration
                      </label>
                      <p>{Utils.formatDate(userDetailsData?.createdAt)}</p>
                    </div>
                    <div className='col-md-2' style={{ textAlign: 'left' }}>
                      <label
                        htmlFor='example-text-input'
                        className='form-control-label'
                      >
                        Exclusive Enable
                      </label>
                      <p>
                        {userDetailsData?.exclusiveEnable ? (
                          <span className='label label-default'>Enabled</span>
                        ) : (
                          <span className='label label-default'>Disabled</span>
                        )}
                      </p>
                    </div>
                    <div className='col-md-2' style={{ textAlign: 'left' }}>
                      <label
                        htmlFor='example-text-input'
                        className='form-control-label'
                      >
                        Account Type
                      </label>
                      <p>{userDetailsData?.accountType}</p>
                    </div>
                    <div className='col-md-2' style={{ textAlign: 'left' }}>
                      <label
                        htmlFor='example-text-input'
                        className='form-control-label'
                      >
                        Register Via
                      </label>
                      <p>{userDetailsData?.signInType}</p>
                    </div>
                    <div className='col-md-2' style={{ textAlign: 'left' }}>
                      <label
                        htmlFor='example-text-input'
                        className='form-control-label'
                      >
                        Total Chips:
                      </label>
                      <p>{userDetailsData?.totalChipsBalance}</p>
                    </div>
                    <div className='col-md-10' style={{ textAlign: 'left' }}>
                      <label
                        htmlFor='example-text-input'
                        className='form-control-label'
                      >
                        Category:
                      </label>
                      <p>{userDetailsData?.userPreferredTags?.join(",")}</p>
                    </div>
                  </div>
                  <hr className='horizontal dark' />
                  {recentUserVideos && recentUserVideos?.length > 0 ? (
                    <p className='text-uppercase text-sm'>
                      Recent Upload Videos
                    </p>
                  ) : (
                    <></>
                  )}

                  <div class='container'>
                    <div className='row'>
                      {recentUserVideos && recentUserVideos?.length > 0 ? (
                        <>
                          {recentUserVideos?.map((data, i) => {
                            return (
                              <>
                                <div className='col-md-4'>
                                  <div
                                    class='card'
                                    style={{ borderRadius: '15px' }}
                                  >
                                    <img
                                      title='Click to play'
                                      onClick={() => {
                                        setShowPlayVideoModal(
                                          !showPlayVideoModal
                                        )
                                        setVideoSrc(data?.link)
                                      }}
                                      src={data?.thumbnail}
                                      alt='Image 1'
                                      style={{
                                        borderRadius: '15px',
                                        cursor: 'pointer'
                                      }}
                                    />
                                    <div class='card-content'>
                                      <div className='row'>
                                        <div className='col'>
                                          <div
                                            className='d-flex justify-content-center'
                                            style={{ fontWeight: 'bold' }}
                                          >
                                            <div className='d-grid text-center mr-4'>
                                              <span className='text-lg font-weight-bolder'>
                                                {data?.totalLikes}
                                              </span>
                                              <span className='text-sm opacity-8 ml-2'>
                                                Likes
                                              </span>
                                            </div>
                                            <div className='d-grid text-center'>
                                              <span className='text-lg font-weight-bolder'>
                                                {data?.totalComments}
                                              </span>
                                              <span className='text-sm opacity-8 ml-2'>
                                                Comments
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <p class='card-description'>
                                        {data?.caption}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                        </>
                      ) : (
                        <div className='col-md-12'>
                          <div className='text-center'>
                            <img width={'25%'} src={NoDataImg}></img>
                            <p> No Recent Video found</p>
                            <br />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='card card-profile'>
                {/* <img
                  src="https://demos.creative-tim.com/argon-dashboard/assets/img/bg-profile.jpg"
                  alt="Image placeholder"
                  className="card-img-top"
                /> */}
                <div className='row justify-content-center'>
                  <div className='col-4 col-lg-4 order-lg-2'>
                    <div className='mt-n4 mt-lg-n6 mb-4 mb-lg-0'>
                      <a href='javascript:;'>
                        <img
                          src={userDetailsData?.profilePic}
                          className='rounded-circle img-fluid border border-2 border-white'
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='card-header text-center border-0 pt-0 pt-lg-2 pb-4 pb-lg-3'>
                  {userDetailsData?.isBadged ? <VerifiedIcon /> : <></>}
                </div>
                <div className='card-body pt-0'>
                  <div className='row'>
                    <div className='col'>
                      <div
                        className='d-flex justify-content-center'
                        style={{ fontWeight: 'bold' }}
                      >
                        <div className='d-grid text-center'>
                          <span className='text-lg font-weight-bolder'>
                            {userDetailsData?.totalFollowers}
                          </span>
                          <span className='text-sm opacity-8 ml-2'>
                            Followers
                          </span>
                        </div>
                        <div className='d-grid text-center mx-4'>
                          <span className='text-lg font-weight-bolder'>
                            {userDetailsData?.totalFollowing}
                          </span>
                          <span className='text-sm opacity-8 ml-2'>
                            Following
                          </span>
                        </div>
                        <div className='d-grid text-center mr-4'>
                          <span className='text-lg font-weight-bolder'>
                            {userDetailsData?.totalVideos}
                          </span>
                          <span className='text-sm opacity-8 ml-2'>Videos</span>
                        </div>
                        <div className='d-grid text-center'>
                          <span className='text-lg font-weight-bolder'>
                            {userDetailsData?.totalLikes}
                          </span>
                          <span className='text-sm opacity-8 ml-2'>Likes</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-center mt-4'>
                    <h3>
                      {userDetailsData?.fullName}
                      <span className='font-weight-light'>, 35</span>
                    </h3>
                    <div className='h4 font-weight-bold'>
                      <i className='ni location_pin mr-2' />
                      {userDetailsData?.userName}
                    </div>
                    <div>
                      <i className='ni education_hat mr-2' />
                      {userDetailsData?.bio}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PlayVideoInModal
              videoSrc={videoSrc}
              show={showPlayVideoModal}
              onHide={() => setShowPlayVideoModal(!showPlayVideoModal)}
            />
          </div>
        </div>
      </div>
    </>
  )
}
