import Switch from '@mui/material/Switch'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@mui/material/Button'
import dayjs, { Dayjs } from 'dayjs'
import { v4 as uuidv4 } from 'uuid';
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useCallback, useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { ProfileAbuseTable } from './ProfileAbuseTable'
import { VideoAbuseTable } from './VideoAbuseTable'
import { AbuseReportsList } from './AbuseReportsList'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast } from '../../constants/toaster'
import { Box, Pagination } from '@mui/material'
import { isEmpty } from 'lodash'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const ReportAbuseList = (props) => {
  const [callAPi, setCallAPi] = useState('')
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [mediaUIChange, setMediaUIChange] = useState(true)
  const [sort_type, setSortType] = useState('createdAt')
  const [showAbuseReportDetails, setShowAbuseReportDetails] = useState({})
  const [statusDropdownText, setStatusDropdownText] = useState('Profile')
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector((state) => state?.userData)
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [datalist, setDatalist] = useState([])
  const [refreshData, setRefreshData] = useState(false)
  const [page, setPage] = useState(1)
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
    setCallAPi(uuidv4())
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateTopHeader('Report & Abuse Manager'))
  })

  useEffect(() => {
    getReportAbuseList();
  }, [callAPi]);

  const handleFilterClick = useCallback(() => {
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setMediaUIChange(statusDropdownText === 'Profile')
    setCallAPi(uuidv4())
  }, [searchText, statusDropdownText, startDate, endDate]);

  const handleResetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setSearchText('')
    setStatusDropdownText('Profile')
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setMediaUIChange(true)
    setCallAPi(uuidv4())
  }

  const getReportAbuseList = async () => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        userName: searchText?.trim(),
        page: pagerecord?.pageNumber - 1 || 0,
        limit: pagerecord.pageSize || 10,
        sort: sort_type,
        order: sort_order,
        type: statusDropdownText.toUpperCase(),
        startDate:
          startDate ?
            startDate?.toDate()
            : '',
        endDate:
          endDate ? endDate?.toDate() : ''
      }
      let path = urls.reportAbuseList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        setRefreshData(false)
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)
        // response?.data?.permissionList.forEach((module, moduleIndex) => {
        //   if (module.permissionName === 'EDIT') {
        //     setEdit(module.isEnable)
        //   } else if (module.permissionName === 'ACTIVE') {
        //     setActive(module.isEnable)
        //   } else if (module.permissionName === 'DELETE') {
        //     setDeletePermission(module.isEnable)
        //   } else if (module.permissionName === 'VIEW') {
        //     setView(module.isEnable)
        //   }
        // })
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pagerecord?.pageNumber,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
        <div className='container-fluid mt--7'>
          <div className='row'>
            <div className='col text-left'>
              <div className='card shadow'>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col'>
                      <h3 className='mb-0'>Report & Abuse Manager</h3>
                    </div>
                  </div>
                </div>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col-md-3'>
                      <InputGroup
                        className='mb-2'
                        style={{ borderRadius: '10px' }}
                      >
                        <Form.Control
                          placeholder='Search by User Name'
                          aria-label='Amount (to the nearest dollar)'
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                        <InputGroup.Text
                          style={{
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px',
                            borderTopLeftRadius: '0px',
                            borderBottomLeftRadius: '0px'
                          }}
                        >
                          <SearchIcon />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div
                      className='col-md-6 mb-3'
                      style={{ marginLeft: '20px' }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={['DatePicker', 'DatePicker']}
                        >
                          <DatePicker
                            disableFuture
                            className='custom-date'
                            label='From Date'
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                          />
                          <DatePicker
                            disableFuture
                            label='To Date'
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            minDate={startDate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className='col-md-1 mb-2 '>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='success'
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            border: '1px solid #cad1d7',
                            boxShadow: 'none',
                            transform: 'none'
                          }}
                          id='dropdown-basic'
                        >
                          {statusDropdownText}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => setStatusDropdownText('Profile')}
                          >
                            Profile
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setStatusDropdownText('Video')}
                          >
                            Video
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className='col-md-3 mb-2'>
                      <Button
                        variant='contained'
                        className='custom-filter-btn'
                        onClick={handleFilterClick}
                      >
                        Filter
                      </Button>
                      <Button
                        variant='contained'
                        className='ml-2 custom-filter-btn'
                        onClick={handleResetFilter}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </div>
                {mediaUIChange ? (
                  <ProfileAbuseTable
                    setShowAbuseReportDetails={setShowAbuseReportDetails}
                    datalist={datalist}
                    setRefreshData={setRefreshData}
                    pagerecord={pagerecord}
                  />
                ) : (
                  <VideoAbuseTable
                    datalist={datalist}
                    setRefreshData={setRefreshData}
                    setShowAbuseReportDetails={setShowAbuseReportDetails}
                    pagerecord={pagerecord}
                  />
                )}
                {datalist && datalist.length > 0 ? (
                  <div className="card-footer py-4">
                    <Box display="flex" justifyContent="right" alignItems='center'>
                      <span>Total record: {pagerecord?.totalItems}</span>
                      <Pagination
                        className="custom-pagination-class"
                        count={pagerecord?.totalPages}
                        page={page}
                        onChange={handleChange}
                        color="secondary"
                      />
                    </Box>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {!isEmpty(showAbuseReportDetails) && <AbuseReportsList
            show={showAbuseReportDetails}
            onHide={() => setShowAbuseReportDetails({})}
          />}
        </div>
      </div>
    </>
  )
}
