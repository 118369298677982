import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
export const AddPackage = props => {
  const [error, setError] = useState({
    title: false,
    description: false
  })

  const [alignment, setAlignment] = useState('android');

  const handleChange = (
    newAlignment
  ) => {
    props.setPackageFormData({ storeType: newAlignment.target.value })
  };

  const handleSubmit = () => {
    if (
      props?.packageFormData?.storeType === '' &&
      props?.packageFormData.productId === '' && props?.packageFormData.amount === ''
    ) {
      setError({ productId: true, storeType: true, amount: true })
    } else if (props?.packageFormData.storeType === '') {
      setError({ productId: false, storeType: true, amount: false })
    } else if (props?.packageFormData.productId === '') {
      setError({ productId: true, storeType: false, amount: false })
    } else if (props?.packageFormData.amount === '') {
      setError({ productId: false, storeType: false, amount: true })
    } else {
      setError({ productId: false, storeType: false, amount: false })
      props.onSubmit()
    }
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.packageFormData?.id ? 'Update Package' : 'Add Package'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-4'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <h4>Package ID*</h4>
              <Form.Control
                disabled={props?.packageFormData?.id}
                type='text'
                maxLength={20}
                placeholder='Package ID'
                value={props.packageFormData.productId}
                onChange={e => props.setPackageFormData({ productId: e.target.value })}
              />
              {error?.productId ? (
                <label className='custom-error-label'>
                  Package ID is required*
                </label>
              ) : (
                <></>
              )}
            </Form.Group>
          </div>
          <div className='col-md-2'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <h4>Amount*</h4>
              <Form.Control
                type='text'
                maxLength={8}
                placeholder='Amount'
                value={props.packageFormData.amount}
                onChange={e => {
                  // Use a regular expression to check if the input is numeric
                  const numericRegex = /^[0-9\b]+$/
                  // Check if the input value matches the numeric regex
                  if (
                    numericRegex.test(e.target.value) ||
                    e.target.value === ''
                  ) {
                    props.setPackageFormData({ amount: e.target.value })
                  }
                }
                }
              />
              {error?.title ? (
                <label className='custom-error-label'>
                  Amount is required*
                </label>
              ) : (
                <></>
              )}
            </Form.Group>
          </div>
          <div className='col-md-2'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <h4>Chips*</h4>
              <Form.Control
                type='text'
                maxLength={8}
                placeholder='Chips'
                value={props.packageFormData.chips}
                onChange={e => {
                  // Use a regular expression to check if the input is numeric
                  const numericRegex = /^[0-9\b]+$/
                  // Check if the input value matches the numeric regex
                  if (
                    numericRegex.test(e.target.value) ||
                    e.target.value === ''
                  ) {
                    props.setPackageFormData({ chips: e.target.value })
                  }
                }
                }
              />
              {error?.title ? (
                <label className='custom-error-label'>
                  Chips is required*
                </label>
              ) : (
                <></>
              )}
            </Form.Group>
          </div>
          <div className='col-md-4'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <h4>Store*</h4>
              <ToggleButtonGroup
                color="primary"
                value={props?.packageFormData?.storeType}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="android" disabled={props?.packageFormData?.id}>Android</ToggleButton>
                <ToggleButton value="ios" disabled={props?.packageFormData?.id}>iOS</ToggleButton>
              </ToggleButtonGroup>
            </Form.Group>

          </div>
          <div className='col-md-12'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <h4>Description*</h4>
              <Form.Control
                type='text'
                maxLength={120}
                placeholder='Description'
                value={props.packageFormData.description}
                onChange={e => {
                  
                    props.setPackageFormData({ description: e.target.value })
                }
                }
              />
              {error?.title ? (
                <label className='custom-error-label'>
                  Chips is required*
                </label>
              ) : (
                <></>
              )}
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
