import Switch from '@mui/material/Switch'
import NoDataImg from '../../../assets/img/no-data.gif'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast } from '../../constants/toaster'
import Utils from '../../constants/Utils'
import { Box, Pagination } from '@mui/material'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const RegularStreamingTable = props => {
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [page, setPage] = useState(1)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const dispatch = useDispatch()
  const [datalist, setDatalist] = useState([])
  const userData = useSelector(state => state?.userData)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
  }
  const serialNumber = i => {
    return i + 1 + (page - 1) * pagerecord.pageSize || 10
  }

  useEffect(() => {
    if (props?.tabValue === 0) {
      getRegularStream()
    }
  }, [page, props])

  const getRegularStream = async (
    searchTextRef,
    searchTextPlaylistRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchTextRef,
        page: pageNo - 1 || 0,
        limit: pagerecord.pageSize || 10,
        sort: sort_type,
        order: sort_order,
        startDate:
          startDateRef && startDateRef !== null ? startDateRef.toDate() : '',
        endDate: endDateRef && endDateRef !== null ? endDateRef.toDate() : ''
      }
      let path = urls.liveManagerList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })

        // setDatalist(response?.data?.response?.items)
        // response?.data?.permissionList.forEach((module, moduleIndex) => {
        //   if (module.permissionName === 'EDIT') {
        //     setEdit(module.isEnable)
        //   } else if (module.permissionName === 'ACTIVE') {
        //     setActive(module.isEnable)
        //   } else if (module.permissionName === 'DELETE') {
        //     setDeletePermission(module.isEnable)
        //   } else if (module.permissionName === 'VIEW') {
        //     setView(module.isEnable)
        //   }
        // })
        // setPagerecord({
        //   totalItems: response?.data?.response?.totalItems,
        //   totalPages: response?.data?.response?.totalPages,
        //   pageNumber: pageNo,
        //   pageSize: 10
        // })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      {datalist && datalist?.length > 0 ? (
        <>
          <div className='table-responsive'>
            <table className='table align-items-center table-flush'>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>S/No.</th>
                  <th scope='col'>User Name</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Mobile No.</th>
                  <th scope='col'>Total Joined Count</th>
                  <th scope='col'>Current View Count</th>
                  <th scope='col'>Link</th>
                  <th scope='col'>Time</th>
                  <th scope='col'>Actions(Stop)</th>
                </tr>
              </thead>
              <tbody>
                {datalist &&
                  datalist?.map((data, i) => {
                    return (
                      <>
                        <tr>
                          <td>{serialNumber(i)}</td>
                          <td>{data?.userName}</td>
                          <td>{data?.fullName}</td>
                          <td>{data?.mobile}</td>
                          <td>{data?.totalViewersCount}</td>
                          <td>{data?.currentViewsCount}</td>
                          <td>Click To View</td>
                          <td>{data?.live ? 'Live' : 'Ended/YTS'}</td>
                          <td>
                            <Switch {...label} defaultChecked size='small' />
                          </td>
                        </tr>
                      </>
                    )
                  })}
              </tbody>
            </table>
          </div>
          {datalist.length ? (
            <>
              <div className='card-footer py-4'>
                <Box display='flex' justifyContent='right' alignItems='center'>
                  <span>Total record: {pagerecord?.totalItems}</span>
                  <Pagination
                    className='custom-pagination-class'
                    count={pagerecord?.totalPages}
                    page={page}
                    onChange={handleChange}
                    color='secondary'
                  />
                </Box>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <>
            <div className='text-center'>
              <img width={'25%'} src={NoDataImg}></img>
              <br />
              <label style={{ fontWeight: 'bold' }}>
                {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
              </label>
            </div>
          </>
        </>
      )}
    </>
  )
}
