import { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import ApiCaller from '../../constants/ApiCaller'
import { useDispatch, useSelector } from 'react-redux'
import urls from '../../constants/apiurl'
import { showDangerToast, showToast } from '../../constants/toaster'
import NavBar from '../../structure/NavBar'
import { NavBarTop } from '../../structure/NavBarTop'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const GlobalSettingList = (props) => {
  const [object, setObject] = useState([]);
  const [allTypeOfAds, setAllTypeOfAds] = useState([])
  const [formValues, setFormValues] = useState({})
  const userData = useSelector((state) => state?.userData)
  const [datalist, setDatalist] = useState({})
  const [id, setId] = useState('')
  const dispatch = useDispatch()

  const handleInputChange = (e, key) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '')
    setDatalist((prevData) => ({
      ...prevData,
      [key]: newValue
    }))
  }


  const handleRateInputChange = (index, field, value) => {
    // Allow only numbers and decimal points
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value)) {
      const newObject = [...object];
      newObject[index][field] = value;
      setObject(newObject);
    }


  };

  const handleUpdateMatrixSetting = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.setMatrixGlobalWorldRate
      let postJson = {
        data: object
      }
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }


  const setSettingData = async () => {
    console.log(datalist)
    if (
      (datalist?.subscriberChannelMaxPrice &&
        datalist?.subscriberChannelMinPrice &&
        datalist?.subscriberChannelMaxPrice <
        datalist?.subscriberChannelMinPrice) ||
      (datalist?.eventPriceMaxBeads &&
        datalist?.eventPriceMinBeads &&
        datalist?.eventPriceMaxBeads < datalist?.eventPriceMinBeads) ||
      (datalist?.videoMaximumSeconds &&
        datalist?.videoMinimumSeconds &&
        datalist?.videoMaximumSeconds < datalist?.videoMinimumSeconds) ||
      (datalist?.maximumBuyBeads &&
        datalist?.minimumBuyBeads &&
        datalist?.maximumBuyBeads < datalist?.minimumBuyBeads)
    ) {
      showDangerToast('Please fill correction data.')
    } else {
      try {
        dispatch(uplodateLoading(true))
        let path = urls.setGlobalSetting
        let res = await ApiCaller.post(datalist, path, userData?.access_token)
        const response = await res.response.json()
        if (response.status == 200) {
          dispatch(uplodateLoading(false))
          setDatalist(response?.data)
          showToast(response?.message)
        } else {
          dispatch(uplodateLoading(false))
          showDangerToast(response.message)
        }
      } catch (error) {
        dispatch(uplodateLoading(false))
        console.error(error)
        showDangerToast(error.message)
      }
    }
  }


  const getAllTypeOfMatrix = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.getAllTypeOfMatrixGlobalRate
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setAllTypeOfAds(response?.data)
        const initialState = response?.data?.map((data) => ({
          typeId: data.typeId,
          rate: data?.rate
        })) || [];
        setObject(initialState)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }


  useEffect(() => {
    dispatch(updateTopHeader("Global Setting"));
  }, [])
  useEffect(() => {
    getSettingList()
    getAllTypeOfMatrix()
  }, [])

  const getSettingList = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.getGlobalSetting
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
        <div className='container-fluid mt--7'>
          <div className='row'>
            <div className='col text-left'>
              <div className='card shadow'>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col'>
                      <h3 className='mb-0'>Global Setting</h3>
                    </div>
                    <div className='col text-right'>
                      <button
                        onClick={setSettingData}
                        className='btn btn-sm btn-primary text-dark'
                        disabled={
                          (datalist?.subscriberChannelMaxPrice &&
                            datalist?.subscriberChannelMinPrice &&
                            datalist?.subscriberChannelMaxPrice <
                            datalist?.subscriberChannelMinPrice) ||
                          (datalist?.eventPriceMaxBeads &&
                            datalist?.eventPriceMinBeads &&
                            datalist?.eventPriceMaxBeads <
                            datalist?.eventPriceMinBeads) ||
                          (datalist?.videoMaximumSeconds &&
                            datalist?.videoMinimumSeconds &&
                            datalist?.videoMaximumSeconds <
                            datalist?.videoMinimumSeconds) ||
                          (datalist?.maximumWithdrawBeads &&
                            datalist?.minimumWithdrawBeads &&
                            datalist?.maximumWithdrawBeads <
                            datalist?.minimumWithdrawBeads) ||
                          (datalist?.maximumBuyBeads &&
                            datalist?.minimumBuyBeads &&
                            datalist?.maximumBuyBeads <
                            datalist?.minimumBuyBeads)
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Inactive User:</h4>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-5'>
                    <h4>Video Uploading Time:</h4>
                  </div>
                </div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Profile</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.noOfProfileForReport}
                            placeholder='Enter report no. for profile'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['noOfProfileForReport']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Video </Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.noOfVideoForReport}
                            placeholder='Enter report no. for Video'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['noOfVideoForReport']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                  <div
                    className='col-md-5'
                    style={{
                      border:
                        datalist?.videoMaximumSeconds != null &&
                        datalist?.videoMinimumSeconds != null &&
                        (parseInt(datalist?.videoMaximumSeconds) <
                          parseInt(datalist?.videoMinimumSeconds)
                          ? '1px solid red'
                          : '1px solid #f2e2e2')
                    }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Minimum(Sec)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.videoMinimumSeconds}
                            placeholder='Enter Minimum Seconds'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['videoMinimumSeconds']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Maximum(Sec)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.videoMaximumSeconds}
                            placeholder='Enter Maximum Seconds'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['videoMaximumSeconds']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-12 text-center'>
                        {datalist?.videoMaximumSeconds != null &&
                          datalist?.videoMinimumSeconds != null &&
                          parseInt(datalist?.videoMaximumSeconds) <
                          parseInt(datalist?.videoMinimumSeconds) ? (
                          <label className='custom-error-label'>
                            Minimum should be less then Maximum*
                          </label>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Withdraw Limit:</h4>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-5'>
                    <h4>Sign up Chips:</h4>
                  </div>
                </div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{
                      border:
                        datalist?.maximumWithdrawBeads != null &&
                        datalist?.minimumWithdrawBeads != null &&
                        (parseInt(datalist?.maximumWithdrawBeads) <
                          parseInt(datalist?.minimumWithdrawBeads)
                          ? '1px solid red'
                          : '1px solid #f2e2e2')
                    }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Maximum(Amount)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.maximumWithdrawBeads}
                            placeholder='Enter Maximum Withdraw Beads'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['maximumWithdrawBeads']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Minimum(Amount)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.minimumWithdrawBeads}
                            placeholder='Enter Minimum Withdraw Chips'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['minimumWithdrawBeads']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-12 text-center'>
                        {datalist?.maximumWithdrawBeads != null &&
                          datalist?.minimumWithdrawBeads != null &&
                          parseInt(datalist?.maximumWithdrawBeads) <
                          parseInt(datalist?.minimumWithdrawBeads) ? (
                          <label className='custom-error-label'>
                            Minimum should be less then Maximum*
                          </label>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>No of Chips </Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.freeBeadsAfterRegistration}
                            placeholder='Enter Free Chips given after registration'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['freeBeadsAfterRegistration']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Buy Chips Limit:</h4>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-5'>
                    <h4>Shop Subscription Rate:</h4>
                  </div>
                </div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{
                      border:
                        datalist?.maximumBuyBeads != null &&
                        datalist?.minimumBuyBeads != null &&
                        (parseInt(datalist?.maximumBuyBeads) <
                          parseInt(datalist?.minimumBuyBeads)
                          ? '1px solid red'
                          : '1px solid #f2e2e2')
                    }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Maximum(Amount)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.maximumBuyBeads}
                            placeholder='Enter Maximum Withdraw Chips'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['maximumBuyBeads']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Minimum(Amount)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.minimumBuyBeads}
                            placeholder='Enter Minimum Withdraw Chips'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['minimumBuyBeads']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-12 text-center'>
                        {datalist?.maximumBuyBeads != null &&
                          datalist?.minimumBuyBeads != null &&
                          parseInt(datalist?.maximumBuyBeads) <
                          parseInt(datalist?.minimumBuyBeads) ? (
                          <label className='custom-error-label'>
                            Minimum should be less then Maximum*
                          </label>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Monthly Price </Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.monthlyPriceForPostInShop}
                            placeholder='Enter monthly price for post in shop'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['monthlyPriceForPostInShop']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Weekly Price </Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.weeklyPriceForPostInShop}
                            placeholder='Enter Weekly Price for Post in Shop'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['weeklyPriceForPostInShop']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Admin Commission on Withdraw:</h4>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-5'>
                    <h4>Exclusive Pricing:</h4>
                  </div>
                </div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Enter % of commission</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={3}
                            value={datalist?.percentageOfCommission}
                            placeholder='Enter % of commission'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['percentageOfCommission']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                  <div
                    className='col-md-5'
                    style={{
                      border:
                        datalist?.maximumBuyBeads != null &&
                        datalist?.minimumBuyBeads != null &&
                        (parseInt(datalist?.maximumBuyBeads) <
                          parseInt(datalist?.minimumBuyBeads)
                          ? '1px solid red'
                          : '1px solid #f2e2e2')
                    }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Maximum(Amount)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.maximumExclusivePrice}
                            placeholder='Enter Maximum Exclusive Chips'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['maximumExclusivePrice']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Minimum(Amount)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.minimumExclusivePrice}
                            placeholder='Enter Minimum Exclusive Chips'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['minimumExclusivePrice']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-12 text-center'>
                        {datalist?.maximumBuyBeads != null &&
                          datalist?.minimumBuyBeads != null &&
                          parseInt(datalist?.maximumBuyBeads) <
                          parseInt(datalist?.minimumBuyBeads) ? (
                          <label className='custom-error-label'>
                            Minimum should be less then Maximum*
                          </label>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className='col-md-5'
                    style={{
                      border: '1px solid #f2e2e2',
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'center'
                    }}
                  >
                    <div className='beads-info'>
                      Number of Chips
                      <span className='beads-amount'>
                        <input
                          maxLength={7}
                          value={datalist?.numberOfBeadsEqual}
                          onChange={(e) =>
                            handleInputChange(e, 'numberOfBeadsEqual')
                          }
                          type='text'
                          className='custom-input'
                          placeholder=''
                        />
                      </span>
                      is equal to
                      <span className='ngn-amount'>
                        <input
                          maxLength={7}
                          value={datalist?.amountOfNGNEqual}
                          onChange={(e) =>
                            handleInputChange(e, 'amountOfNGNEqual')
                          }
                          type='text'
                          className='custom-input'
                          placeholder=''
                        />
                        INR
                      </span>
                      .
                    </div>
                  </div> */}
                </div>

                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Event Price:</h4>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-5'>
                    <h4>Ad View Time:</h4>
                  </div>
                </div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{
                      border:
                        datalist?.eventPriceMaxBeads != null &&
                        datalist?.eventPriceMinBeads != null &&
                        (parseInt(datalist?.eventPriceMaxBeads) <
                          parseInt(datalist?.eventPriceMinBeads)
                          ? '1px solid red'
                          : '1px solid #f2e2e2')
                    }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Min Chips</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.eventPriceMinBeads}
                            placeholder='Enter Min Chips'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['eventPriceMinBeads']: parseInt(
                                    e.target.value
                                  )
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Max Chips</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.eventPriceMaxBeads}
                            placeholder='Enter Max Chips'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['eventPriceMaxBeads']: parseInt(
                                    e.target.value
                                  )
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-12 text-center'>
                        {datalist?.eventPriceMaxBeads != null &&
                          datalist?.eventPriceMinBeads != null &&
                          parseInt(datalist?.eventPriceMaxBeads) <
                          parseInt(datalist?.eventPriceMinBeads) && (
                            <label className='custom-error-label'>
                              Minimum should be less then Maximum*
                            </label>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Sec/View </Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={5}
                            value={datalist?.perViewSec}
                            placeholder='Enter Sec/View'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['perViewSec']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Subscriber Channel Price/Month:</h4>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-5'>
                    <h4>Badge Price:</h4>
                  </div>
                </div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{
                      border:
                        datalist?.subscriberChannelMaxPrice != null &&
                        datalist?.subscriberChannelMinPrice != null &&
                        (parseInt(datalist?.subscriberChannelMaxPrice) <
                          parseInt(datalist?.subscriberChannelMinPrice)
                          ? '1px solid red'
                          : '1px solid #f2e2e2')
                    }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Min Chips</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.subscriberChannelMinPrice}
                            placeholder='Enter Min Chips'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['subscriberChannelMinPrice']: parseInt(
                                    e.target.value
                                  )
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Max Chips</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.subscriberChannelMaxPrice}
                            placeholder='Enter Max Chips'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['subscriberChannelMaxPrice']: parseInt(
                                    e.target.value
                                  )
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-12 text-center'>
                        {datalist?.subscriberChannelMaxPrice != null &&
                          datalist?.subscriberChannelMinPrice != null &&
                          parseInt(datalist?.subscriberChannelMaxPrice) <
                          parseInt(datalist?.subscriberChannelMinPrice) && (
                            <label className='custom-error-label'>
                              Minimum should be less then Maximum*
                            </label>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Chips </Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.badgeBeadsPrice}
                            placeholder='Enter Chips'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['badgeBeadsPrice']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Influencer Elegibliy:</h4>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-5'>
                    <h4>Scratch Card Limit:</h4>
                  </div>
                </div>
                <div className='col-md-1'></div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Follower's Influencer</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.followersForInfluencer}
                            placeholder='Enter followers for Influencer'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['followersForInfluencer']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Influencer Views</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.viewsForInfluencer}
                            placeholder='Enter views For Influencer'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['viewsForInfluencer']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Years Spend</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.yearsSpendForInfluence}
                            placeholder='Enter years Spend For Influence'
                            className='form-control-inWidth'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['yearsSpendForInfluence']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Maximum(Day)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.maxScratchCardPerDay}
                            placeholder='Enter Max Scratch Card Per Day'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['maxScratchCardPerDay']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Maximum(Week)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.maxScratchCardPerWeek}
                            placeholder='Enter Max Scratch Card Per Week'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['maxScratchCardPerWeek']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Maximum(Year)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.maxScratchCardPerMonth}
                            placeholder='Enter Max Scratch Card Per Month'
                            className='form-control-inWidth'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['maxScratchCardPerMonth']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Influencer Earning Elegibliy:</h4>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-5'>
                    <h4>Scratch Card Time Frame:</h4>
                  </div>
                </div>
                <div className='col-md-1'></div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Minimum Followers</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.minFollowersForInfluencerEarning}
                            placeholder='Enter Minimum Followers For Influencer Earning'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['minFollowersForInfluencerEarning']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Minimum Influencer Earning By Followers</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.minInfluencerEarningByFollowers}
                            placeholder='Enter Influencer Earning By Followers'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['minInfluencerEarningByFollowers']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Minimum Views</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.minViewsForInfluencerEarning}
                            placeholder='Enter years Spend For Influence'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['minViewsForInfluencerEarning']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Minimum Influencer Earning By Views</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.minInfluencerEarningByViews}
                            placeholder='Enter Minimum Influencer Earning By Views'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['minInfluencerEarningByViews']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Expiry Time</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.scratchCardExpiryTime}
                            placeholder='Enter Scratch Card Expiry Time'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['scratchCardExpiryTime']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Earn Scratch Card</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.noOfVideosToWatchEarnScratchCard}
                            placeholder='Enter no. Of Videos To Watch Earn Scratch Card'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['noOfVideosToWatchEarnScratchCard']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Time Frame (Minutes)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.timeFrameLimitPerScratchCardInMinutes}
                            placeholder='Enter time Frame Limit Per Scratch Card In Minutes'
                            className='form-control-inWidth'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['timeFrameLimitPerScratchCardInMinutes']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Scratch Card Chips:</h4>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-5'>
                    <h4>Others:</h4>
                  </div>
                </div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{
                      border:
                        datalist?.scratchCardMaxChips != null &&
                        datalist?.scratchCardMinChips != null &&
                        (parseInt(datalist?.scratchCardMaxChips) <
                          parseInt(datalist?.scratchCardMinChips)
                          ? '1px solid red'
                          : '1px solid #f2e2e2')
                    }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Minimum(Card Chips)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.scratchCardMinChips}
                            placeholder='Enter Minimum scratch Card Chips'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['scratchCardMinChips']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Maximum(Card chips)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.scratchCardMaxChips}
                            placeholder='Enter Maximum scratch Card Chips'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['scratchCardMaxChips']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-12 text-center'>
                        {datalist?.scratchCardMaxChips != null &&
                          datalist?.scratchCardMinChips != null &&
                          parseInt(datalist?.scratchCardMaxChips) <
                          parseInt(datalist?.scratchCardMinChips) ? (
                          <label className='custom-error-label'>
                            Minimum should be less then Maximum*
                          </label>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Between Reels </Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.noOfVideosBetweenTwoAds}
                            placeholder='Enter Position'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['noOfVideosBetweenTwoAds']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Panel on Reels</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.noOfVideosPanelOnReelAds}
                            placeholder='Enter Position'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['noOfVideosPanelOnReelAds']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Delete Watch History(Days) </Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.noOfDaysToDeleteUserWatchHistory}
                            placeholder='Enter no. Of Days To Delete User Watch History'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['noOfDaysToDeleteUserWatchHistory']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Story Play(Sec) </Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.storyPlayTimeInSec}
                            placeholder='Enter story Play Time In Sec'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['storyPlayTimeInSec']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>FeedBack Email </Form.Label>
                          <Form.Control
                            type='Email'
                            maxLength={8}
                            value={datalist?.feedBackEmail}
                            placeholder='Enter FeedBack Email'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['feedBackEmail']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'></div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Tutorial Url </Form.Label>
                          <Form.Control
                            type='text'
                            value={datalist?.tutorialUrl}
                            placeholder='Enter Tutorial Url'
                            className='form-control-inWidth'
                            onChange={(e) => {
                              setDatalist((prevData) => ({
                                ...prevData,
                                ['tutorialUrl']: e.target.value
                              }))
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>  <hr />
                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-8'>
                    <h4>Ads Matrix World Rate</h4>
                  </div>
                  <div className='col-md-4'>
                    <button
                      onClick={() => handleUpdateMatrixSetting()}
                      className='btn btn-sm btn-primary text-dark'
                    >
                      Update Submit
                    </button>
                  </div>
                </div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-6'
                  >
                    <table>
                      <thead>
                        <tr>
                          {allTypeOfAds?.map((data) => (
                            <th className='custom-matrix-th' key={data.typeId}>{data?.name}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {object.map((data, index) => (
                            <td className='custom-matrix-td' key={index}>
                              <input
                                style={{ maxWidth: '100px' }}
                                value={data.rate}
                                maxLength={8}
                                onChange={(e) => handleRateInputChange(index, 'rate', e.target.value)}
                              />
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className='row mb-5'></div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
