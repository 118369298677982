import Button from 'react-bootstrap/Button'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Slider from '@mui/material/Slider'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import CommonMap from '../../custom/CommonMap'
import { SearchBar } from '../../custom/SearchBar'
import { SearchResultsList } from '../../custom/SearchResultsList'
import { useState } from 'react'
const marks = [
  {
    value: 0,
    label: '0'
  },
  {
    value: 100,
    label: '100'
  }
]
function valuetext (value) {
  return `${value}°C`
}
export const AddGeoLocation = props => {
  const [value, setValue] = useState(dayjs())
  const [results, setResults] = useState([])
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.roleFormData?.id ? 'Update Geo Location' : 'Add Geo Location'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CommonMap />
        <div className='row mt-4'>
          <div className='col-md-6'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Latitude*</Form.Label>
              <Form.Control type='text' placeholder='' />
            </Form.Group>
          </div>
          <div className='col-md-6'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Longitude*</Form.Label>
              <Form.Control type='text' placeholder='' />
            </Form.Group>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Radius(KM)*</Form.Label>
              <Slider
                defaultValue={0}
                valueLabelDisplay='auto'
                marks={marks}
                aria-label='Default'
                getAriaValueText={valuetext}
              />
            </Form.Group>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Event Host*</Form.Label>
              <SearchBar setResults={setResults} />
              {results && results.length > 0 && (
                <SearchResultsList results={results} />
              )}
            </Form.Group>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                  disableFuture
                  label='Start Date'
                  value={value}
                  onChange={newValue => setValue(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div className='col-md-6'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                  disableFuture
                  label='End Date'
                  value={value}
                  onChange={newValue => setValue(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={props.onSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
