import Swal from 'sweetalert2'
import { NavLink, useNavigate } from 'react-router-dom'
import { AuthData } from '../../auth/AuthWrapper'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
export const NavBarTop = () => {
  const headerText = useSelector(state => state?.topHeaderText)
  const userData = useSelector(state => state?.userData)
  const navigate = useNavigate()
  const { logout } = AuthData()
  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to logout',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async result => {
      if (result.isConfirmed) {
        await logout()
        navigate('/login')
        Swal.fire('Logout!', 'You have been logged out.', 'success')
      }
    })
  }

  useEffect(() => {
    console.log("==========asd",userData)
  }, [])

  return (
    <>
      <nav
        className='navbar navbar-top navbar-expand-md navbar-dark'
        id='navbar-main'
      >
        <div className='container-fluid'>
          {/* Brand */}
          <a className='h4 mb-0 text-dark text-uppercase d-none d-lg-inline-block'>
            {headerText}
          </a>
          {/* Form */}
          <form className='navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto'>
            {/* <div className='form-group mb-0'>
              <div className='input-group input-group-alternative'>
                <div className='input-group-prepend'>
                  <span className='input-group-text'>
                    <i className='fas fa-search' />
                  </span>
                </div>
                <input
                  className='form-control'
                  placeholder='Search'
                  type='text'
                />
              </div>
            </div> */}
          </form>
          {/* User */}
          <ul className='navbar-nav align-items-center d-none d-md-flex'>
            <li className='nav-item dropdown'>
              <a
                className='nav-link pr-0'
                href='#'
                role='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <div className='media align-items-center'>
                  <span className='avatar avatar-sm rounded-circle'>
                    <img
                      alt='Image placeholder'
                      src={
                        userData?.profileImage
                          ? userData?.profileImage
                          : './assets/img/theme/team-4-800x800.jpg'
                      }
                    />
                  </span>
                  <div className='media-body ml-2 d-none d-lg-block'>
                    <span className='mb-0 text-sm  font-weight-bold text-dark'>
                      {userData?.userName}
                    </span>
                  </div>
                </div>
              </a>
              <div className='dropdown-menu dropdown-menu-arrow dropdown-menu-right'>
                <div className=' dropdown-header noti-title'>
                  <h6 className='text-overflow m-0'>Welcome!</h6>
                </div>
                <NavLink
                  activeclassname='active'
                  to={'/edit-profile'}
                  className='dropdown-item'
                >
                  <i className='ni ni-single-02' />
                  <span>My profile</span>
                </NavLink>
                <div className='dropdown-divider' />
                <a onClick={handleLogout} className='dropdown-item cursor'>
                  <i className='ni ni-user-run' />
                  <span>Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}
