import { useEffect, useState } from "react"
import Swal from 'sweetalert2'
import NavBar from "../../structure/NavBar"
import { NavBarTop } from "../../structure/NavBarTop"
import { useDispatch, useSelector } from "react-redux"
import { updateTopHeader, uplodateLoading } from "../../../redux/features/common/commonSlice"
import { Button, Dropdown, Form, InputGroup } from "react-bootstrap"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import Utils from "../../constants/Utils"
import urls from "../../constants/apiurl"
import { showDangerToast, showToast } from "../../constants/toaster"
import ApiCaller from "../../constants/ApiCaller"
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { Box, Switch } from "@mui/material"
import NoDataImg from '../../../assets/img/no-data.gif'
import { AddDistrict } from "./AddDistrict"
import Pagination from '@mui/material/Pagination'


const label = { inputProps: { 'aria-label': 'Size switch demo' } }

export const DistrictList = (props) => {
  const [countryDropdownText, setCountryDropdownText] = useState({
    id: "",
    lable: "Select Country"
  });
  const [countryCodeList, setCountryCodeList] = useState([{
    id: "",
    name: "Select Country"
  }])
  const [stateDropdownText, setStateDropdownText] = useState({
    key: "",
    value: "Select State"
  });
  const [stateCodeList, setStateCodeList] = useState([{
    key: "",
    value: "Select State"
  }])
  const dispatch = useDispatch();
  const [deletePermission, setDeletePermission] = useState(false);
  const userData = useSelector((state) => state?.userData);
  const [districtFormData, setDistrictFormData] = useState({
    name: '',
    parentId: '',
    id: ""
  });
  const [showAddDistrict, setShowAddDistrict] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  });
  const [sort_type, setSortType] = useState('createdAt');
  const [sort_order, setSortOrder] = useState(false);
  const [datalist, setDatalist] = useState([]);
  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState(false);
  const LOADING_ENABLE = useSelector((state) => state?.loading);
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
  }
  const [rolePermissionData, setRolePermissionData] = useState({});
  const [subAdminListByRole, setsubAdminListByRole] = useState({});
  const [viewPermissionControl, setViewPermissionControl] = useState(false);



  useEffect(() => {
    dispatch(updateTopHeader('District Manager'))
  }, [])
  useEffect(() => {
    getDistrictList()
    getCountryCodeList()
    getStateCodeList()
  }, [sort_order, sort_type, page, props])

  useEffect(() => {
    getDistrictList('', startDate, endDate)
  }, [startDate, endDate, statusDropdownText, stateDropdownText])


  useEffect(() => {
    if (countryDropdownText && countryDropdownText?.id) {
      getStateCodeListByCountryId(countryDropdownText?.id)
    }
  }, [countryDropdownText])

  const handleAddState = () => {
    setDistrictFormData({
      id: '',
      name: '',
      parentId: ''
    })
    setShowAddDistrict(true)
  };

  const handleFilterClick = () => {
    getDistrictList(searchText, startDate, endDate)
  };

  const handleResetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setStatusDropdownText('Select Status')
    setSearchText('')
    getDistrictList('', null, null)
    setCountryDropdownText({
      id: "",
      lable: "Select Country"
    })
    setStateDropdownText(
      {
        key: "",
        value: "Select State"
      })
  };

  const getGetRolePermssions = async (id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.rolePermissionDetails + '/' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setRolePermissionData(response?.data)
        setViewPermissionControl(true)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const getCountryCodeList = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.countryCodeList
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempArray = [...countryCodeList]
        tempArray.push(...response?.data)
        setCountryCodeList(tempArray)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const getStateCodeList = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.stateCodeList
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempArray = [{
          key: "",
          value: "Select State"
        }]
        tempArray.push(...response?.data)
        setStateCodeList(tempArray)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }


  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeDistrictStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getDistrictList("", startDate, endDate)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  };

  const handleOnSubmitDistrictModel = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.addDistrict
      let res = await ApiCaller.post(
        districtFormData,
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      console.log('res', response)
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getDistrictList("", startDate, endDate)
        setShowAddDistrict(false)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleCountryClick = (item) => {
    setCountryDropdownText({
      id: item?.id,
      lable: item?.name
    })
    if (item?.id === "" || item?.id === undefined) {
      getStateCodeList()
    }
  }


  const getDistrictList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    console.log(startDate);
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchTextRef?.trim(),
        pageNumber: searchTextRef ? 0 : pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        bannerType: null,
        countryId: countryDropdownText?.id,
        stateId: stateDropdownText?.key,
        active:
          statusDropdownText === 'Active'
            ? true
            : statusDropdownText === 'In Active'
              ? false
              : null,
        startDate:
          startDateRef && startDateRef !== null
            ? startDateRef.toDate()
            : '',
        endDate:
          endDateRef && endDateRef !== null ? endDateRef.toDate() : ''
      }
      let path = urls.districtList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.response?.items)
        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  };

  const handleEditDistrict = (item) => {
    setDistrictFormData({
      id: item?.id,
      name: item?.name,
      parentId: item?.stateId,
      countryId: item?.countryId
    })
    setShowAddDistrict(true)
    getStateCodeListByCountryId(item?.countryId)
  }

  const handleDeleteDistrict = (item) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async result => {
      if (result.isConfirmed) {
        deleteApiCall(item?.id)
      }
    })
  }


  const deleteApiCall = async id => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.deleteDistrict + '/' + id
      let res = await ApiCaller.delete({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        Swal.fire('Deleted!', response?.message, 'success')
        getDistrictList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }


  const getStateCodeListByCountryId = async (countryId) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.stateCodeListByCountryId + "/" + countryId
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempArray = [{
          key: "",
          value: "Select State"
        }]
        tempArray.push(...response?.data)
        setStateCodeList(tempArray)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }



  return (<>
    <NavBar />
    <div className='main-content'>
      <NavBarTop />
      <div className='header'>
        <div className='logo'>
          <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
        </div>
      </div>
      <div className='container-fluid mt--7'>
        <div className='row'>
          <div className='col text-left'>
            <div className='card shadow'>
              <div className='card-header border-0'>
                <div className='row align-items-center text-left'>
                  <div className='col'>
                    <h3 className='mb-0'>District List</h3>
                  </div>
                  <div className='col text-right'>
                    {userData.admin ? (
                      <a
                        onClick={handleAddState}
                        className='btn btn-sm btn-primary text-dark'
                      >
                        <AddIcon />
                        Add New
                      </a>
                    ) : edit ? (
                      <a
                        onClick={handleAddState}
                        className='btn btn-sm btn-primary text-dark'
                      >
                        <AddIcon />
                        Add New
                      </a>
                    ) : (
                      <></>
                    )}

                    {/* <a href='#!' className='btn btn-sm btn-primary'>
                        See all
                      </a> */}
                  </div>
                </div>
              </div>
              <div className='card-header border-0'>
                <div className='row align-items-center text-left'>
                  <div className='col-md-3'>
                    <InputGroup
                      className='mb-2'
                      style={{ borderRadius: '10px' }}
                    >
                      <Form.Control
                        placeholder='Search by District Name'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        aria-label='Amount (to the nearest dollar)'
                      />
                      <InputGroup.Text
                        style={{
                          borderTopRightRadius: '10px',
                          borderBottomRightRadius: '10px',
                          borderTopLeftRadius: '0px',
                          borderBottomLeftRadius: '0px'
                        }}
                      >
                        <SearchIcon />
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className='col-md-2'>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='success'
                        style={{
                          backgroundColor: 'white',
                          color: 'black',
                          border: '1px solid #cad1d7',
                          boxShadow: 'none',
                          transform: 'none'
                        }}
                        id='dropdown-basic'
                      >
                        {countryDropdownText?.lable}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {countryCodeList?.map((item, i) => {
                          return <><Dropdown.Item eventKey={item?.id}
                            onClick={() => handleCountryClick(item)
                            }
                          >
                            {item?.name}
                          </Dropdown.Item></>
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className='col-md-2'>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='success'
                        style={{
                          backgroundColor: 'white',
                          color: 'black',
                          border: '1px solid #cad1d7',
                          boxShadow: 'none',
                          transform: 'none'
                        }}
                        id='dropdown-basic'
                      >
                        {stateDropdownText?.value}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {stateCodeList?.map((item, i) => {
                          return <><Dropdown.Item eventKey={item?.key}
                            onClick={() => {
                              setStateDropdownText(item)
                            }
                            }
                          >
                            {item?.value}
                          </Dropdown.Item></>
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className='col-md-2'>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='success'
                        style={{
                          backgroundColor: 'white',
                          color: 'black',
                          border: '1px solid #cad1d7',
                          boxShadow: 'none',
                          transform: 'none'
                        }}
                        id='dropdown-basic'
                      >
                        {statusDropdownText}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            setStatusDropdownText('Select Status')
                          }
                        >
                          Select Status
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => setStatusDropdownText('Active')}
                        >
                          Active
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => setStatusDropdownText('In Active')}
                        >
                          In Active
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className='col-md-6 mb-3'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={['DatePicker', 'DatePicker']}
                      >
                        <DatePicker
                          disableFuture
                          className='custom-date'
                          label='From Date'
                          value={startDate}
                          onChange={(newValue) => setStartDate(newValue)}
                        />
                        <DatePicker
                          disableFuture
                          label='To Date'
                          value={endDate}
                          onChange={(newValue) => setEndDate(newValue)}
                          minDate={startDate}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div className='col-md-3 mb-2'>
                    <Button
                      variant='contained'
                      className='custom-filter-btn'
                      onClick={handleFilterClick}
                    >
                      Filter
                    </Button>
                    <Button
                      variant='contained'
                      className='ml-2 custom-filter-btn'
                      onClick={handleResetFilter}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              </div>
              <div className='table-responsive'>
                {datalist && datalist?.length > 0 ? (
                  <>
                    <table className='table align-items-center table-flush'>
                      <thead className='thead-light'>
                        <tr>
                          <th scope='col'>S/No.</th>
                          <th scope='col'>Name</th>
                          <th scope='col'>State Name</th>
                          <th scope='col'>Country Name</th>
                          <th scope='col'>Created At</th>
                          <th scope='col'>Status</th>
                          <th scope='col' />
                        </tr>
                      </thead>
                      <tbody>
                        {datalist &&
                          datalist?.map((data, i) => {
                            return (
                              <>
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{data?.name}</td>
                                  <td>{data?.stateName ? data?.stateName : "-"}</td>
                                  <td>{data?.countryName ? data?.countryName : "-"}</td>
                                  <td>{Utils.formatDate(data?.createdAt)}</td>
                                  <td>
                                    {userData.admin ? (
                                      <Switch
                                        {...label}
                                        checked={data?.active}
                                        onChange={(e) =>
                                          handleStatusChange(e, data?.id)
                                        }
                                        size='small'
                                      />
                                    ) : active ? (
                                      <Switch
                                        {...label}
                                        checked={data?.active}
                                        onChange={(e) =>
                                          handleStatusChange(e, data?.id)
                                        }
                                        size='small'
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                  <td className='text-right'>
                                    <div className='dropdown'>
                                      <a
                                        className='btn btn-sm btn-icon-only text-light'
                                        href='#'
                                        role='button'
                                        data-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                      >
                                        <i className='fas fa-ellipsis-v' />
                                      </a>
                                      <div
                                        className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                        x-placement='bottom-end'
                                        style={{
                                          position: 'absolute',
                                          willChange: 'transform',
                                          top: '0px',
                                          left: '0px',
                                          transform:
                                            'translate3d(32px, 32px, 0px)'
                                        }}
                                      >
                                        {userData.admin ? (
                                          <a
                                            className='dropdown-item cursor'
                                            onClick={() =>
                                              handleEditDistrict(data)
                                            }
                                          >
                                            Edit
                                          </a>
                                        ) : active ? (
                                          <a
                                            className='dropdown-item cursor'
                                            onClick={() =>
                                              handleEditDistrict(data)
                                            }
                                          >
                                            Edit
                                          </a>
                                        ) : (
                                          <></>
                                        )}

                                        {userData.admin ? (
                                          <a
                                            className='dropdown-item cursor'
                                            onClick={() =>
                                              handleDeleteDistrict(data)
                                            }
                                          >
                                            Delete
                                          </a>
                                        ) : deletePermission ? (
                                          <a
                                            className='dropdown-item cursor'
                                            onClick={() =>
                                              handleDeleteDistrict(data)
                                            }
                                          >
                                            Delete
                                          </a>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <div className='text-center'>
                      <img width={'25%'} src={NoDataImg}></img>
                      <br />
                      <label style={{ fontWeight: 'bold' }}>
                        {LOADING_ENABLE ? (
                          <>Loading Data.....</>
                        ) : (
                          <>No Data Found</>
                        )}
                      </label>
                    </div>
                  </>
                )}
              </div>
              {datalist && datalist.length > 0 ? (
                <div className='card-footer py-4'>
                  <Box display="flex" justifyContent="right" alignItems='center'>
                    <span>Total record: {pagerecord?.totalItems}</span>
                    <Pagination
                      className='custom-pagination-class'
                      count={pagerecord?.totalPages}
                      page={page}
                      onChange={handleChange}
                      color='secondary'
                    />
                  </Box>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <AddDistrict
        show={showAddDistrict}
        countryCodeList={countryCodeList}
        districtFormData={districtFormData}
        stateCodeList={stateCodeList}
        rolePermissionData={rolePermissionData}
        subAdminListByRole={subAdminListByRole}
        setDistrictFormData={setDistrictFormData}
        onSubmit={() => handleOnSubmitDistrictModel()}
        onHide={() => setShowAddDistrict(false)}
      />
    </div>
  </>)
}
