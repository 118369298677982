// import { configureStore } from '@reduxjs/toolkit'
// import commonReducer from '../features/common/commonSlice'
// export const store = configureStore({
//   reducer: commonReducer
// })

import { configureStore } from '@reduxjs/toolkit'
import commonReducer from '../features/common/commonSlice'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'

const persistConfig = {
  key: 'root',
  storage
}

/* 

if reducers are multiple

const rootReducer = combineReducers({ 
  user: userReducer,
  notes: NotesReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer) */

const persistedReducer = persistReducer(persistConfig, commonReducer)

export const store = configureStore({
  reducer: persistedReducer
})

export const persistor = persistStore(store)
