import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { uplodateLoading } from "../../../redux/features/common/commonSlice";
import ApiCaller from "../../constants/ApiCaller";
import urls from "../../constants/apiurl";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { Form } from "react-bootstrap";
import { showDangerToast } from "../../constants/toaster";

const label = { inputProps: { 'aria-label': 'Size switch demo' } }
const SequenceEdit = (props) => {
    const [enableSequenceEdit, setEnableSequenceEdit] = useState(props?.enableSequenceEdit)
    const userData = useSelector(state => state?.userData)
    const [sequenceUpdate, setSequenceUpdate] = useState({
        id: "",
        seq: 0,
    });
    const [pagerecord, setPagerecord] = useState({
        totalItems: 0,
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
    });
    const [sort_type, setSortType] = useState('createdAt')

    const [sort_order, setSortOrder] = useState(false)
    const [statusDropdownText, setStatusDropdownText] = useState('Select Status')


    const dispatch = useDispatch()

    const handleChangeSequence = (event, data) => {
        switch (event.detail) {
            case 1: {
                setEnableSequenceEdit(false);
                break;
            }
            case 2: {
                setSequenceUpdate({ id: data?.id, seq: data?.sequence });
                setEnableSequenceEdit(true);
                break;
            }
            case 3: {
                setEnableSequenceEdit(false);
                break;
            }
            default: {
                setEnableSequenceEdit(false);
                break;
            }
        }
    };

    const handleSeqChange = (e) => {
        console.log(e.target.value);
        const newValue =
            e.target.value.replace(
                /[^0-9]/g,
                ""
            );
        setSequenceUpdate({
            ...sequenceUpdate,
            seq: newValue,
        });
        props?.setSequenceUpdate({
            ...sequenceUpdate,
            seq: newValue,
        })
    }


    return <>
        <td className="cursor">
            {enableSequenceEdit ? (
                <div className="d-flex">
                    <Form.Control
                        style={{ width: "55px" }}
                        size="sm"
                        type="text"
                        maxLength={3}
                        value={sequenceUpdate?.seq}
                        onChange={(e) => handleSeqChange(e)}
                        placeholder="Seq"
                    />
                    <IconButton
                        aria-label="save"
                        onClick={() => {
                            props?.handleDoneSq()
                            props?.setEnableSequenceEdit(false)
                            setEnableSequenceEdit(!enableSequenceEdit)
                        }}
                    >
                        <DoneIcon />
                    </IconButton>
                    <IconButton
                        aria-label="cancel"
                        onClick={() =>
                            setEnableSequenceEdit(false)
                        }
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            ) : (
                <>
                    <lable
                        title="Double Click to change"
                        onClick={(e) =>
                            handleChangeSequence(e, props?.data)
                        }
                    >
                        {props?.data?.sequence}
                    </lable>
                </>
            )}
        </td></>
}

export default SequenceEdit;