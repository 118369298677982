import PeopleIcon from '@mui/icons-material/People'
import DashboardIcon from '@mui/icons-material/Dashboard'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter'
import FlareIcon from '@mui/icons-material/Flare'
import FormatColorTextIcon from '@mui/icons-material/FormatColorText'
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic'
import WidgetsIcon from '@mui/icons-material/Widgets'
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import TollIcon from '@mui/icons-material/Toll'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import CastIcon from '@mui/icons-material/Cast'
import ReceiptIcon from '@mui/icons-material/Receipt'
import SummarizeIcon from '@mui/icons-material/Summarize'
import NotificationAddIcon from '@mui/icons-material/NotificationAdd'
import PublicIcon from '@mui/icons-material/Public'
import EmailIcon from '@mui/icons-material/Email'
import SettingsIcon from '@mui/icons-material/Settings'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt'
import CampaignIcon from '@mui/icons-material/Campaign'
import RssFeedIcon from '@mui/icons-material/RssFeed'
import SellIcon from '@mui/icons-material/Sell'
import GTranslateIcon from '@mui/icons-material/GTranslate';
import QuizIcon from '@mui/icons-material/Quiz'
import StarRateIcon from '@mui/icons-material/StarRate'
import TuneIcon from '@mui/icons-material/Tune'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AdUnitsIcon from '@mui/icons-material/AdUnits'
export const sidebarNav = [
  {
    moduleId: '1',
    name: 'Dashboard',
    path: '/',
    icon: <DashboardIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '28',
    name: 'Role Manager',
    path: '/role-manager',
    icon: <TuneIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '2',
    name: 'User Manager',
    path: '/user-manager',
    icon: <PeopleIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '32',
    name: 'Influencer Manager',
    path: '/influencer-manager',
    icon: <AccessibilityNewIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '3',
    name: 'Sub Admin Manager',
    path: '/sub-admin-manager',
    icon: <AdminPanelSettingsIcon className='margin-right-1-rem' />
  },
  // {
  //   moduleId: '16',
  //   name: 'Category Manager',
  //   path: '/country-manager',
  //   icon: <PublicIcon className='margin-right-1-rem' />
  // },
  {
    moduleId: '9',
    name: 'Report & Abuse Manager',
    path: '/report-abuse-manager',
    icon: <ReportProblemIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '10',
    name: 'Chips Purchase Manager',
    path: '/beads-purchase-manager',
    icon: <PriceCheckIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '11',
    name: 'Video Manager',
    path: '/video-manager',
    icon: <VideoLibraryIcon className='margin-right-1-rem' />
  },
  // {
  //   moduleId: '11',
  //   name: 'Post Manager',
  //   path: '/video-manager',
  //   icon: <VideoLibraryIcon className='margin-right-1-rem' />
  // },
  {
    moduleId: '13',
    name: 'Transaction Manager',
    path: '/transaction-manager',
    icon: <ReceiptIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '14',
    name: 'In App Purchase Manager',
    path: '/app_packages-manager',
    icon: <ReceiptIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '28',
    name: 'Meishe Assets Manager',
    path: '/meishe-assets-manager',
    icon: <AdUnitsIcon className='margin-right-1-rem' />
  },
  // {
  //   moduleId: "4",
  //   name: "Filter Manager",
  //   path: "/filter-manager",
  //   icon: <PhotoFilterIcon className="margin-right-1-rem" />,
  // },
  // {
  //   moduleId: "5",
  //   name: "Font & Style Manager",
  //   path: "/font-style-manager",
  //   icon: <FormatColorTextIcon className="margin-right-1-rem" />,
  // },
  // {
  //   moduleId: "6",
  //   name: "Effects Manager",
  //   path: "/effect-manager",
  //   icon: <FlareIcon className="margin-right-1-rem" />,
  // },
  {
    moduleId: '7',
    name: 'Sound Manager',
    path: '/sound-manager',
    icon: <LibraryMusicIcon className='margin-right-1-rem' />
  },
  // {
  //   moduleId: "8",
  //   name: "Widget Manager",
  //   path: "/widget-manager",
  //   icon: <WidgetsIcon className="margin-right-1-rem" />,
  // },

  // {
  //   name: 'Coin Manager',
  //   path: '/coin-manager',
  //   icon: <TollIcon className='margin-right-1-rem' />
  // },

  // {
  //   moduleId: '12',
  //   name: 'Streaming Manager',
  //   path: '/streaming-manager',
  //   icon: <CastIcon className='margin-right-1-rem' />
  // },

  // {
  //   moduleId: "14",
  //   name: "Report Manager",
  //   path: "/report-manager",
  //   icon: <SummarizeIcon className="margin-right-1-rem" />,
  // },
  {
    moduleId: '15',
    name: 'Notification Manager',
    path: '/notification-manager',
    icon: <NotificationAddIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '16',
    name: 'Country Manager',
    path: '/country-manager',
    icon: <PublicIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '28',
    name: 'State Manager',
    path: '/state-manager',
    icon: <PublicIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '29',
    name: 'City Manager',
    path: '/city-manager',
    icon: <PublicIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '30',
    name: 'District Manager',
    path: '/district-manager',
    icon: <PublicIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '18',
    name: 'Global Settings',
    path: '/global-setting-manager',
    icon: <SettingsIcon className='margin-right-1-rem' />
  },
  // {
  //   moduleId: '16',
  //   name: 'Language Manager',
  //   path: '/country-manager',
  //   icon: <PublicIcon className='margin-right-1-rem' />
  // },
  {
    moduleId: '17',
    name: 'Email Templates',
    path: '/email-manager',
    icon: <EmailIcon className='margin-right-1-rem' />
  },

  {
    moduleId: '19',
    name: 'Static Content',
    path: '/static-content-manager',
    icon: <FileCopyIcon className='margin-right-1-rem' />
  },
  // {
  //   moduleId: "20",
  //   name: "Geo Location Manager",
  //   path: "/geo-location-manager",
  //   icon: <EditLocationAltIcon className="margin-right-1-rem" />,
  // },
  {
    moduleId: '21',
    name: 'Ads Management',
    path: '/ads-manager',
    icon: <CampaignIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '22',
    name: 'Blogs Management',
    path: '/blog-manager',
    icon: <RssFeedIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '23',
    name: 'Category Manager',
    path: '/tag-manager',
    icon: <SellIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '31',
    name: 'Language Manager',
    path: '/language-manager',
    icon: <GTranslateIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '24',
    name: 'FAQ Manager',
    path: '/faq-manager',
    icon: <QuizIcon className='margin-right-1-rem' />
  },
  // {
  //   moduleId: '25',
  //   name: 'Rate Application Manager',
  //   path: '/rating-manager',
  //   icon: <StarRateIcon className='margin-right-1-rem' />
  // },
  {
    moduleId: '26',
    name: 'Withdraw Manager',
    path: '/withdraw-manager',
    icon: <AccountBalanceIcon className='margin-right-1-rem' />
  },
  {
    moduleId: '27',
    name: 'Banner Manager',
    path: '/banner-manager',
    icon: <AdUnitsIcon className='margin-right-1-rem' />
  }
]
