import * as React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import Button from '@mui/material/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import { AsyncPaginate } from "react-select-async-paginate";
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import { useState } from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast } from '../../constants/toaster'

export const NormalUserFilters = (props) => {
  const userData = useSelector((state) => state?.userData);
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  });
  const { setFilterData, filterData } = props
  const dispatch = useDispatch()
  const [value, setValue] = useState(dayjs())
  const [valueDropdown, setValueDropdown] = useState(null);


  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined);
      }, ms);
    });

  const wrappedLoadOptions = React.useCallback(function (...args) {
    return loadOptionsTest(...args);
  }, []);

  const loadOptionsTest = async (
    search,
    prevOptions
  ) => {
    console.log("1")
    await sleep(1000);
    try {
      let postJson = {
        searchText: search?.trim(),
        pageNumber: search?.trim() ? 0 : pagerecord.pageNumber - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: "createdAt",
        asc: false,
      }
      let path = urls.userSearchDropdownList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        console.log(response?.data?.items)
        let tempArray = []
        response?.data?.items.forEach(element => {
          tempArray.push({
            value: element?.key,
            label: element?.value
          })
        });
        setPagerecord({
          ...pagerecord,
          pageNumber: pagerecord?.pageNumber + 1
        })
        const hasMore = response?.data?.totalPages > 0 && response?.data?.totalPages > pagerecord?.pageNumber;
        return {
          options: tempArray,
          hasMore
        };

      } else {
        showDangerToast(response.message)
      }
    } catch (error) {
      console.error(error)
      showDangerToast(error.message)
    }

  };

  return (
    <div className='card-header border-0'>
      <div className='row align-items-center text-left'>
        <div className='col-md-3'>
          <AsyncPaginate
            debounceTimeout={300}
            value={valueDropdown}
            // loadOptions={loadOptions}
            loadOptions={wrappedLoadOptions}
            onChange={(e) => {
              setValueDropdown(e)
              console.log(e)
              setFilterData((pre) => ({ ...pre, searchText: e?.label }))
            }}
          />
          {/* <InputGroup className='mb-2' style={{ borderRadius: '10px' }}>
            <Form.Control
              placeholder='Search by User Name'
              aria-label='Amount (to the nearest dollar)'
              value={filterData?.searchText}
              onChange={(e) => setFilterData((pre) => ({ ...pre, searchText: e.target.value }))}
            />
            <InputGroup.Text
              style={{
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px'
              }}
            >
              <SearchIcon />
            </InputGroup.Text> */}
          {/* </InputGroup> */}
        </div>
        <div className='col-md-2 mb-2 '>
          <Dropdown>
            <Dropdown.Toggle
              variant='success'
              style={{
                backgroundColor: 'white',
                color: 'black',
                border: '1px solid #cad1d7',
                boxShadow: 'none',
                transform: 'none'
              }}
              id='dropdown-basic'
            >
              {filterData?.accountStatus || 'Select Status'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setFilterData((pre) => ({ ...pre, accountStatus: 'Select Status' }))}
              >
                Select Status
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setFilterData((pre) => ({ ...pre, accountStatus: 'Active' }))}>
                Active
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setFilterData((pre) => ({ ...pre, accountStatus: 'In Active' }))}>
                In Active
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='col-md-6 mb-3' style={{ marginLeft: '40px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              <DatePicker
                disableFuture
                className='custom-date'
                label='From Date'
                value={filterData?.startDate}
                onChange={newValue => setFilterData((pre) => ({ ...pre, startDate: newValue }))}
              />
              <DatePicker
                disableFuture
                label='To Date'
                value={filterData?.endDate}
                onChange={newValue => setFilterData((pre) => ({ ...pre, endDate: newValue }))}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        {/* <div className='col-md-1 mb-2 '>
          <Dropdown>
            <Dropdown.Toggle
              variant='success'
              style={{
                backgroundColor: 'white',
                color: 'black',
                border: '1px solid #cad1d7',
                boxShadow: 'none',
                transform: 'none'
              }}
              id='dropdown-basic'
            >
              {filterData?.subscription || 'Select Subscription'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setFilterData((pre) => ({ ...pre, subscription: 'Select Subscription' }))}
              >
                Select Subscription
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setFilterData((pre) => ({ ...pre, subscription: 'Subscription Name' }))}
              >
                Subscription Name
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
        <div className='col-md-3 mb-2'>
          <Button
            variant='contained'
            className='custom-filter-btn'
            onClick={() => props?.handleFilterClick()}
          >
            Filter
          </Button>
          <Button
            variant='contained'
            className='ml-2 custom-filter-btn'
            onClick={() => { 
              props?.handleResetFilter() 
              setValueDropdown(null)
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    </div >
  )
}
