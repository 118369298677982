import Button from 'react-bootstrap/Button'
import DatePicker from 'react-datepicker'
import AWS from 'aws-sdk'
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import Dropdown from 'react-dropdown'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useRef, useState } from 'react'
import { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { useDispatch, useSelector } from 'react-redux'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import { showDangerToast, showToast } from '../../constants/toaster'

const options = [
  { value: '', label: 'Select Ad Type' },
  { value: '1', label: 'Ganesh' }
]

const defaultAdsTypeOptions = [
  { value: '', label: 'Select' },
  { value: 'IMAGE', label: 'Image' },
  { value: 'VIDEO', label: 'Video' },
  { value: 'GOOGLE_MOB', label: 'Google Mob' }
]


export const AddAds = props => {
  const [contentType, setContentType] = useState('IMAGE');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [invalidContentType, setInvalidContentType] = useState(false)
  const [contentUrl, setContentUrl] = useState(null)
  const [defaultAdsType, setDefaultAdsType] = useState({
    value: '',
    label: 'Select'
  })

  //Country Details
  const [countryCodeList, setCountryCodeList] = useState([{
    value: "",
    label: "Select Country"
  }])
  const [countryDetails, setCountryDetails] = useState({
    value: "",
    label: "Select Country"
  })

  //State Details
  const [stateCodeList, setStateCodeList] = useState([{
    value: "",
    label: "Select State"
  }])
  const [stateDetails, setStateDetails] = useState({
    value: "",
    label: "Select State"
  })

  //District Details
  const [districtCodeList, setDistrictCodeList] = useState([{
    value: "",
    label: "Select District"
  }])
  const [districtDetails, setDistrictDetails] = useState({
    value: "",
    label: "Select District"
  })

  //City Details
  const [cityCodeList, setCityCodeList] = useState([{
    value: "",
    label: "Select City"
  }])
  const [cityDetails, setCityDetails] = useState({
    value: "",
    label: "Select City"
  })
  const [startDateChanged, setStartDateChanged] = useState(false)
  const [value, setValue] = useState(dayjs())
  const [isClickable, setIsClickable] = useState(false)
  const handleClickable = async event => {
    setIsClickable(event.target.checked)
  }
  const [targetValue, setTargetValue] = useState(0)
  const [websiteLink, setWebsiteLink] = useState('')
  const [description, setDescription] = useState('')
  const [typeOfAdvertisment, setTypeOfAdvertisment] = useState([])
  const [adsType, setAdsType] = useState({
    value: '',
    label: 'Select',
    status: false
  })
  const [linkEnable, setLinkEnable] = useState(false)
  const dispatch = useDispatch()
  const userData = useSelector(state => state?.userData)


  const fileRef = useRef(null);



  const handleAdTypeChange = e => {
    props?.setAdsFormData({
      ...props?.adsFormData,
      type: e.value
    })
    setContentType(e.value)
    props?.setAdsContentFile(null)
  }

  useEffect(() => {
    if (props?.show) {
      getTypeOfAdvertisement()
      getCountryCodeList()
      if (props?.tabValue === 1) {
        props?.setAdsFormData({
          ...props?.adsFormData,
          type: 'IMAGE'
        })
      }
    }
  }, [props?.show])


  const getCountryCodeList = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.countryCodeList
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempArray = [...countryCodeList]
        response?.data.forEach(element => {
          tempArray.push({
            value: element?.id,
            label: element?.name
          })
        });
        setCountryCodeList(tempArray)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }


  const getTypeOfAdvertisement = async () => {
    try {
      dispatch(uplodateLoading(true))
      let typeOf = props?.tabValue === 0 ? 'BETWEEN_REEL' : props?.tabValue === 1 ? 'PANEL_ON_REEL' : ''
      let path = urls.getTypeOfAdvertisement + "/" + typeOf
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempArray = []
        response?.data.forEach((data) => {
          tempArray.push({
            value: data?.value,
            label: data?.key,
            status: data?.status
          })
        });
        setTypeOfAdvertisment(tempArray)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleCountryChange = (item) => {
    setCountryDetails(item)
    if (item?.value) {
      getStateCodeListByCountryId(item?.value)
      getCityCodeListByParentId(item?.value, false)
      setStateDetails({
        label: "Select State",
        value: ""
      })
      setDistrictCodeList({
        label: "Select District",
        value: ""
      })
      setCityDetails({
        label: "Select City",
        value: ""
      })
    } else {
      setStateCodeList([])
      setStateDetails({
        label: 'Select State',
        value: ""
      })
    }
  }
  const handleStateChange = (item) => {
    console.log(item)
    setStateDetails(item)
    if (item?.value) {
      getDistrictCodeListByStateId(item?.value)
      getCityCodeListByParentId(item?.value, true)
      setDistrictCodeList({
        label: "Select District",
        value: ""
      })
      setCityDetails({
        label: "Select City",
        value: ""
      })
    } else {
      setDistrictCodeList([])
      setDistrictDetails({
        label: 'Select District',
        value: ""
      })
    }
  }

  const getStateCodeListByCountryId = async (countryId) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.stateCodeListByCountryId + "/" + countryId
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempArray = [{
          label: "Select State",
          value: ""
        }]
        response?.data.forEach(element => {
          tempArray.push({
            value: element?.key,
            label: element?.value
          })
        });
        setStateCodeList(tempArray)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const getCityCodeListByParentId = async (parentId, isState) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.getCityCodeListByParent + "/" + parentId + "/" + isState
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempArray = [{
          label: "Select City",
          value: ""
        }]
        response?.data.forEach(element => {
          tempArray.push({
            value: element?.key,
            label: element?.value
          })
        });
        setCityCodeList(tempArray)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const getDistrictCodeListByStateId = async (stateId) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.getDistrictByStateId + "/" + stateId
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempArray = [{
          label: "Select District",
          value: ""
        }]
        response?.data.forEach(element => {
          tempArray.push({
            value: element?.key,
            label: element?.value
          })
        });
        setDistrictCodeList(tempArray)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }


  const handleSubmitAds = async () => {
    let responseImage;
    if (contentType && (contentType === 'IMAGE' || contentType === 'VIDEO')) {
      if (contentUrl) {
        dispatch(uplodateLoading(true))
        responseImage = await uploadFile()
      } else {
        alert("Please upload Image/Video")
      }
    }
    let postJson = {
      type: props?.tabValue != null && props?.tabValue === 0 ? 'BETWEEN_REELS' : props?.tabValue === 1 ? 'PANEL_ON_THE_REELS' : '',
      typeId: adsType?.value,
      target: targetValue,
      startDate: formatDate(new Date(startDate)),
      endDate: formatDate(new Date(endDate)),
      countryId: countryDetails?.value,
      stateId: stateDetails?.value,
      districtId: districtDetails?.value,
      cityId: cityDetails?.value,
      websiteLink: websiteLink,
      description: description,
      contentType: props?.tabValue != null && props?.tabValue === 0 ? contentType : props?.tabValue === 1 ? 'IMAGE' : '',
      contentUrl: responseImage,
      // contentId: '',
      // userId: ''
    }

    try {
      dispatch(uplodateLoading(true))
      let path = urls.adminUploadAds
      let res = await ApiCaller.post(
        postJson,
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      console.log('res', response)
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        props.onUploadAddSuccess()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }

  }



  const handleImageSelect = event => {
    const file = event.target.files[0]
    if (contentType === 'IMAGE') {
      console.log(file.type)
      if (file && file.type.startsWith('image/')) {
        setInvalidContentType(false)
        setContentUrl(file)
      } else {
        setInvalidContentType(true)
        setContentUrl(null)
        return
      }
    } else {
      if (!file.type.includes("video")) {
        setInvalidContentType(true)
        setContentUrl(null)
        return
      } else {
        setInvalidContentType(false)
        setContentUrl(file)
      }
    }
  }

  const uploadFile = async () => {
    // S3 Bucket Name
    const S3_BUCKET = 'praxo-dev-cloud-front'

    // S3 Region
    const REGION = 'ap-south-1'

    // S3 Credentials
    AWS.config.update({
      accessKeyId: 'AKIAQLSIVLYXUCEHSXOK',
      secretAccessKey: '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'
    })
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION
    })
    // Files Parameters
    const fileName = 'ads/' + contentUrl?.name
    console.log(fileName)
    const params = {
      Bucket: S3_BUCKET,
      Key: fileName,
      Body: contentUrl
    }

    // Uploading file to s3

    var upload = await s3
      .putObject(params)
      .on('httpUploadProgress', evt => {
        // File uploading progress
        console.log(
          'Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%'
        )
      })
      .promise()
    // await upload.then((err, data) => {
    //   alert("File upload successfully.")
    //   console.log("Image URL: " + "https://dzv0fidwp2q1d.cloudfront.net/" + fileName)
    // });
    return 'https://supa-dev-bucket.s3.af-south-1.amazonaws.com/' + fileName
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.adsFormData?.id ? 'Update Ads' : 'Add Ads'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>

          {props?.tabValue === 0 || props?.tabValue === 1 ? (
            <>
              {props?.tabValue === 0 ? (
                <div className='col-md-4'>
                  <label>Select Advertisment Type*</label>
                  <Dropdown
                    options={defaultAdsTypeOptions}
                    onChange={e => handleAdTypeChange(e)}
                    value={defaultAdsType}
                    placeholder='Select Ad Type'
                  />
                </div>
              ) : (
                <></>
              )}
              {props?.adsFormData?.type === 'IMAGE' ||
                props?.adsFormData?.type === 'VIDEO' ||
                props?.tabValue === 1 ? (

                <div className='col-md-4'>
                  <label>Choose</label>
                  <input
                    type="file"
                    id="file"
                    accept={props?.adsFormData?.type === 'IMAGE' ? 'image/*' : 'video/*'}
                    ref={fileRef}
                    onChange={(event) => handleImageSelect(event)}
                  />
                  {invalidContentType ? <p style={{ color: 'red !important' }}>Invalid File Type</p> : <></>}
                </div>
              ) : (
                <></>
              )}

            </>
          ) : (
            <></>
          )}
          {props?.adsFormData?.type === 'IMAGE' ||
            props?.adsFormData?.type === 'VIDEO' ? <div className='col-md-4'>
            <label>Advertisment Category*</label>
            <Dropdown
              options={typeOfAdvertisment}
              onChange={e => {
                setAdsType(e)
                typeOfAdvertisment?.map((data) => {
                  if (data?.value === e?.value) {
                    setLinkEnable(data?.status)
                  }
                })
              }}
              value={adsType}
              placeholder='Select'
            />

          </div> : <></>}

        </div>


        <div className='row mt-3'>
          <div className='col-md-12'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <Form.Label>Display Location</Form.Label>
              <div className='row'>
                <div className='col-md-6'>
                  <Dropdown
                    options={countryCodeList}
                    onChange={e => handleCountryChange(e)}
                    value={countryDetails}
                    placeholder='Select Country'
                  />
                </div>
                <div className='col-md-6'>
                  <Dropdown
                    options={stateCodeList}
                    onChange={e => handleStateChange(e)}
                    value={stateDetails}
                    placeholder='Select State'
                  />
                </div>
                <div className='col-md-6 mt-3'>
                  <Dropdown
                    options={districtCodeList}
                    onChange={e => setDistrictDetails(e)}
                    value={districtDetails}
                    placeholder='Select District'
                  />
                </div>
                <div className='col-md-6 mt-3'>
                  <Dropdown
                    options={cityCodeList}
                    onChange={e => setCityDetails(e)}
                    value={cityDetails}
                    placeholder='Select District'
                  />
                </div>
              </div>
            </Form.Group>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <Form.Label>Start Date*</Form.Label>
              <DatePicker
                selected={props.adsFormData?.startDate}
                onChange={date => {
                  props?.setAdsFormData({
                    ...props?.adsFormData,
                    startDate: date
                  })
                  setStartDate(date)
                  setStartDateChanged(true)
                }}
                dateFormat='MM/dd/yyyy'
                minDate={new Date()}
              />
            </Form.Group>
          </div>
          <div className='col-md-6'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <Form.Label>End Date*</Form.Label>
              <DatePicker
                selected={props.adsFormData?.endDate}
                onChange={date => {
                  props?.setAdsFormData({
                    ...props?.adsFormData,
                    endDate: date
                  })
                  setEndDate(date)
                }}
                dateFormat='MM/dd/yyyy'
                minDate={props.adsFormData?.startDate}
                disabled={!startDateChanged}
              />
            </Form.Group>
          </div>
        </div>
        {props?.adsFormData?.type === 'IMAGE' ||
          props?.adsFormData?.type === 'VIDEO' ? <div className='row mt-3'>
          <div className='col-md-6'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>No. Of Target Hits*</Form.Label>
              <Form.Control
                type='text'
                maxLength={10}
                placeholder='Total Views Needed'
                value={targetValue}
                onChange={e => {
                  // Allow only numbers and decimal points
                  const regex = /^\d*\.?\d*$/;
                  if (regex.test(e.target.value)) {
                    setTargetValue(e.target.value)
                  }
                }
                }
              />
            </Form.Group>
          </div>
          {linkEnable ? <>
            <div className='col-md-6'>
              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                <Form.Label>Website Link*</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='www.google.com'
                  value={websiteLink}
                  onChange={e =>
                    setWebsiteLink(e.target.value)
                  }
                />
              </Form.Group>
            </div>
            <div className='col-md-12'>
              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Description'
                  value={description}
                  onChange={e =>
                    setDescription(e.target.value)
                  }
                />
              </Form.Group>
            </div>
          </> : <></>}


        </div> : <></>}

      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmitAds()}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
