import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  topHeaderText: '',
  loading: false,
  userData: {},
  permission:{}
}
export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updateTopHeader: (state, action) => {
      state.topHeaderText = action.payload
    },
    uplodateLoading: (state, action) => {
      state.loading = action.payload
    },
    updateUserData: (state, action) => {
      state.userData = action.payload
    },
    updatePermission: (state, action) => {
      state.permission = action.payload
    }
  }
})

export const { updateTopHeader, uplodateLoading, updateUserData ,updatePermission} =
  commonSlice.actions
export default commonSlice.reducer
