import { GroupBase, OptionsOrGroups } from "react-select";

export const OptionType = {
    value:'',
    label:0
};

const options = [];
for (let i = 0; i < 50; ++i) {
    options.push({
        value: i + 1,
        label: `Option ${i + 1}`
    });
}

const sleep = (ms) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve(undefined);
        }, ms);
    });

export const loadOptions = async (
    search,
    prevOptions
) => {
    console.log("1")
    await sleep(1000);
    let filteredOptions;
    if (!search) {
        filteredOptions = options;
    } else {
        const searchLower = search.toLowerCase();

        filteredOptions = options.filter(({ label }) =>
            label.toLowerCase().includes(searchLower)
        );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
        prevOptions.length,
        prevOptions.length + 10
    );

    return {
        options: slicedOptions,
        hasMore
    };
};


