import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useEffect } from 'react'
export const PlayVideoInModal = props => {
  useEffect(() => {}, [])

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          Video Preview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <video width='100%' controls height={'500px'}>
          <source src={props?.videoSrc} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
