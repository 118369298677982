import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import Form from 'react-bootstrap/Form'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import ReplyIcon from '@mui/icons-material/Reply'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { useState } from 'react'
import { showDangerToast } from '../../constants/toaster'

const options = [
  { value: '', label: 'Select User' },
  { value: '1', label: 'Ganesh' }
]
const defaultOption = options[0]

export const EditEmail = props => {
  const [value, setValue] = useState('all')
  const [showUserDropdown, setShowUserDropdown] = useState(false)
  const handleChange = event => {
    setValue(event.target.value)
    if (event.target.value === 'toUser') {
      setShowUserDropdown(true)
    } else {
      setShowUserDropdown(false)
    }
  }

  const handleSubmit = () => {
    if (
      props?.editEmailFormData?.title &&
      props?.editEmailFormData?.subject &&
      props?.editEmailFormData?.content
    ) {
      props.handleSubmitEmail()
    } else {
      showDangerToast('Please fill all required fields')
    }
  }

  return (
    <>
      <div className='container-fluid mt--7'>
        <div className='row'>
          <div className='col text-left'>
            <div className='card shadow'>
              <div className='card-header border-0'>
                <div className='row align-items-center '>
                  <div className='col text-left'>
                    <a
                      onClick={() => props.setEditEmailEnable(false)}
                      className='btn btn-sm btn-primary text-dark'
                    >
                      <ReplyIcon />
                      Cancel
                    </a>
                  </div>
                  <div className='col text-center'>
                    <h3 className='mb-0'>Edit Email Template</h3>
                  </div>
                  <div className='col text-right'>
                    <a
                      onClick={handleSubmit}
                      className='btn btn-sm btn-primary text-dark'
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>

              <div className='card-header border-0'>
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group
                      className='mb-3'
                      controlId='exampleForm.ControlInput1'
                    >
                      <Form.Label>Template Name*</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Template Name'
                        value={props?.editEmailFormData?.title}
                        onChange={e =>
                          props.setEditEmailFormData({
                            ...props?.editEmailFormData,
                            title: e.target.value
                          })
                        }
                      />
                      {props?.editEmailFormData?.title === '' ? (
                        <label className='custom-error-label'>
                          Title is required*
                        </label>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group
                      className='mb-3'
                      controlId='exampleForm.ControlInput1'
                    >
                      <Form.Label>Subject*</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Submit'
                        value={props.editEmailFormData.subject}
                        onChange={e =>
                          props.setEditEmailFormData({
                            ...props?.editEmailFormData,
                            subject: e.target.value
                          })
                        }
                      />
                      {props?.editEmailFormData?.subject === '' ? (
                        <label className='custom-error-label'>
                          Subject is required*
                        </label>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-md-12'>
                    <Form.Group
                      className='mb-3'
                      controlId='exampleForm.ControlInput1'
                    >
                      <Form.Label>Content*</Form.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={props.editEmailFormData?.content}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          console.log('Editor is ready to use!', editor)
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          props.setEditEmailFormData({
                            ...props?.editEmailFormData,
                            content: data
                          })
                          console.log({ event, editor, data })
                        }}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor)
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor)
                        }}
                      />
                      {props?.editEmailFormData?.content === '' ? (
                        <label className='custom-error-label'>
                          Content is required*
                        </label>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-md-12'>
                    <Form.Group
                      className='mb-3'
                      controlId='exampleForm.ControlInput1'
                    >
                      <Form.Label>Keyword*</Form.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        data='<p>Hello from CKEditor&nbsp;5!</p>'
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          console.log('Editor is ready to use!', editor)
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          console.log({ event, editor, data })
                        }}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor)
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor)
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
