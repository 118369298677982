import "react-dropdown/style.css";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
export const ViewEffect = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Effect Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mt-1">
          <div className="col-md-6">
            Thumbnail URL:{" "}
            <img
              src={props.effectViewFormData.thumbnailUrl}
              alt="Thumbnail"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          <div className="col-md-6">
            Effect URL:{" "}
            <a
              href={props.effectViewFormData.effectUrl}
              target="_blank"
              rel="noreferrer"
            >
              View Effect
            </a>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-md-6">
            <p>Widget Name: {props.effectViewFormData.widgetName}</p>
          </div>
          <div className="col-md-6">
            <p>Active: {props.effectViewFormData.active ? "Yes" : "No"}</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-md-6">
            <p>Name: {props.effectViewFormData.name}</p>
          </div>
          <div className="col-md-6">
            <p>Duration: {props.effectViewFormData.duration}</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-md-6">
            <p>CreatedAt: {props.effectViewFormData.createdAt}</p>
          </div>
          <div className="col-md-6">
            <p>UpdatedAt: {props.effectViewFormData.updatedAt}</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-md-6">
            <p>View Count: {props.effectViewFormData.viewCount}</p>
          </div>
          <div className="col-md-6">
            <p>Play Count: {props.effectViewFormData.playCount}</p>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-6">
            <p>Like Count: {props.effectViewFormData.likeCount}</p>
          </div>
          <div className="col-md-6">
            <p>Follower Count: {props.effectViewFormData.followerCount}</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-md-6">
            <p>Description: {props.effectViewFormData.description}</p>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-6">
            <p>Tags:{/*  {props.effectViewFormData.tags?.join(", ")} */}</p>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outlined" onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
