import logo from './logo.svg'
import './App.css'
import { BrowserRouter } from 'react-router-dom'
import { AuthWrapper } from './auth/AuthWrapper'
import { UseSessionProvider } from 'react-session-hook'
import { Provider } from 'react-redux'
import './assets/css/loader-2.css'
import './assets/css/style.css'
import { store, persistor } from '../src/redux/store/store'
import { PersistGate } from 'redux-persist/integration/react'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import { useEffect } from 'react'
function App () {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className='App'>
          <BrowserRouter>
            <UseSessionProvider>
              <AuthWrapper />
            </UseSessionProvider>
          </BrowserRouter>
          <ToastContainer
            position='top-right'
            autoClose={1000}
            hideProgressBar={true}
            closeOnClicks
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </PersistGate>
    </Provider>
  )
}

export default App
