import Swal from 'sweetalert2'
import { useEffect, useState } from 'react'
import NavBar from '../../structure/NavBar'
import { NavBarTop } from '../../structure/NavBarTop'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { FullImageView } from '../../custom/FullImageView'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import defaultProfileImage from '../../../assets/img/image-preview.jpg'
import { AuthData } from '../../../auth/AuthWrapper'
import { useNavigate } from 'react-router-dom'

export const EditProfile = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { logout } = AuthData()
  const userData = useSelector(state => state?.userData)
  const [passwordFormData, setPasswordFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  })
  const [profieData, setProfileData] = useState({})
  const [imageFile, setImageFile] = useState(null)
  const [profileUrl, setProfileUrl] = useState()
  const [fullSizePreview, setFullSizePreview] = useState(false)
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/
  useEffect(() => {
    dispatch(updateTopHeader('Profile'))
    getProfileDetails()
  }, [])

  const handleImageSelect = event => {
    const fileInput = event.target
    const files = fileInput.files

    if (files.length > 0) {
      const selectedImage = files[0]
      // Perform your desired action with the selected image
      console.log('Selected image:', selectedImage)
      // You can also display a preview of the image if needed
      displayImagePreview(selectedImage)
    }
  }

  const displayImagePreview = imageFile => {
    const reader = new FileReader()
    setImageFile(imageFile)
    reader.onload = function (e) {
      setProfileUrl(e.target.result)
    }

    reader.readAsDataURL(imageFile)
  }

  const handleUpdatePassword = async () => {
    if (!passwordFormData?.oldPassword) {
      showDangerToast('Old Password is empty')
    } else if (!passwordFormData?.newPassword) {
      showDangerToast('New Password is empty')
    } else if (!passwordFormData?.confirmPassword) {
      showDangerToast('Confirm Password is empty')
    } else {
      try {
        dispatch(uplodateLoading(true))
        let path = urls.updatePassword
        let res = await ApiCaller.post(
          passwordFormData,
          path,
          userData?.access_token
        )
        const response = await res.response.json()
        if (response.status == 200) {
          dispatch(uplodateLoading(false))
          showToast(response.message)
          dispatch(uplodateLoading(false))
          Swal.fire('Success!', response.message, 'success')
          await logout()
          navigate('/login')
        } else {
          dispatch(uplodateLoading(false))
          showDangerToast(response.message)
        }
      } catch (error) {
        dispatch(uplodateLoading(false))
        console.error(error)
        showDangerToast(error.message)
      }
    }
  }

  const handleUpdateProfile = async () => {
    try {
      let form_data = new FormData()
      form_data.append(
        'updateProfile',
        new Blob([JSON.stringify(profieData)], {
          type: 'application/json'
        })
      )
      if (imageFile) {
      }
      form_data.append('profileImage', imageFile)
      dispatch(uplodateLoading(true))
      let path = urls.updateProfile
      var res = await ApiCaller.post_formdata(
        form_data,
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const getProfileDetails = async () => {
    console.log(userData)
    try {
      dispatch(uplodateLoading(true))
      let path = urls.profileDetail + '?id=' + userData?.id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setProfileData(response?.data)
        if (response?.data?.profileImage) {
          setProfileUrl(response?.data?.profileImage)
        }
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div
          className='header pb-8 pt-5 pt-lg-8 d-flex align-items-center'
          style={{
            minHeight: '600px',
            backgroundImage: 'url(../assets/img/theme/profile-cover.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center top'
          }}
        >
          {/* Mask */}
          <span className='mask bg-gradient-default opacity-8' />
          {/* Header container */}
          <div className='container-fluid'>
              <div className='col-lg-12 col-md-10'>
                <h1 className='display-2 text-white'>
                  Update Profile
                </h1>
              </div>
          </div>
        </div>

        <div className='container-fluid mt--7'>
          <div className='row'>
            <div className='col-xl-4 order-xl-2 mb-5 mb-xl-0'>
              <div className='card card-profile shadow'>
                <div className='row justify-content-center'>
                  <div className='col-lg-3 order-lg-2'>
                    <div className='card-profile-image'>
                      <a>
                        <img
                          style={{ cursor: 'pointer' }}
                          title='Click to view full preview'
                          onClick={() => setFullSizePreview(true)}
                          src={profileUrl ? profileUrl : defaultProfileImage}
                          className='rounded-circle'
                        />
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className='card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4'>
                  <div className='d-flex justify-content-between'>
                    <a className='btn btn-sm btn-info mr-4'>Connect</a>
                    <a className='btn btn-sm btn-default float-right'>
                      Message
                    </a>
                  </div>
                </div> */}
                <div
                  className='card-body pt-0 pt-md-4'
                  style={{ marginTop: '75px' }}
                >
                  <div className='row'>
                    <div className='col'>
                      <div className=' justify-content-center mt-md-5'>
                        <input
                          type='file'
                          accept='image/*'
                          onChange={handleImageSelect}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-8 order-xl-1'>
              <div className='card bg-secondary shadow'>
                <div className='card-header bg-white border-0'>
                  <div className='row text-left'>
                    <div className='col-8'>
                      <h3 className='mb-0'>My account</h3>
                    </div>
                    <div className='col-4 text-right'>
                      <a
                        className='btn btn-sm btn-primary'
                        style={{ color: 'white' }}
                        onClick={handleUpdateProfile}
                      >
                        Update
                      </a>
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <form>
                    <h6 className='heading-small text-muted mb-4 text-left'>
                      User information
                    </h6>
                    <div className='pl-lg-4'>
                      <div className='row'>
                        {/* <div className='col-lg-6 text-left'>
                          <div className='form-group focused'>
                            <label
                              className='form-control-label'
                              htmlFor='input-username'
                            >
                              Username
                            </label>
                            <input
                              type='text'
                              id='input-username'
                              className='form-control form-control-alternative'
                              placeholder='Username'
                              defaultValue='lucky.jesse'
                            />
                          </div>
                        </div> */}
                        <div className='col-lg-6 text-left'>
                          <div className='form-group'>
                            <label
                              className='form-control-label'
                              htmlFor='input-email'
                            >
                              Email address
                            </label>
                            <input
                              value={profieData?.email}
                              onChange={e => {
                                setProfileData(prevData => ({
                                  ...prevData,
                                  ['email']: e.target.value
                                }))
                              }}
                              type='email'
                              id='input-email'
                              className='form-control form-control-alternative'
                              placeholder='jesse@example.com'
                            />
                          </div>
                        </div>
                        <div className='col-lg-6 text-left'>
                          <div className='form-group'>
                            <label
                              className='form-control-label'
                              htmlFor='input-email'
                            >
                              Mobile Number
                            </label>
                            <input
                              value={profieData?.contactNumber}
                              onChange={e => {
                                setProfileData(prevData => ({
                                  ...prevData,
                                  ['contactNumber']: e.target.value
                                }))
                              }}
                              type='text'
                              id='input-email'
                              className='form-control form-control-alternative'
                              placeholder='+91'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-6 text-left'>
                          <div className='form-group focused'>
                            <label
                              className='form-control-label'
                              htmlFor='input-first-name'
                            >
                              First name
                            </label>
                            <input
                              value={profieData?.firstName}
                              onChange={e => {
                                setProfileData(prevData => ({
                                  ...prevData,
                                  ['firstName']: e.target.value
                                }))
                              }}
                              type='text'
                              id='input-first-name'
                              className='form-control form-control-alternative'
                              placeholder='First name'
                              defaultValue='Lucky'
                            />
                          </div>
                        </div>
                        <div className='col-lg-6 text-left'>
                          <div className='form-group focused'>
                            <label
                              className='form-control-label'
                              htmlFor='input-last-name'
                            >
                              Last name
                            </label>
                            <input
                              value={profieData?.lastName}
                              onChange={e => {
                                setProfileData(prevData => ({
                                  ...prevData,
                                  ['lastName']: e.target.value
                                }))
                              }}
                              type='text'
                              id='input-last-name'
                              className='form-control form-control-alternative'
                              placeholder='Last name'
                              defaultValue='Jesse'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className='my-4' />

                    <div className='row text-left'>
                      <div className='col-8'>
                        <h6 className='heading-small text-muted mb-4 text-left'>
                          Change Password
                        </h6>
                      </div>
                      <div className='col-4 text-right'>
                        <a
                          className='btn btn-sm btn-primary'
                          style={{ color: 'white' }}
                          onClick={handleUpdatePassword}
                        >
                          Update Password
                        </a>
                      </div>
                    </div>
                    <div className='pl-lg-4'>
                      <div className='row'>
                        <div className='col-lg-4 text-left'>
                          <div className='form-group focused'>
                            <label
                              className='form-control-label'
                              htmlFor='input-city'
                            >
                              Old Password
                            </label>
                            <input
                              value={passwordFormData?.oldPassword}
                              onChange={e => {
                                setPasswordFormData(prevData => ({
                                  ...prevData,
                                  ['oldPassword']: e.target.value
                                }))
                              }}
                              type='text'
                              id='input-city'
                              className='form-control form-control-alternative'
                              placeholder='**********'
                            />
                            {passwordFormData.oldPassword &&
                              !passwordRegex.test(
                                passwordFormData.oldPassword
                              ) && (
                                <p className='custom-error-validation'>
                                  Invalid Format
                                </p>
                              )}
                          </div>
                        </div>
                        <div className='col-lg-4 text-left'>
                          <div className='form-group focused'>
                            <label
                              className='form-control-label'
                              htmlFor='input-country'
                            >
                              New Password
                            </label>
                            <input
                              value={passwordFormData?.newPassword}
                              onChange={e => {
                                setPasswordFormData(prevData => ({
                                  ...prevData,
                                  ['newPassword']: e.target.value
                                }))
                              }}
                              type='text'
                              id='input-country'
                              className='form-control form-control-alternative'
                              placeholder='**********'
                            />
                            {passwordFormData.newPassword &&
                              !passwordRegex.test(
                                passwordFormData.newPassword
                              ) && (
                                <p className='custom-error-validation'>
                                  Invalid Format
                                </p>
                              )}
                          </div>
                        </div>
                        <div className='col-lg-4 text-left'>
                          <div className='form-group'>
                            <label
                              className='form-control-label'
                              htmlFor='input-country'
                            >
                              Confirm Password
                            </label>
                            <input
                              value={passwordFormData?.confirmPassword}
                              onChange={e => {
                                setPasswordFormData(prevData => ({
                                  ...prevData,
                                  ['confirmPassword']: e.target.value
                                }))
                              }}
                              type='text'
                              id='input-postal-code'
                              className='form-control form-control-alternative'
                              placeholder='**********'
                            />
                            {passwordFormData.confirmPassword &&
                            !passwordRegex.test(
                              passwordFormData.confirmPassword
                            ) ? (
                              <p className='custom-error-validation'>
                                Invalid Format
                              </p>
                            ) : (
                              passwordFormData.confirmPassword &&
                              passwordFormData?.newPassword !==
                                passwordFormData.confirmPassword && (
                                <p className='custom-error-validation'>
                                  New Password and Confirm Password must be
                                  same.
                                </p>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <FullImageView
            src={profileUrl}
            show={fullSizePreview}
            onHide={() => setFullSizePreview(false)}
          />
        </div>
      </div>
    </>
  )
}
