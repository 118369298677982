import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Box from "@mui/material/Box";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'
import { useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import ApiCaller from '../../constants/ApiCaller'
import urls from '../../constants/apiurl'
import { showDangerToast, showToast } from '../../constants/toaster'
import NavBar from '../../structure/NavBar'
import { NavBarTop } from '../../structure/NavBarTop'
import { AddNotification } from './AddNotification'
import { Pagination } from '@mui/material';
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const NotificationList = props => {
  const [addNewNotification, setAddNewNotification] = useState(false)
  const [value, setValue] = useState(dayjs())
  const userData = useSelector(state => state?.userData)
  const [showAddRole, setShowAddRole] = useState(false)
  const [datalist, setDatalist] = useState([])
  const [viewPermissionControl, setViewPermissionControl] = useState(false)
  const [viewSubAdminList, setViewSubAdminList] = useState(false)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [roleFormData, setRoleFormData] = useReducer(
    (roleFormData, newItem) => {
      return { ...roleFormData, ...newItem }
    },
    { name: '', id: '', description: '' }
  )
  const [permissionSubmitData, setPermissionSubmitData] = useState([])
  const [rolePermissionData, setRolePermissionData] = useState({})
  const [subAdminListByRole, setSubAdminListByRole] = useState([])
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10,
    });
  };

  useEffect(() => {
    dispatch(updateTopHeader('Notification Manager'))
  }, [])
  useEffect(() => {
    getRoleList()
  }, [sort_order, sort_type, props, page])

  const getGetRolePermssions = async id => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.rolePermissionDetails + '/' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setRolePermissionData(response?.data)
        setViewPermissionControl(true)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const getSubAdminsByRoleId = async id => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.subAdminByRole + '/' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setSubAdminListByRole(response?.data)
        setViewSubAdminList(true)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeRoleStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const getRoleList = async (pageNo = pagerecord.pageNumber) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        pageNumber: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order
      }
      let path = urls.notificationList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditRole = item => {
    setRoleFormData(item)
    setShowAddRole(true)
  }

  const handleAddRole = () => {
    setRoleFormData({
      id: '',
      name: '',
      description: ''
    })
    setShowAddRole(true)
  }

  const handleOnSubmitRoleModel = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.addRole
      let res = await ApiCaller.post(roleFormData, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        setShowAddRole(false)
        getRoleList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }



  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
        {addNewNotification ? (
          <AddNotification
            setAddNewNotification={setAddNewNotification}
          />
        ) : (
          <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Notification List</h3>
                      </div>
                      <div className='col text-right'>
                        <a
                          onClick={() => setAddNewNotification(true)}
                          className='btn btn-sm btn-primary text-dark'
                        >
                          <AddIcon />
                          Add Notification
                        </a>
                        {/* <a href='#!' className='btn btn-sm btn-primary'>
                      See all
                    </a> */}
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-4 mb-3'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker
                              disableFuture
                              className='custom-date'
                              label='From Date'
                              value={value}
                              onChange={newValue => setValue(newValue)}
                            />
                            <DatePicker
                              disableFuture
                              label='To Date'
                              value={value}
                              onChange={newValue => setValue(newValue)}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    <table className='table align-items-center table-flush'>
                      <thead className='thead-light'>
                        <tr>
                          <th scope='col'>S/No.</th>
                          <th scope='col'>Notification Title</th>
                          <th scope='col'>Sent Date</th>
                          <th scope='col'>Users</th>
                          <th scope='col' />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <th scope='row'>
                            <div className='media align-items-center'>
                              <div className='media-body'>
                                <span className='mb-0 text-sm'>
                                  Subscription Successful
                                </span>
                              </div>
                            </div>
                          </th>
                          <td>26/11/2023</td>
                          <td>Kiran</td>
                          <td className='text-right'>
                            <div className='dropdown'>
                              <a
                                className='btn btn-sm btn-icon-only text-light'
                                href='#'
                                role='button'
                                data-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                              >
                                <i className='fas fa-ellipsis-v' />
                              </a>
                              <div
                                className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                x-placement='bottom-end'
                                style={{
                                  position: 'absolute',
                                  willChange: 'transform',
                                  top: '0px',
                                  left: '0px',
                                  transform: 'translate3d(32px, 32px, 0px)'
                                }}
                              >
                                <a className='dropdown-item cursor'>
                                  View Details
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {datalist && datalist.length > 0 ? (
                    <div className="card-footer py-4">
                      <Box display="flex" justifyContent="right" alignItems='center'>
                        <span>Total record: {pagerecord?.totalItems}</span>
                      <Pagination
                        className="custom-pagination-class"
                        count={pagerecord?.totalPages}
                        page={page}
                        onChange={handleChange}
                        color="secondary"
                      />
                      </Box>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
