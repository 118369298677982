import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import NoDataImg from '../../../assets/img/no-data.gif'
import { useSelector } from 'react-redux'
export const RoleBasedSubAdminList = props => {
  const LOADING_ENABLE = useSelector(state => state?.loading)
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='row mb-2'>
          <h3 className='col-md-12 text-center'>Sub Admins List</h3>
        </div>
        {props?.subAdminListByRole.length > 0 ? (
          <div className='table-responsive'>
            <table className='table align-items-center table-flush'>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>Name</th>
                  <th scope='col'>Email</th>
                  <th scope='col'>Status</th>
                </tr>
              </thead>
              {props?.subAdminListByRole.length > 0 ? (
                <></>
              ) : (
                <>
                  <img width={'25%'} src={NoDataImg}></img>
                </>
              )}
              <tbody>
                {props?.subAdminListByRole?.map((data, i) => {
                  return (
                    <tr>
                      <td>{data?.name}</td>
                      <td>{data?.email}</td>
                      <td>{data?.status ? 'Active' : 'In Active'}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className='text-center'>
            <img width={'25%'} src={NoDataImg}></img>
            <br />
            <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? <>Loading Data.....</> :<>No Data Found</>}
                          </label>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
