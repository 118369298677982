import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useEffect, useReducer, useState } from 'react'
import { showDangerToast } from '../../constants/toaster'
export const AddCountry = (props) => {
  const [isValidFormat, setIsValidFormat] = useState(true)
  const [isValidLengthFormat, setIsValidLengthFormat] = useState(true)
  const [isCodeRequired, setIsCodeRequired] = useState(false)

  const handleSubmit = () => {
    if (
      props?.countryFormData.name === '' ||
      props?.countryFormData.name === undefined ||
      props?.countryFormData?.code === '' ||
      props?.countryFormData?.code === undefined ||
      props?.countryFormData?.mobileLength === '' ||
      props?.countryFormData?.mobileLength === undefined ||
      props?.countryFormData?.isnCode === '' ||
      props.countryFormData.isnCode === undefined
    ) {
      showDangerToast('Please fill all required fields')
    } else {
      props.onSubmit()
    }
  }

  const handleCodeChange = (e) => {
    const inputCode = e.target.value

    // Check if the input starts with a plus sign followed by any number of digits
    const formatValid = /^\+\d*$/.test(inputCode) || inputCode === ''
    setIsValidFormat(formatValid)

    // Check if the input is empty
    const isRequired = inputCode.trim() === ''
    setIsCodeRequired(isRequired)

    // Update the form data regardless of the format

    props.setCountryFormData({
      ...props?.countryFormData,
      code: inputCode
    })
  }
  const handleLengthChange = (e) => {
    // Allow only digits (0-9) in the input
    const newValue = e.target.value.replace(/\D/g, '')
    props.setCountryFormData({
      ...props?.countryFormData,
      mobileLength: newValue
    })
  }
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.roleFormData?.id ? 'Update Country' : 'Add Country'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-4'>
            <Form.Control
              maxLength={15}
              value={props.countryFormData.name}
              onChange={(e) =>
                props.setCountryFormData({
                  ...props?.countryFormData,
                  name: e.target.value
                })
              }
              placeholder='Country'
              aria-label='Small'
              aria-describedby='inputGroup-sizing-sm'
            />
            {props?.countryFormData?.name === '' ? (
              <label className='custom-error-label'>Name is required*</label>
            ) : (
              <></>
            )}
          </div>
          <div className='col-md-2'>
            <Form.Control
              maxLength={5}
              value={props.countryFormData.isnCode}
              onChange={(e) =>
                props.setCountryFormData({
                  ...props?.countryFormData,
                  isnCode: e.target.value
                })
              }
              placeholder='ISN'
              aria-label='Small'
              aria-describedby='inputGroup-sizing-sm'
            />
            {props?.countryFormData?.isnCode === '' ? (
              <label className='custom-error-label'>ISN Code is required*</label>
            ) : (
              <></>
            )}
          </div>
          <div className='col-md-3'>
            <Form.Control
              type='text'
              maxLength={5}
              value={props.countryFormData.code}
              onChange={handleCodeChange}
              onBlur={handleCodeChange}
              placeholder='Country Code'
              aria-label='Small'
              aria-describedby='inputGroup-sizing-sm'
            />
            {isValidFormat ? (
              <></>
            ) : (
              <label className='custom-error-label'>Invalid code format</label>
            )}
            {props?.countryFormData?.code === '' && (
              <label className='custom-error-label'>Code is required*</label>
            )}
          </div>
          <div className='col-md-3'>
            <Form.Control
              type='text'
              maxLength={2}
              value={props.countryFormData.mobileLength}
              onChange={handleLengthChange}
              onBlur={handleLengthChange}
              placeholder='Mobile Length'
              aria-label='Small'
              aria-describedby='inputGroup-sizing-sm'
            />
            {isValidLengthFormat ? (
              <></>
            ) : (
              <label className='custom-error-label'>
                Invalid Mobile Length format
              </label>
            )}
            {props?.countryFormData?.mobileLength === '' && (
              <label className='custom-error-label'>
                Mobile Length is required*
              </label>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
