import Switch from '@mui/material/Switch'
import NoDataImg from '../../../assets/img/no-data.gif'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast } from '../../constants/toaster'
import Utils from '../../constants/Utils'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const AmountTransactionTable = props => {
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector(state => state?.userData)
  const [datalist, setDatalist] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const dispatch = useDispatch()
  useEffect(() => {
    if (props?.tabValue === 0) {
      getWithdrawList()
    }
  }, [props])

  const getWithdrawList = async (pageNo = pagerecord.pageNumber) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        keyword: '',
        page: pageNo - 1 || 0,
        limit: pagerecord.pageSize || 10,
        sort: sort_type,
        transactionType: 'ALL',
        order: sort_order
      }
      let path = urls.amountTransactionHistory
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      {datalist && datalist?.length > 0 ? (
        <>
          <div className='table-responsive'>
            <table className='table align-items-center table-flush'>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>S/No.</th>
                  <th scope='col'>Full Name</th>
                  <th scope='col'>User Name</th>
                  <th scope='col'>User ID</th>
                  <th scope='col'>Transaction Type</th>
                  <th scope='col'>Transaction ID</th>
                  <th scope='col'>Total Price</th>
                  <th scope='col'>Transaction Date</th>
                </tr>
              </thead>
              <tbody>
                {datalist &&
                  datalist?.map((data, i) => {
                    return (
                      <>
                        <tr>
                          <td>{i + 1}</td>
                          <td>{data?.fullName}</td>
                          <td>{data?.userName}</td>
                          <td>{data?.userId}</td>
                          <td>Withdraw Commission</td>
                          <td>{data?.txnId}</td>
                          <td>{(data?.totalChips)?.toFixed(2)} INR</td>
                          <td>{Utils.formatDate(data?.createdAt)}</td>
                        </tr>
                      </>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <>
            <div className='text-center'>
              <img width={'25%'} src={NoDataImg}></img>
              <br />
              <label style={{ fontWeight: 'bold' }}>
                {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
              </label>
            </div>
          </>
        </>
      )}
    </>
  )
}
