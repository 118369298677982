import { Link, NavLink, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { sidebarNav } from './sidebarNav'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { AuthData } from '../../auth/AuthWrapper'
import LogoutIcon from '@mui/icons-material/Logout'

const NavBar = () => {
  const userData = useSelector(state => state?.userData)
  const userPermisiions = useSelector(state => state?.permission)
  const { logout } = AuthData()
  useEffect(() => {}, [])
  const navigate = useNavigate()

  const isPermitted = sideBarModuleId => {
    let enable = false
    userPermisiions?.modules?.forEach((module, moduleIndex) => {
      if (module.moduleId === sideBarModuleId) {
        module?.permissions?.forEach((p, permissionIndex) => {
          if (p.name === 'VIEW') {
            enable = p.isEnable
          }
        })
      }
    })
    return enable
  }

  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to logout',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async result => {
      if (result.isConfirmed) {
        await logout()
        navigate('/login')
        Swal.fire('Logout!', 'You have been logged out.', 'success')
      }
    })
  }

  return (
    <>
      <nav
        className='navbar navbar-vertical fixed-left navbar-expand-md navbar-light  scroll-container'
        id='sidenav-main'
      >
        <div className='container-fluid'>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#sidenav-collapse-main'
            aria-controls='sidenav-main'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon' />
          </button>
          {/* <Link to={'/'} className='navbar-brand pt-0'> */}
            <img
              src={
                'https://supa-dev-bucket.s3.af-south-1.amazonaws.com/admin_Logo-white.png'
              }
              width={'80%'}
            />
          {/* </Link> */}
          <ul className='nav align-items-center d-md-none'>
            <li className='nav-item dropdown'>
              <a
                className='nav-link nav-link-icon'
                href='#'
                role='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <i className='ni ni-bell-55' />
              </a>
              <div
                className='dropdown-menu dropdown-menu-arrow dropdown-menu-right'
                aria-labelledby='navbar-default_dropdown_1'
              >
                <a className='dropdown-item' href='#'>
                  Action Test
                </a>
                <a className='dropdown-item' href='#'>
                  Another action
                </a>
                <div className='dropdown-divider' />
                <a className='dropdown-item' href='#'>
                  Something else here
                </a>
              </div>
            </li>
            <li className='nav-item dropdown'>
              <a
                className='nav-link'
                href='#'
                role='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <div className='media align-items-center'>
                  <span className='avatar avatar-sm rounded-circle'>
                    <img
                      alt='Image placeholder'
                      src='./assets/img/theme/team-1-800x800.jpg
'
                    />
                  </span>
                </div>
              </a>
              <div className='dropdown-menu dropdown-menu-arrow dropdown-menu-right'>
                <div className=' dropdown-header noti-title'>
                  <h6 className='text-overflow m-0 text-dark'>Welcome!</h6>
                </div>
                <a href='./examples/profile.html' className='dropdown-item'>
                  <i className='ni ni-single-02' />
                  <span>My profile</span>
                </a>
                <a href='./examples/profile.html' className='dropdown-item'>
                  <i className='ni ni-settings-gear-65' />
                  <span>Settings</span>
                </a>
                <a href='./examples/profile.html' className='dropdown-item'>
                  <i className='ni ni-calendar-grid-58' />
                  <span>Activity</span>
                </a>
                <a href='./examples/profile.html' className='dropdown-item'>
                  <i className='ni ni-support-16' />
                  <span>Support</span>
                </a>
                <div className='dropdown-divider' />
                <a href='#!' className='dropdown-item'>
                  <i className='ni ni-user-run' />
                  <span>Logout</span>
                </a>
              </div>
            </li>
          </ul>
          <div className='collapse navbar-collapse' id='sidenav-collapse-main'>
            <div className='navbar-collapse-header d-md-none'>
              <div className='row'>
                <div className='col-6 collapse-brand'>
                  <a href='./index.html'>
                    <img src='./assets/img/brand/blue.png' />
                  </a>
                </div>
                <div className='col-6 collapse-close'>
                  <button
                    type='button'
                    className='navbar-toggler'
                    data-toggle='collapse'
                    data-target='#sidenav-collapse-main'
                    aria-controls='sidenav-main'
                    aria-expanded='false'
                    aria-label='Toggle sidenav'
                  >
                    <span />
                    <span />
                  </button>
                </div>
              </div>
            </div>
            <form className='mt-4 mb-3 d-md-none'>
              <div className='input-group input-group-rounded input-group-merge'>
                <input
                  type='search'
                  className='form-control form-control-rounded form-control-prepended'
                  placeholder='Search'
                  aria-label='Search'
                />
                <div className='input-group-prepend'>
                  <div className='input-group-text'>
                    <span className='fa fa-search' />
                  </div>
                </div>
              </div>
            </form>
            <ul className='navbar-nav'>
              {sidebarNav.map((r, i) => {
                if (userData.admin) {
                  return (
                    <li className='nav-item' key={i}>
                      <NavLink
                        activeClassName='active'
                        to={r.path}
                        className='nav-link'
                      >
                        {r?.icon} {r.name}
                      </NavLink>
                    </li>
                  )
                } else if (isPermitted(r.moduleId)) {
                  return (
                    <li className='nav-item' key={i}>
                      <NavLink
                        activeClassName='active'
                        to={r.path}
                        className='nav-link'
                      >
                        {r?.icon} {r.name}
                      </NavLink>
                    </li>
                  )
                } else {
                  return null // If the user doesn't have permission, render nothing
                }
              })}
              <li className='nav-item'>
                <NavLink
                  activeClassName='active'
                  className='nav-link'
                  onClick={handleLogout}
                >
                  <LogoutIcon className='margin-right-1-rem' /> Logout
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default NavBar
