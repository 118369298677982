var Utils = {
  formatDate (inputDate) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    const date = new Date(inputDate)
    return date.toLocaleDateString('en-US', options)
  },
  extractHoursAndMinutes (dateTimeString) {
    const date = new Date(dateTimeString)
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${hours}:${minutes}`
  }
}
export default Utils
