import React from 'react'

function Image ({ image, width, height }) {
  const placeholderImage =
    'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png'
  const onImageError = e => {
    e.target.src = placeholderImage
  }
  return (
    <div>
      <img
        alt=''
        width={width}
        height={height}
        src={image?.src}
        // src={placeholderImage}
        onError={onImageError}
      />
    </div>
  )
}

export default Image
