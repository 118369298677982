import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateTopHeader,
  uplodateLoading
} from '../../redux/features/common/commonSlice'
import urls from '../constants/apiurl'
import Button from "@mui/material/Button";
import InputGroup from "react-bootstrap/InputGroup";
import ApiCaller from '../constants/ApiCaller'
import { showDangerToast } from '../constants/toaster'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import Form from "react-bootstrap/Form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
export const RenderHeader = () => {
  const dispatch = useDispatch()
  const [dashboardData, setDashboardData] = useState({})
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState("");
  const userData = useSelector(state => state?.userData)
  useEffect(() => {
    dispatch(updateTopHeader('Dashboard'))
    dispatch(uplodateLoading(false))
    getDashboardData()
  }, [])

  const getDashboardData = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.dashboard
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        console.log(response?.data)
        setDashboardData(response?.data)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleFilterClick = () => {
    getDashboardData()
  };
  const handleResetFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchText("");
  };

  return (
    <div className='header'>
      <div className='logo'>
        <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'>
          <div className='container-fluid'>
            <div className='header-body'>
              {/* Card stats */}
              <div className="card-header border-0">
                    <div className="row align-items-center text-left">
                      <div className="col-md-6 mb-3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["DatePicker", "DatePicker"]}
                          >
                            <DatePicker
                              disableFuture
                              className="custom-date"
                              label="From Date"
                              value={startDate}
                              onChange={(newValue) => setStartDate(newValue)}
                            />
                            <DatePicker
                              disableFuture
                              label="To Date"
                              value={endDate}
                              onChange={(newValue) => setEndDate(newValue)}
                              minDate={startDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className="col-md-3 mb-2">
                        <Button
                          variant="contained"
                          className="custom-filter-btn"
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant="contained"
                          className="ml-2 custom-filter-btn"
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
              <div className='row'>
                <div className='col-xl-3 col-lg-6'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                            Total Number of Users
                          </h5>
                          <span
                            className='h2  mb-0'
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Active/In Active :{' '}
                            {dashboardData?.totalNumberOfUserActive} /{' '}
                            {dashboardData?.totalNumberOfUserInActive}
                          </span>
                          <br />
                          {/* <span className='h2  mb-0 text-white' style={{fontSize:'14px',fontWeight:'bold'}}>
                            In Active : 350,897
                          </span> */}
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-dark rounded-circle shadow'>
                            <i className='fas fa-users' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                            Verification Badge
                          </h5>
                          <span
                            className='h2  mb-0'
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Total Number:{' '}
                            {dashboardData?.totalNumberOfVerificationBadge}
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-dark rounded-circle shadow'>
                            <i className='fas fa-certificate' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                            Subscribed Channel
                          </h5>
                          <span
                            className='h2  mb-0'
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Total Number:{' '}
                            {dashboardData?.totalNumberOfSubscribedChannel}
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-dark rounded-circle shadow'>
                            <i className='fas fa-users' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                            Tip Received (NGN)
                          </h5>
                          <span
                            className='h2  mb-0'
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Total Amount:{' '}
                            {dashboardData?.totalNumberOfTipReceived} Beads
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-dark rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                            Badge Verification Request
                          </h5>
                          <span
                            className='h2  mb-0'
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Total :{' '}
                            {
                              dashboardData?.totalNumberOfBadgeVerificationRequest
                            }
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-dark rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                            Uploaded Videos
                          </h5>
                          <span
                            className='h2  mb-0'
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Total : {dashboardData?.totalNumberOfUploadedVideos}
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-dark rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                            Verified Users
                          </h5>
                          <span
                            className='h2  mb-0'
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Total : {dashboardData?.totalNumberOfVerifiedUsers}
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-dark rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                            Avg. Stay Time of user spending on the application
                          </h5>
                          <span
                            className='h2  mb-0'
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Time : 6h
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-dark rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                            Earning of Admin via beads (NGN)
                          </h5>
                          <span
                            className='h2  mb-0'
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Total : {dashboardData?.totalEarningOfAdminViaBeads}
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-dark rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                            Trending Video
                          </h5>
                          <span
                            className='h2  mb-0'
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            View received(in 24 Hours)) :{' '}
                            {dashboardData?.trendingVideoTotalViews}
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-dark rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          Tranding Video
                        </span>
                        <a
                          href={dashboardData?.trendingVideoLink}
                          target='_blank'
                          className='text-nowrap'
                        >
                          View
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                            Trending Sound
                          </h5>
                          <span
                            className='h2  mb-0'
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Sound Used(in 24 Hours)) :{' '}
                            {dashboardData?.trendingSoundTotalUsed}
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-dark rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          Name Of Sound: {dashboardData?.trendingSoundName}
                        </span>
                        <a
                          href={dashboardData?.trendingSoundLink}
                          target='_blank'
                          className='text-nowrap'
                        >
                          View
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                            App Rating
                          </h5>
                          <span
                            className='h2  mb-0'
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            5/5
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-dark rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 2%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
