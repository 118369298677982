import { Box } from '@mui/material';
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';

function ShowLongText({ text, header='Description' }) {
    const [isShow, setIsShow] = useState(false)
    return (
        <>
            <div>{text?.length > 50 ? <Box sx={{ cursor: 'pointer' }} onClick={()=>setIsShow(true)}>{`${text?.slice(0, 45)}...`}</Box> : text}</div>
            {isShow && <Modal show={isShow} onHide={()=>setIsShow(false)}>
                <Modal.Header >
                    <Modal.Title>{header}</Modal.Title>
                    <Button variant="secondary" onClick={()=>setIsShow(false)} style={{ marginLeft: "auto" }}>
                        Close
                    </Button>
                </Modal.Header>
                <Modal.Body><span style={{ wordWrap: 'break-word', marginTop:'-20px'}}>{text}</span></Modal.Body>
            </Modal>}
        </>
    )
}

export default ShowLongText