import Switch from '@mui/material/Switch'
import Swal from 'sweetalert2'
import NoDataImg from '../../../assets/img/no-data.gif'
import Pagination from '@mui/material/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@mui/material/Button'
import Box from "@mui/material/Box";
import dayjs, { Dayjs } from 'dayjs'
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { AddEffect } from './AddEffect'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import { ViewEffect } from './ViewEffect'
import Utils from '../../constants/Utils'
import ShowLongText from '../../custom/ShowLongText'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const EffectsList = props => {
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [tags, setTags] = useState([])
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector(state => state?.userData)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const [datalist, setDatalist] = useState([])
  const [defaultWidgetOption, setDefaultWidgetOption] = useState(null)
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [value, setValue] = useState(dayjs())
  const dispatch = useDispatch()
  const [thumbnailFile, setThumbnailFile] = useState()
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [effectFile, setEffectFile] = useState()
  const [widgetKeyValueData, setWidgetKeyValueData] = useState([])
  const [page, setPage] = useState(1)
  const [inputValue, setInputValue] = useState('')
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
  }
  const [showAddEffect, setShowAddEffect] = useState(false)
  const [effectFormData, setEffectFormData] = useState({
    id: '',
    name: '',
    duration: 0,
    widgetId: '',
    description: '',
    imageUrl: '',
  })
  const [thumbanailControl, setThumbanailControl] = useState({
    showError: true,
    errorMessage: '',
    showImage: false,
    imageData: null
  })
  const [filterFileControl, setFilterFileControl] = useState({
    showError: true,
    errorMessage: '',
    showImage: false,
    imageData: null
  })
  const [showViewEffect, setShowViewEffect] = useState(false)
  const [effectViewFormData, setEffectViewFormData] = useState({
    name: '',
    duration: 0,
    widgetId: '',
    description: '',
    widgetName: '',
    description: '',
    createdAt: '',
    updatedAt: '',
    active: '',
    viewCount: 0,
    playCount: 0,
    likeCount: 0,
    followerCount: 0,
    tags: ''
  })

  const handleFilterClick = () => {
    getEffectList(searchText, startDate, endDate)
    console.log('Handle Filter Click')
  }

  const handleResetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setStatusDropdownText('Select Status')
    setSearchText('')
    getEffectList('')
  }

  useEffect(() => {
    getEffectList('', startDate, endDate, statusDropdownText)
  }, [startDate, endDate, statusDropdownText, page])

  const handleOnSubmitFilterModel = async () => {
    try {
      let postJson = {
        name: effectFormData?.name,
        duration: effectFormData?.duration,
        widgetId: effectFormData?.widgetId,
        description: effectFormData?.description,
        tags: tags,
        id: effectFormData?.id
      }
      let form_data = new FormData()
      form_data.append(
        'effectDto',
        new Blob([JSON.stringify(postJson)], {
          type: 'application/json'
        })
      )
      form_data.append('thumbnail', thumbnailFile)
      form_data.append('effect', effectFile)
      dispatch(uplodateLoading(true))
      let path = urls.addEffect
      var res = await ApiCaller.post_formdata(
        form_data,
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getEffectList()
        setShowAddEffect(false)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const handleAddFilter = () => {
    getWidgetKeyValuePair()
    setEffectFormData({
      id: '',
      name: '',
      duration: 0,
      widgetId: '',
      description: '',
      imageUrl:''
    })
    setShowAddEffect(true)
    setDefaultWidgetOption(null)
    setTags([])
    setThumbanailControl({
      showError: true,
      errorMessage: '',
      showImage: false,
      imageData: null
    })
    setThumbnailFile(null)
    setFilterFileControl({
      showError: true,
      errorMessage: '',
      showImage: false,
      imageData: null
    })
    setEffectFile(null)
  }

  const handleDeleteEffect = data => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async result => {
      if (result.isConfirmed) {
        deleteApiCall(data?.id)
      }
    })
  }

  const deleteApiCall = async id => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.deleteEffect + '/' + id
      let res = await ApiCaller.delete({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        Swal.fire('Deleted!', response?.message, 'success')
        getEffectList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditEffect = item => {
    console.log("bashbajvhfchavbfckbakfcbasbc", item)
    getWidgetKeyValuePair(item?.widgetId)
    setEffectFormData(item)
    setTags(item?.tags)
    setShowAddEffect(true)
    setThumbnailFile(item?.thumbnailFile)
    setEffectFile(item?.effectUrl)
  }

  const handleViewEffect = id => {
    getEffectWithId(id)
  }

  const getEffectWithId = async id => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.getEffect + '/' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setEffectViewFormData(prevState => {
          return {
            ...prevState,
            ...response.data
          }
        }) 
        setShowViewEffect(true)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeEffectStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getEffectList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleHideModel = () => {
    setShowAddEffect(false)
    setInputValue('')
    setTags([])
    setThumbnailFile(null)
    setThumbanailControl({
      showError: true,
      errorMessage: '',
      showImage: false,
      imageData: null
    })
    setFilterFileControl({
      showError: true,
      errorMessage: '',
      showImage: false,
      imageData: null
    })

  }

  useEffect(() => {
    dispatch(updateTopHeader('Effect List'))
  })

  const getEffectList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchTextRef,
        pageNumber: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        active:
          statusDropdownText === 'Active'
            ? true
            : statusDropdownText === 'In Active'
              ? false
              : null,
        startDate:
          startDateRef && startDateRef !== null
            ? startDateRef.toDate()
            : '',
        endDate:
          endDateRef && endDateRef !== null ? endDateRef.toDate() : ''
      }
      let path = urls.effectList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.response?.items)
        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === 'EDIT') {
            setEdit(module.isEnable)
          } else if (module.permissionName === 'ACTIVE') {
            setActive(module.isEnable)
          } else if (module.permissionName === 'DELETE') {
            setDeletePermission(module.isEnable)
          } else if (module.permissionName === 'VIEW') {
            setView(module.isEnable)
          }
        })
        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  useEffect(() => {
    getEffectList()
  }, [sort_order, sort_type, props])

  const getWidgetKeyValuePair = async widgetId => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.widgetKeyValuePair
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempWidgetKeyValueList = [{ value: '', label: 'Select Widget' }]
        response?.data?.map((data, i) => {
          tempWidgetKeyValueList.push({ value: data?.value, label: data?.key })
          if (data?.value === widgetId) {
            setDefaultWidgetOption({ value: data?.value, label: data?.key })
          }
        })
        setWidgetKeyValueData(tempWidgetKeyValueList)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <>
        <NavBar />
        <div className='main-content'>
          <NavBarTop />
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
            </div>
          </div>
          <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Effect List</h3>
                      </div>
                      <div className='col text-right'>
                        {userData.admin ? (
                          <a
                            onClick={handleAddFilter}
                            className='btn btn-sm btn-primary text-dark'
                          >
                            <AddIcon />
                            Add Effect
                          </a>
                        ) : edit ? (
                          <a
                            onClick={handleAddFilter}
                            className='btn btn-sm btn-primary text-dark'
                          >
                            <AddIcon />
                            Add Effect
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            placeholder='Search by Display Name'
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            aria-label='Amount (to the nearest dollar)'
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '10px',
                              borderTopLeftRadius: '0px',
                              borderBottomLeftRadius: '0px'
                            }}
                          >
                            <SearchIcon />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className='col-md-1 mb-2 ' style={{ marginRight: '30px' }}>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              border: '1px solid #cad1d7',
                              boxShadow: 'none',
                              transform: 'none'
                            }}
                            id='dropdown-basic'
                          >
                            {statusDropdownText}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                setStatusDropdownText('Select Status')
                              }
                            >
                              Select Status
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('Active')}
                            >
                              Active
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('In Active')}
                            >
                              In Active
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div
                        className='col-md-4 mb-3'
                        style={{ marginLeft: '30px' }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker
                              disableFuture
                              className='custom-date'
                              label='From Date'
                              value={startDate}
                              onChange={newValue => setStartDate(newValue)}
                            />
                            <DatePicker
                              disableFuture
                              label='To Date'
                              value={endDate}
                              onChange={newValue => setEndDate(newValue)}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {datalist && datalist?.length > 0 ? (
                      <>
                        <table className='table align-items-center table-flush'>
                          <thead className='thead-light'>
                            <tr>
                              <th scope='col'>Display Name</th>
                              <th scope='col'>Description</th>
                              <th scope='col'>Duration</th>
                              <th scope='col'>View Count</th>
                              <th scope='col'>Play Count</th>
                              <th scope='col'>Like Count</th>
                              <th scope='col'>Follower Count</th>
                              <th scope='col'>Tags</th>
                              <th scope='col'>Content Type</th>
                              <th scope='col'>Created at</th>
                              <th scope='col'>Thumbnail</th>
                              <th scope='col'>Widget</th>
                              <th scope='col'>Status</th>
                              <th scope='col' />
                            </tr>
                          </thead>
                          <tbody>
                            {datalist &&
                              datalist?.map((data, i) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{data?.name}</td>
                                      <td className='custom-td-width'>
                                      <ShowLongText text={data?.description} header='Description' /></td>
                                      <td>{data?.duration} Min</td>
                                      <td>1</td>
                                      <td>2</td>
                                      <td>4</td>
                                      <td>5</td>
                                      <td>
                                        {data?.tags && data?.tags.length ? (
                                          <>{data?.tags.join(', ')}</>
                                        ) : (
                                          <>-</>
                                        )}
                                      </td>
                                      <td>{data?.widgetContentType}</td>
                                      <td>
                                        {Utils.formatDate(data?.createdAt)}
                                      </td>
                                      <td>
                                        <Image
                                          image={{
                                            id: 1,
                                            src: data?.thumbnailUrl
                                          }}
                                          width='80px'
                                          height='auto'
                                        />
                                      </td>
                                      <td>{data?.widgetName}</td>

                                      <td>
                                        {userData.admin ? (
                                          <Switch
                                            {...label}
                                            checked={data?.active}
                                            onChange={e =>
                                              handleStatusChange(e, data?.id)
                                            }
                                            size='small'
                                          />
                                        ) : active ? (
                                          <Switch
                                            {...label}
                                            checked={data?.active}
                                            onChange={e =>
                                              handleStatusChange(e, data?.id)
                                            }
                                            size='small'
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                      <td className='text-right'>
                                        <div className='dropdown'>
                                          <a
                                            className='btn btn-sm btn-icon-only text-light'
                                            href='#'
                                            role='button'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                          >
                                            <i className='fas fa-ellipsis-v' />
                                          </a>
                                          <div
                                            className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                            x-placement='bottom-end'
                                            style={{
                                              position: 'absolute',
                                              willChange: 'transform',
                                              top: '0px',
                                              left: '0px',
                                              transform:
                                                'translate3d(32px, 32px, 0px)'
                                            }}
                                          >
                                              {userData.admin ? (
                                                <a
                                                  className="dropdown-item cursor"
                                                  onClick={() =>
                                                    handleEditEffect(data)
                                                  }
                                                >
                                                  Edit
                                                </a>
                                              ) : edit ? (
                                                <a
                                                  className="dropdown-item cursor"
                                                  onClick={() =>
                                                    handleEditEffect(data)
                                                  }
                                                >
                                                  Edit
                                                </a>
                                              ) : (
                                                <></>
                                              )}
                                            {userData.admin ? (
                                              <a
                                                className='dropdown-item cursor'
                                                onClick={() =>
                                                  handleDeleteEffect(data)
                                                }
                                              >
                                                Delete
                                              </a>
                                            ) : deletePermission ? (
                                              <a
                                                className='dropdown-item cursor'
                                                onClick={() =>
                                                  handleDeleteEffect(data)
                                                }
                                              >
                                                Delete
                                              </a>
                                            ) : (
                                              <></>
                                            )}
                                             <a className="dropdown-item cursor"
                                                      onClick={() =>
                                                        handleViewEffect(data.id)
                                                      }
                                                    >
                                                View
                                              </a>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <div className='text-center'>
                          <img width={'25%'} src={NoDataImg}></img>
                          <br />
                          <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  {datalist && datalist?.length > 0 ? (
                    <>
                      <div className='card-footer py-4'>
                      <Box display="flex" justifyContent="right" alignItems='center'>
                        <span>Total record: {pagerecord?.totalItems}</span>
                        <Pagination
                          className='custom-pagination-class'
                          count={pagerecord?.totalPages}
                          page={page}
                          onChange={handleChange}
                          color='secondary'
                        />
                        </Box>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/*   <Footer /> */}
          </div>
          <ViewEffect
            show={showViewEffect}
            effectViewFormData={effectViewFormData}
            onHide={() => setShowViewEffect(false)}
          />
          <AddEffect
            setTags={setTags}
            tags={tags}
            show={showAddEffect}
            effectFormData={effectFormData}
            widgetKeyValueData={widgetKeyValueData}
            defaultWidgetOption={defaultWidgetOption}
            setEffectFormData={setEffectFormData}
            onSubmit={() => handleOnSubmitFilterModel()}
            onHide={() => handleHideModel()}
            setThumbnailFile={setThumbnailFile}
            setEffectFile={setEffectFile}
            thumbnailFile={thumbnailFile}
            effectFile={effectFile}
            thumbanailControl={thumbanailControl}
            setThumbanailControl={setThumbanailControl}
            filterFileControl={filterFileControl}
            setFilterFileControl={setFilterFileControl}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        </div>
      </>
    </>
  )
}
