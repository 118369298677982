import NoDataImg from '../../../assets/img/no-data.gif'
import Pagination from '@mui/material/Pagination'
import Switch from '@mui/material/Switch'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@mui/material/Button'
import Box from "@mui/material/Box";
import dayjs, { Dayjs } from 'dayjs'
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import { EditStaticContent } from './EditStaticContent'
import Utils from '../../constants/Utils'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const StaticList = props => {
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [tags, setTags] = useState([])
  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
  }


  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [value, setValue] = useState(dayjs())
  const dispatch = useDispatch()
  const [datalist, setDatalist] = useState([])
  const [defaultWidgetOption, setDefaultWidgetOption] = useState(null)
  const [widgetKeyValueData, setWidgetKeyValueData] = useState([])
  const [showAddStatic, setShowAddStatic] = useState(false)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector(state => state?.userData)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [staticContentFormData, setStaticContentFormData] = useState({
    id: '',
    title: '',
    description: '',
    metaTitle:'',
    metaDescription:''
  })
  const handleOnSubmitStaticModel = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.updateStaticContent
      let res = await ApiCaller.post(
        {...staticContentFormData,metaKeywords:tags},
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        setShowAddStatic(false)
        getStaticList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const handleAddFilter = () => {
    setStaticContentFormData({})
    getWidgetKeyValuePair()
    setShowAddStatic(true)
  }

  const { RangePicker } = DatePicker;

  const handleFilterClick = () => {
    getStaticList(searchText, startDate, endDate)
  }
  const handleResetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setSearchText('')
    getStaticList('', null, null)
  }


  const getWidgetKeyValuePair = async roleId => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.widgetKeyValuePair
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempWidgetKeyValueList = [{ value: '', label: 'Select Widget' }]
        response?.data?.map((data, i) => {
          tempWidgetKeyValueList.push({ value: data?.value, label: data?.key })
          if (data?.key === roleId) {
            setDefaultWidgetOption({ value: data?.value, label: data?.key })
          }
        })
        setWidgetKeyValueData(tempWidgetKeyValueList)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditStaticContent = item => {
    setStaticContentFormData(item)
    setTags(item?.metaKeywords)
    setShowAddStatic(true)
  }

  useEffect(() => {
    dispatch(updateTopHeader('Static Content Manager'))
  }, [])

  useEffect(() => {
    getStaticList()
  }, [sort_order, sort_type, props])

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeFilterStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const getStaticList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchTextRef?.trim(),
        pageNumber: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        startDate:
          startDateRef && startDateRef !== null
            ? startDateRef.toDate()
            : '',
        endDate:
          endDateRef && endDateRef !== null ? endDateRef.toDate() : ''
      }    
      let path = urls.staticContentList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }


  return (
    <>
      <>
        <NavBar />
        <div className='main-content'>
          <NavBarTop />
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
            </div>
          </div>
          <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Static Content List</h3>
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            placeholder='Search by Static Page'
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            aria-label='Amount (to the nearest dollar)'
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '10px',
                              borderTopLeftRadius: '0px',
                              borderBottomLeftRadius: '0px'
                            }}
                          >
                            <SearchIcon />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className='col-md-6 mb-3'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker
                              disableFuture
                              className='custom-date'
                              label='From Date'
                              value={startDate}
                              onChange={newValue => setStartDate(newValue)}
                            />
                            <DatePicker
                              disableFuture
                              label='To Date'
                              value={endDate}
                              onChange={newValue => setEndDate(newValue)}
                              minDate={startDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {datalist?.length ? (
                      <table className='table align-items-center table-flush'>
                        <thead className='thead-light'>
                          <tr>
                            {/* <th scope='col'>Static Page Slug</th> */}
                            <th scope='col'>Static Page Title</th>
                            <th scope='col'>Created Date</th>
                            <th scope='col' />
                          </tr>
                        </thead>
                        <tbody>
                          {datalist?.map((data, i) => {
                            return (
                              <>
                                <tr>
                                  {/* <td>{data?.slug}</td> */}
                                  <td>{data?.title}</td>
                                  <td>{Utils.formatDate(data?.createdAt)}</td>
                                  <td className='text-right'>
                                    <div className='dropdown'>
                                      <a
                                        className='btn btn-sm btn-icon-only text-light'
                                        href='#'
                                        role='button'
                                        data-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                      >
                                        <i className='fas fa-ellipsis-v' />
                                      </a>
                                      <div
                                        className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                        x-placement='bottom-end'
                                        style={{
                                          position: 'absolute',
                                          willChange: 'transform',
                                          top: '0px',
                                          left: '0px',
                                          transform:
                                            'translate3d(32px, 32px, 0px)'
                                        }}
                                      >
                                        <a
                                          className='dropdown-item cursor'
                                          onClick={() =>
                                            handleEditStaticContent(data)
                                          }
                                        >
                                          Edit
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <>
                        <div className='text-center'>
                          <img width={'25%'} src={NoDataImg}></img>
                          <br />
                          <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? <>Loading Data.....</> :<>No Data Found</>}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  {datalist?.length ? (
                    <>
                      <div className='card-footer py-4'>
                      <Box display="flex" justifyContent="right" alignItems='center'>
                        <span>Total record: {pagerecord?.totalItems}</span>
                        <Pagination
                          className='custom-pagination-class'
                          count={pagerecord?.totalPages}
                          page={page}
                          onChange={handleChange}
                          color='secondary'
                        />
                        </Box>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <EditStaticContent
             tags={tags}
             setTags={setTags}
              show={showAddStatic}
              staticContentFormData={staticContentFormData}
              setStaticContentFormData={setStaticContentFormData}
              onSubmit={() => handleOnSubmitStaticModel()}
              onHide={() => setShowAddStatic(false)}
            />
          </div>
        </div>
      </>
    </>
  )
}
