import { useCallback, useEffect, useReducer, useState } from "react";
import NoDataImg from "../../../assets/img/no-data.gif";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { v4 as uuidv4 } from 'uuid';
import SearchIcon from "@mui/icons-material/Search";
import InputGroup from "react-bootstrap/InputGroup";
import Pagination from "@mui/material/Pagination";
import NavBar from "../../structure/NavBar";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Form from "react-bootstrap/Form";
import { NavBarTop } from "../../structure/NavBarTop";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTopHeader,
  uplodateLoading,
} from "../../../redux/features/common/commonSlice";
import { AddRole } from "./AddRole";
import AddIcon from "@mui/icons-material/Add";
import urls from "../../constants/apiurl";
import ApiCaller from "../../constants/ApiCaller";
import { showDangerToast, showToast } from "../../constants/toaster";
import Switch from "@mui/material/Switch";
import { RolePermissions } from "./RolePermissions";
import { RoleBasedSubAdminList } from "./RoleBasedSubAdminList";
import ShowLongText from "../../custom/ShowLongText";
const label = { inputProps: { "aria-label": "Size switch demo" } };
export const RoleList = (props) => {
  const [callAPi, setCallAPi] = useState('')
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [view, setView] = useState(false);
  const userData = useSelector((state) => state?.userData);
  const [showAddRole, setShowAddRole] = useState(false);
  const [datalist, setDatalist] = useState([]);
  const [viewPermissionControl, setViewPermissionControl] = useState(false);
  const [viewSubAdminList, setViewSubAdminList] = useState(false);
  const [sort_type, setSortType] = useState("createdAt");
  const [sort_order, setSortOrder] = useState(false);
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
  });
  const [roleFormData, setRoleFormData] = useReducer(
    (roleFormData, newItem) => {
      return { ...roleFormData, ...newItem };
    },
    { name: "", id: "", description: "" }
  );
  const [permissionSubmitData, setPermissionSubmitData] = useState([]);
  const [rolePermissionData, setRolePermissionData] = useState({});
  const [subAdminListByRole, setSubAdminListByRole] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10,
    });
    setCallAPi(uuidv4())
  };


  const handleFilterClick = useCallback(() => {
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }, [searchText, startDate, endDate]);

  const handleResetFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchText("");
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  };


  useEffect(() => {
    dispatch(updateTopHeader("Role Manager"));
  }, []);
  useEffect(() => {
    getRoleList()
  }, [callAPi])

  const getGetRolePermssions = async (id) => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.rolePermissionDetails + "/" + id;
      let res = await ApiCaller.get({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        setRolePermissionData(response?.data);
        setViewPermissionControl(true);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };
  const getSubAdminsByRoleId = async (id) => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.subAdminByRole + "/" + id;
      let res = await ApiCaller.get({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        setSubAdminListByRole(response?.data);
        setViewSubAdminList(true);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.changeRoleStatus + "/" + id;
      let res = await ApiCaller.put({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        showToast(response?.message);
        getRoleList(searchText, startDate, endDate);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const getRoleList = async () => {
    let startDateObject;
    let endDateObject;
    if (startDate?.toDate()) {
      let newDate = new Date(startDate?.toDate());
      newDate.setDate(newDate.getDate() + 1);
      startDateObject = newDate;
    }
    if (endDate?.toDate()) {
      // Create a new Date object based on startDate
      let newDate = new Date(endDate?.toDate());
      // Add one day
      newDate.setDate(newDate.getDate() + 1);
      endDateObject = newDate;
    }
    try {
      dispatch(uplodateLoading(true));
      let postJson = {
        searchText: searchText?.trim(),
        pageNumber: pagerecord?.pageNumber - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        startDate:
          startDateObject !== null
            ? startDateObject
            : '',
        endDate:
          endDateObject !== null
            ? endDateObject
            : '',
      };
      let path = urls.roleList;
      let res = await ApiCaller.post(postJson, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        setDatalist(response?.data?.response?.items);
        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === "EDIT") {
            setEdit(module.isEnable);
          } else if (module.permissionName === "ACTIVE") {
            setActive(module.isEnable);
          } else if (module.permissionName === "DELETE") {
            setDeletePermission(module.isEnable);
          } else if (module.permissionName === "VIEW") {
            setView(module.isEnable);
          }
        });
        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pagerecord?.pageNumber,
          pageSize: 10,
        });
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const handleEditRole = (item) => {
    setRoleFormData(item);
    setShowAddRole(true);
  };

  const handleAddRole = () => {
    setRoleFormData({
      id: "",
      name: "",
      description: "",
    });
    setShowAddRole(true);
  };

  const handleOnSubmitRoleModel = async () => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.addRole;
      let res = await ApiCaller.post(
        roleFormData,
        path,
        userData?.access_token
      );
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        showToast(response?.message);
        setShowAddRole(false);
        getRoleList();
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const submitPermissionControl = async () => {
    try {
      dispatch(uplodateLoading(true));
      let postData = {
        permissions: permissionSubmitData,
      };
      let path = urls.rolePermissionUpdate;
      let res = await ApiCaller.post(postData, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        showToast(response?.message);
        setViewPermissionControl(false);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  return (
    <>
      <NavBar />
      <div className="main-content">
        <NavBarTop />
        <div className="header">
          <div className="logo">
            <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8"></div>
          </div>
        </div>
        {viewPermissionControl ? (
          <RolePermissions
            submitPermissionControl={submitPermissionControl}
            setViewPermissionControl={setViewPermissionControl}
            setPermissionSubmitData={setPermissionSubmitData}
            permissionSubmitData={permissionSubmitData}
            rolePermissionData={rolePermissionData}
          />
        ) : (
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col text-left">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <div className="row align-items-center text-left">
                      <div className="col">
                        <h3 className="mb-0">Role List</h3>
                      </div>
                      <div className="col text-right">
                        {userData.admin ? (
                          <a
                            onClick={handleAddRole}
                            className="btn btn-sm btn-primary text-dark"
                          >
                            <AddIcon />
                            Add New
                          </a>
                        ) : edit ? (
                          <a
                            onClick={handleAddRole}
                            className="btn btn-sm btn-primary text-dark"
                          >
                            <AddIcon />
                            Add New
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-header border-0">
                    <div className="row align-items-center text-left">
                      <div className="col-md-3">
                        <InputGroup
                          className="mb-2"
                          style={{ borderRadius: "10px" }}
                        >
                          <Form.Control
                            placeholder="Search by Title"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            aria-label="Amount (to the nearest dollar)"
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px",
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                          >
                            {/* <SearchIcon onClick={handleFilterClick}/> */}
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className="col-md-6 mb-3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["DatePicker", "DatePicker"]}
                          >
                            <DatePicker
                              disableFuture
                              className="custom-date"
                              label="From Date"
                              value={startDate}
                              onChange={(newValue) => setStartDate(newValue)}
                            />
                            <DatePicker
                              disableFuture
                              label="To Date"
                              value={endDate}
                              onChange={(newValue) => setEndDate(newValue)}
                              minDate={startDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className="col-md-3 mb-2">
                        <Button
                          variant="contained"
                          className="custom-filter-btn"
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant="contained"
                          className="ml-2 custom-filter-btn"
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {datalist?.length ? (
                      <>
                        <table className="table align-items-center table-flush">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Title</th>
                              <th scope="col">Description</th>
                              <th scope="col">Status</th>
                              <th scope="col">No of Sub-Admin</th>
                              <th scope="col" />
                            </tr>
                          </thead>
                          <tbody>
                            {datalist?.map((data, i) => {
                              return (
                                <tr>
                                  <th scope="row">
                                    <div className="media align-items-center">
                                      <div className="media-body">
                                        <span className="mb-0 text-sm">
                                          {data?.name}
                                        </span>
                                      </div>
                                    </div>
                                  </th>
                                  <td><ShowLongText text={data?.description} header='Description' /></td>
                                  <td>
                                    {userData.admin ? (
                                      <Switch
                                        {...label}
                                        checked={data?.active}
                                        onChange={(e) =>
                                          handleStatusChange(e, data?.id)
                                        }
                                        size="small"
                                      />
                                    ) : active ? (
                                      <Switch
                                        {...label}
                                        checked={data?.active}
                                        onChange={(e) =>
                                          handleStatusChange(e, data?.id)
                                        }
                                        size="small"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </td>

                                  <td>
                                    <div className="d-flex align-items-center">
                                      <span className="mr-2">
                                        {data?.noOfSubAdmin}
                                      </span>
                                      {/* <div>
                                  <div className='progress'>
                                    <div
                                      className='progress-bar bg-warning'
                                      role='progressbar'
                                      aria-valuenow={60}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                      style={{ width: '60%' }}
                                    />
                                  </div>
                                </div> */}
                                    </div>
                                  </td>
                                  <td className="text-right">
                                    <div className="dropdown">
                                      <a
                                        className="btn btn-sm btn-icon-only text-light"
                                        href="#"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="fas fa-ellipsis-v" />
                                      </a>
                                      <div
                                        className="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                                        x-placement="bottom-end"
                                        style={{
                                          position: "absolute",
                                          willChange: "transform",
                                          top: "0px",
                                          left: "0px",
                                          transform:
                                            "translate3d(32px, 32px, 0px)",
                                        }}
                                      >
                                        {userData.admin ? (
                                          <a
                                            className="dropdown-item cursor"
                                            onClick={() => handleEditRole(data)}
                                          >
                                            Edit
                                          </a>
                                        ) : edit ? (
                                          <a
                                            className="dropdown-item cursor"
                                            onClick={() => handleEditRole(data)}
                                          >
                                            Edit
                                          </a>
                                        ) : (
                                          <></>
                                        )}

                                        <a
                                          className="dropdown-item cursor"
                                          onClick={() =>
                                            getGetRolePermssions(data?.id)
                                          }
                                        >
                                          Permission Control
                                        </a>
                                        <a
                                          className="dropdown-item cursor"
                                          onClick={() =>
                                            getSubAdminsByRoleId(data?.id)
                                          }
                                        >
                                          View All Sub Admins
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <div className="text-center">
                          <img width={"25%"} src={NoDataImg}></img>
                          <br />
                          <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  {datalist && datalist.length > 0 ? (
                    <div className="card-footer py-4">
                      <Box display="flex" justifyContent="right" alignItems='center'>
                        <span>Total record: {pagerecord?.totalItems}</span>
                        <Pagination
                          className="custom-pagination-class"
                          count={pagerecord?.totalPages}
                          page={page}
                          onChange={handleChange}
                          color="secondary"
                        />
                      </Box>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <AddRole
          show={showAddRole}
          roleFormData={roleFormData}
          setRoleFormData={setRoleFormData}
          onSubmit={() => handleOnSubmitRoleModel()}
          onHide={() => setShowAddRole(false)}
        />
        <RoleBasedSubAdminList
          show={viewSubAdminList}
          subAdminListByRole={subAdminListByRole}
          onHide={() => setViewSubAdminList(false)}
        />
      </div>
    </>
  );
};
