import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Utils from '../../constants/Utils'
export const ViewDetails = props => {
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='row'>
          <h3 className='col-md-12 text-center'>View Video Details</h3>
        </div>
        <hr />
        <div className='row mt-5'>
          <div className='col-md-6'>
            <b>UserName:</b> {props?.selectedVideoDetails?.profileDetails?.userName}
          </div>
          <div className='col-md-6'>
            <b>Mobile No:</b> {props?.selectedVideoDetails?.profileDetails?.mobile}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <b>Posted On</b> {Utils.formatDate(props?.selectedVideoDetails?.createdAt)}
          </div>
          <div className='col-md-6'>
            <b>Video Link:</b>{' '}
            <a href={props?.selectedVideoDetails?.link} target='_blank'>
              Click Here
            </a>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <b>Likes:</b> {props?.selectedVideoDetails?.totalLikes}
          </div>
          <div className='col-md-6'>
            <b>Comments:</b> {props?.selectedVideoDetails?.totalComments}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <b>Reports:</b> {props?.selectedVideoDetails?.totalReports}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
         Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
