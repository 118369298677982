import Button from 'react-bootstrap/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useEffect } from 'react'
import ApiCaller from '../../constants/ApiCaller'
import urls from '../../constants/apiurl'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import { useState } from 'react'
import { showDangerToast } from '../../constants/toaster'
import { useDispatch, useSelector } from "react-redux"

export const AddSubAdmin = props => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.userData);
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [isValidMobile, setIsValidMobile] = useState(true)
  const [isValidFirstName, setIsValidFirstName] = useState(true)
  const [isValidLastName, setIsValidLastName] = useState(true)
  const [countryCodeList, setCountryCodeList] = useState([{
    value: "",
    label: "Select Country"
  }])
  const [countryDetails, setCountryDetails] = useState({
    value: "",
    label: "Select Country"
  })


  useEffect(() => {
    if (props?.show) {
      console.log("subAdminFormData", props?.subAdminFormData)
      getCountryCodeList()
    }
  }, [props?.show])

  const getCountryCodeList = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.countryCodeList
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempArray = []
        response?.data.forEach(element => {
          tempArray.push({
            value: element?.id,
            label: element?.code,
            // ext: element?.code
          })
        });
        setCountryCodeList(tempArray)
        if (tempArray?.length > 0) {
          setCountryDetails(
            tempArray[0]
          )
          props?.setCountryId(tempArray[0]?.value)
        } else {
          setCountryDetails({
            value: "",
            label: '',
            // ext: ''
          })
        }
        // let tempArray = [...countryCodeList]
        // response?.data.forEach(element => {
        //   tempArray.push({
        //     value: element?.id,
        //     label: element?.name,
        //     ext: element?.code
        //   })
        // });
        // setCountryCodeList(tempArray)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleChangeEmail = (e) => {
    const inputValue = e.target.value;
    props.setSubAdminFormData({
      type: 'UPDATE',
      payload: { email: inputValue }
    })
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputValue);
    setIsValidEmail(isValid);
  }
  const handleChangeMobile = (e) => {
    const inputValue = e.target.value;
    const numericInput = inputValue.replace(/[^0-9]/g, '');
    props.setSubAdminFormData({
      type: 'UPDATE',
      payload: { contactNumber: numericInput }
    })
    if (!inputValue) {
      setIsValidMobile(false)
    } else {
      setIsValidMobile(true)
    }
  }

  const handleSubmit = () => {
    if (
      props?.subAdminFormData.firstName === '' ||
      props?.subAdminFormData.lastName === '' ||
      props?.subAdminFormData.email === '' ||
      props?.subAdminFormData.contactNumber === '' ||
      props?.subAdminFormData?.roleId === '' ||
      countryDetails?.value === ''
    ) {
      showDangerToast('Please fill all required fields')
    } else {
      if (!isValidEmail) {
        showDangerToast('Invalid Email')
      } else if (!isValidMobile) {
        showDangerToast('Invalid Mobile')
      } else {
        props.onSubmit()
      }
    }
  }

  const handleNameInputChange = (e, inputType) => {
    const value = e.target.value;
    // Check if the input matches the validation criteria
    const isValidInput = /^[a-zA-Z!@#$%^&*()_+]{2,20}$/.test(value);
    // Update the state based on validation result
    if (inputType === 'firstName') {
      setIsValidFirstName(isValidInput)
    } else if (inputType === 'lastName') {
      setIsValidLastName(isValidInput)
    }
    props.setSubAdminFormData({
      type: 'UPDATE',
      payload: { [inputType]: value }
    })
  };

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.subAdminFormData?.id ? 'Update SubAdmin' : 'Add SubAdmin'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-6'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <h4>First Name*</h4>
              <Form.Control
                type='text'
                placeholder='Enter First Name'
                value={props.subAdminFormData.firstName}
                onChange={e => handleNameInputChange(e, 'firstName')}
              />
              {props?.subAdminFormData?.firstName === '' ? (
                <label className='custom-error-label'>
                  First Name is required*
                </label>
              ) : (
                !isValidFirstName && (
                  <label className='custom-error-label'>
                    Input must be 2-20 characters long and contain at least one uppercase letter, one lowercase letter, and one special character.
                  </label>
                )
              )}
            </Form.Group>
          </div>
          <div className='col-md-6'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <h4>Last Name*</h4>
              <Form.Control
                type='text'
                placeholder='Enter Last Name'
                value={props.subAdminFormData.lastName}
                onChange={e => handleNameInputChange(e, 'lastName')}
              />
              {props?.subAdminFormData?.lastName === '' ? (
                <label className='custom-error-label'>
                  Last Name is required*
                </label>
              ) : (
                !isValidLastName && (
                  <label className='custom-error-label'>
                    Input must be 2-20 characters long and contain at least one uppercase letter, one lowercase letter, and one special character.
                  </label>)
              )}
            </Form.Group>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <h4>Email*</h4>
              <Form.Control
                type='text'
                placeholder='Enter Email'
                value={props.subAdminFormData.email}
                onChange={handleChangeEmail}
              />
              {props?.subAdminFormData?.email === '' ? (
                <label className='custom-error-label'>Email is required*</label>
              ) : (
                !isValidEmail ? <>
                  <label className='custom-error-label'>Invalid Email*</label>
                </> : <></>
              )}
            </Form.Group>
          </div>
          <div className='col-md-6'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <h4>Phone No.*</h4>
              <div className='d-flex'>
                <Dropdown
                  options={countryCodeList}
                  onChange={e => {
                    setCountryDetails(e)
                    props?.setCountryId(e?.value)
                  }}
                  value={countryDetails}
                />
                {countryDetails?.value === '' ? (
                  <label className='custom-error-label'>Country is required*</label>
                ) : (
                  <></>
                )}
                <Form.Control
                  type='text'
                  maxLength={10}
                  minLength={7}
                  placeholder='Enter Phone No.'
                  value={props.subAdminFormData.contactNumber}
                  onChange={handleChangeMobile}
                />
              </div>
              {props?.subAdminFormData?.contactNumber === '' ? (
                <label className='custom-error-label'>Phone is required*</label>
              ) : (
                !isValidMobile ? <>
                  <label className='custom-error-label'>Invalid Mobile*</label>
                </> : <>
                  {props?.subAdminFormData?.contactNumber && props?.subAdminFormData?.contactNumber.length < 7 && <label className='custom-error-label'>Mobile should be between 7-12 *</label>}

                </>
              )}
            </Form.Group>
          </div>
          <div className='col-md-6 mt-3'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <h4>Role.*</h4>
              <Dropdown
                options={props?.roleKeyValueData}
                onChange={e =>
                  props.setSubAdminFormData({
                    type: 'UPDATE',
                    payload: { roleId: e.value }
                  })
                }
                value={props?.defaultRoleOption}
                placeholder='Select Role'
              />
              {props?.subAdminFormData?.roleId === '' ? (
                <label className='custom-error-label'>Role is required*</label>
              ) : (
                <></>
              )}
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={!props?.subAdminFormData?.firstName || !props?.subAdminFormData?.lastName || !isValidFirstName || !isValidLastName || props?.subAdminFormData?.contactNumber && props?.subAdminFormData?.contactNumber.length < 7}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
