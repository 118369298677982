import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useCallback, useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DropBox from "../../custom/DropBox";
import ShowImage from "../../custom/ShowImage";
import { showDangerToast } from "../../constants/toaster";
const imageFileTypes = ["image/png", "image/gif", "image/jpeg"];
const otherFileTypes = [".arscene", ".videofx"];

export const AddBanner = (props) => {
  useEffect(() => {
    if (props?.bannerFormData?.id) {
      if (props.bannerImage) {
        props.setThumbanailControl({
          showError: false,
          errorMessage: "",
          showImage: true,
          imageData: { src: props?.bannerFormData?.imageUrl },
        });
      }
    }
  }, [props.show]);
  const [value, setValue] = useState(dayjs());
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);

  const [startDate, setStartDate] = useState(new Date("2023-10-16T06:23:56"));
  const [endDate, setEndDate] = useState();
  const [startDateChanged, setStartDateChanged] = useState(false);

  const handleSubmit = () => {
    if (props?.bannerFormData?.id) {
      if (
        props?.bannerFormData?.name &&
        props?.bannerFormData?.hyperLink &&
        props?.bannerFormData?.startDate &&
        props?.bannerFormData?.endDate
      ) {
        props.onSubmit();
      } else {
        showDangerToast("Please fill all required Fields");
      }
    } else {
      if (
        props?.bannerFormData?.name &&
        props?.bannerFormData?.hyperLink &&
        props?.bannerFormData?.startDate &&
        props?.bannerFormData?.endDate &&
        props?.bannerImage
      ) {
        props.onSubmit();
      } else {
        showDangerToast("Please fill all required Fields");
      }
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      if (imageFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = function (e) {
          setImages((prevState) => [
            ...prevState,
            { id: index, src: e.target.result },
          ]);
          props?.setBannerImage(file);
          props.setThumbanailControl({
            showError: false,
            errorMessage: "",
            showImage: true,
            imageData: { id: index, src: e.target.result },
          });
        };
        reader.readAsDataURL(file);

        return file;
      } else {
        props.setThumbanailControl({
          showError: true,
          errorMessage: "Only Image file is accept. ",
          showImage: false,
          imageData: [],
        });
      }
    });
  }, []);

  const clickToRemove = () => {
    props?.setBannerImage(null)
  }

  useEffect(() => {
    console.log("propsssss", props);
    props.setThumbanailControl({
      showError: true,
      errorMessage: "",
      showImage: true,
      imageData: { src: props?.bannerFormData?.imageUrl },
    });
    props.setFilterFileControl({
      showError: true,
      errorMessage: "",
      showImage: true,
      imageData: { src: props?.bannerFormData?.imageUrl },
    });
  }, [props?.show === true]);

  console.log("props?.thumbanailControl", props?.thumbanailControl);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.bannerFormData?.id ? "Update Banner" : "Add Banner"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <Form.Control
              value={props.bannerFormData?.name}
              onChange={(e) =>
                props.setBannerFormData({
                  ...props?.bannerFormData,
                  name: e.target.value,
                })
              }
              placeholder="Banner Title"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
            {!props?.bannerFormData?.name ? (
              <label className="custom-error-label">
                Banner Name is required*
              </label>
            ) : (
              <></>
            )}
          </div>
          <div className="col-md-6">
            <Form.Control
              value={props.bannerFormData?.hyperLink}
              onChange={(e) =>
                props.setBannerFormData({
                  ...props?.bannerFormData,
                  hyperLink: e.target.value,
                })
              }
              placeholder="Banner Hyper Link"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
            {!props?.bannerFormData?.hyperLink ? (
              <label className="custom-error-label">
                Hyper Link is required*
              </label>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            Upload Thumbnail
            <DropBox
              onDrop={onDrop}
              acceptType="image/*"
              isImage={true}
              thumbanailControl={props?.thumbanailControl}
              setThumbanailControl={props?.setThumbanailControl}
              clickToRemove={clickToRemove}
            />
            {props.thumbanailControl?.showError ? (
              <label className="custom-error-label" style={{ color: "red" }}>
                {" "}
                {props.thumbanailControl?.errorMessage}
              </label>
            ) : (
              <></>
            )}
            {props?.bannerFormData?.id ? (
              <></>
            ) : props?.bannerImage === null ? (
              <label className="custom-error-label">
                Thumbnail is required*
              </label>
            ) : (
              <></>
            )}
          </div>
          <div className="col-md-6">
            Date Range
            <div className="row col-md-12 mt-2">
              <DatePicker
                selected={props.bannerFormData?.startDate}
                onChange={(date) => {
                  props?.setBannerFormData({
                    ...props?.bannerFormData,
                    startDate: date,
                  });
                  setStartDateChanged(true);
                  setStartDate(date);
                }}
                dateFormat="MM/dd/yyyy"
                minDate={new Date()}
              />
              {!props?.bannerFormData?.startDate ? (
                <label className="custom-error-label">
                  Start Date is required*
                </label>
              ) : (
                <></>
              )}
            </div>
            <div className="row col-md-12 mt-4">
              <DatePicker
                selected={props.bannerFormData?.endDate}
                onChange={(date) => {
                  props?.setBannerFormData({
                    ...props?.bannerFormData,
                    endDate: date,
                  });
                  setEndDate(date);
                }}
                dateFormat="MM/dd/yyyy"
                minDate={startDate}
                disabled={!startDateChanged}
              />
              {!props?.bannerFormData?.endDate ? (
                <label className="custom-error-label">
                  End Date is required*
                </label>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};
