import { Routes, Route, useNavigate } from 'react-router-dom'
import { AuthData } from '../../auth/AuthWrapper'
import { nav } from './navigation'
import { NotFound } from '../pages/NotFound'
import { ForgotPassword } from '../pages/ForgotPassword'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ResetPassword } from '../pages/ResetPassword'
import { Login } from '../pages/Login'
import { LogoutComponent } from '../pages/LogoutComponent'

export const RenderRoutes = () => {
  const { user, login } = AuthData()
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const navigate = useNavigate()
  useEffect(() => {
    let storeData = localStorage.getItem('userData')
    if (storeData && storeData != null && storeData !== undefined) {
      storeData = JSON.parse(storeData)
      login(storeData?.userName, storeData?.password)
    }
    // if ((window.location.pathname === '/login') && storeData) {
    //   navigate('/')
    // }
  }, [])

  useEffect(()=>{
    let storeData = localStorage.getItem('userData')

    console.log('=============>>>>', window.location.pathname === '/login' && storeData, window.location.pathname === '/login' , storeData);
    if ((window.location.pathname === '/login') && storeData) {
      navigate('/')
    }
  },[])
  return (
    <>
      <Routes>
        {nav.map((r, i) => {
          if (r.isPrivate && user.isAuthenticated) {
            return <Route key={i} path={r.path} element={r.element}></Route>
          } else if (!r.isPrivate) {
            return <Route key={i} path={r.path} element={r.element}></Route>
          } else {
            return false
          }
        })}
        {/* <Route path='/*' element={<NotFound />} /> */}
        <Route path='/' element={<Login />} />
        <Route path='/logout' element={<LogoutComponent />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password/:token' element={<ResetPassword />} />
      </Routes>
      {LOADING_ENABLE && (
        <div className='cover' id='coverDiv'>
          <div className='cover-content'>
            <div
              style={{ color: '#785ebb' }}
              className='la-square-loader la-2x'
            >
              <div></div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
