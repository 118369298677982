import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useEffect, useState } from 'react'
export const PayStackMfaOTPModal = props => {
  useEffect(() => {}, [])
  const [otp, setOtp] = useState('')

  return (
    <Modal
      {...props}
      size='sm'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title
          id='contained-modal-title-vcenter'
          style={{ justifyContent: 'center' }}
        >
          OTP Verification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input onChange={e => setOtp(e.target.value)} value={otp} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='outlined'
          onClick={() => {
            if (props?.type) {
              if (props?.type === 'TRANSACTION') {
                props.onVerifyTransaction(otp)
              } else if (props?.type === 'MFA') {
                props.onVerify(otp)
              }
            }
          }}
        >
          Verify
        </Button>
        <Button variant='outlined' onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
