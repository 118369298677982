import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const DescriptionModal = ({ show, handleClose, description }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header >
        <Modal.Title>Description</Modal.Title>
        <Button variant="secondary" onClick={handleClose} style={{ marginLeft: "auto" }}>
            Close
          </Button>
      </Modal.Header>
      <Modal.Body><p style={{ wordWrap: 'break-word' }}>{description}</p></Modal.Body>
    </Modal>
  );
};

export default DescriptionModal;
