import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useState } from 'react'
export const AddRole = props => {
  const [error, setError] = useState({
    title: false,
    description: false
  })

  const handleSubmit = () => {
    if (
      props?.roleFormData.name?.trim() === '' &&
      props?.roleFormData.description?.trim() === ''
    ) {
      setError({ title: true, description: true })
    } else if (props?.roleFormData.name?.trim() === '') {
      setError({ title: true, description: false })
    } else if (props?.roleFormData.description?.trim() === '') {
      setError({ title: false, description: true })
    } else {
      setError({ title: false, description: false })
      props.onSubmit()
    }
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.roleFormData?.id ? 'Update Role' : 'Add Role'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-12'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <h4>Title*</h4>
              <Form.Control
                type='text'
                placeholder='Enter Title'
                value={props.roleFormData.name}
                onChange={e => props.setRoleFormData({ name: e.target.value })}
              />
              {error?.title ? (
                <label className='custom-error-label'>
                  Role Name is required*
                </label>
              ) : (
                <></>
              )}
            </Form.Group>
          </div>
          <div className='col-md-12 mt-2'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <h4>Discription*</h4>
              <Form.Control
                type='text'
                as='textarea'
                placeholder='Discription'
                value={props.roleFormData.description}
                onChange={e =>
                  props.setRoleFormData({ description: e.target.value })
                }
              />
              {error?.description ? (
                <label className='custom-error-label'>
                  Role Description is required*
                </label>
              ) : (
                <></>
              )}
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
