import Swal from 'sweetalert2'
import { useEffect, useReducer, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import AWS from 'aws-sdk'
import NavBar from '../../structure/NavBar'
import Button from '@mui/material/Button'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { NavBarTop } from '../../structure/NavBarTop'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import AddIcon from '@mui/icons-material/Add'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import Switch from '@mui/material/Switch'
import { AddAds } from './AddAds'
import Utils from '../../constants/Utils'
import { MainAdsTable } from './MainAdsTable'
import { SmallAdsTable } from './SmallAdsTable'
import { InfluencerAdsTable } from './InfluencerAdsTable'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const defaultAdsTypeOptions = [
  { value: '', label: 'Select' },
  { value: 'IMAGE', label: 'Image' },
  { value: 'VIDEO', label: 'Video' },
  { value: 'GOOGLE_MOB', label: 'Google Mob' }
]

export const AdsList = props => {
  const [adsContentFile, setAdsContentFile] = useState()
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [kycStatusDropdownText, setKycStatusDropdownText] =
    useState('Select Kyc Status')
  const [adTypeDropdownText, setAdTypeDropdownText] = useState('Select Type')
  const [addNewAds, setAddNewAds] = useState(false)
  const [value, setValue] = useState(dayjs())
  const userData = useSelector(state => state?.userData)
  const [showAddRole, setShowAddRole] = useState(false)
  const [datalist, setDatalist] = useState([])

  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const [viewPermissionControl, setViewPermissionControl] = useState(false)
  const [viewSubAdminList, setViewSubAdminList] = useState(false)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })

  const [tabValue, setTabValue] = useState(0)

  const handleChangeTab = (event, newValue) => {
    setPagerecord({
      totalItems: 0,
      pageNumber: 1,
      pageSize: 10,
      totalPages: 1
    })
    setPage(1)
    setTabValue(newValue)
  }

  const [adsFormData, setAdsFormData] = useState({
    id: '',
    title: '',
    type: '',
    adsContentUrl: '',
    adsType: 'MAIN_ADS',
    viewsNeeded: '',
    urlEnabled: false,
    url: '',
    startDate: new Date(),
    endDate: null
  })
  const [permissionSubmitData, setPermissionSubmitData] = useState([])
  const [rolePermissionData, setRolePermissionData] = useState({})
  const [subAdminListByRole, setSubAdminListByRole] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(updateTopHeader('Ad Management Manager'))
  }, [])

  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
  }

  useEffect(() => {
    getAdsList()
  }, [sort_order, sort_type, page, props, tabValue])

  const handleDeleteAds = data => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async result => {
      if (result.isConfirmed) {
        deleteApiCall(data?.id)
      }
    })
  }

  const deleteApiCall = async id => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.deleteAds + '/' + id
      let res = await ApiCaller.delete({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        Swal.fire('Deleted!', response?.message, 'success')
        getAdsList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  useEffect(() => {
    // This code will execute whenever startDate changes.
    getAdsList(searchText, startDate, endDate, statusDropdownText)
  }, [startDate, endDate, statusDropdownText, adTypeDropdownText])

  const handleFilterClick = () => {
    getAdsList(searchText, startDate, endDate)
  }

  const handleResetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setStatusDropdownText('Select Status')
    setAdTypeDropdownText('Select Type')
    setSearchText('')
    getAdsList('')
  }

  const getAdsList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchTextRef?.trim(),
        pageNumber: searchTextRef ? 0 : pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        active:
          statusDropdownText === 'Active'
            ? true
            : statusDropdownText === 'In Active'
              ? false
              : null,
        adsType:
          tabValue === 0
            ? 'BETWEEN_REELS'
            : tabValue === 1
              ? 'PANEL_ON_THE_REELS'
              : tabValue === 2
                ? 'INFLUENCE_ADS'
                : null,
        contentType:
          adTypeDropdownText === 'Video'
            ? 'VIDEO'
            : adTypeDropdownText === 'Image'
              ? 'IMAGE'
              : adTypeDropdownText === 'Ad Mob'
                ? 'GOOGLE_MOB'
                : null,
        asc: sort_order,
        startDate:
          startDateRef && startDateRef !== null ? startDateRef.toDate() : '',
        endDate: endDateRef && endDateRef !== null ? endDateRef.toDate() : ''
      }
      let path = urls.globalAdsList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)

        // response?.data?.permissionList.forEach((module, moduleIndex) => {
        //   if (module.permissionName === 'EDIT') {
        //     setEdit(module.isEnable)
        //   } else if (module.permissionName === 'ACTIVE') {
        //     setActive(module.isEnable)
        //   } else if (module.permissionName === 'DELETE') {
        //     setDeletePermission(module.isEnable)
        //   } else if (module.permissionName === 'VIEW') {
        //     setView(module.isEnable)
        //   }
        // })
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditAds = item => {
    defaultAdsTypeOptions?.map(data => {
      if (data?.value === item?.type) {
        // setDefaultAdsType({
        //   value: data?.value,
        //   label: data?.label
        // })
      }
    })
    setAdsFormData({
      ...item,
      startDate: new Date(item?.startDate),
      endDate: new Date(item?.endDate)
    })
    setAddNewAds(true)
  }

  const handleOnUploadAdsSuccess = () => {
    setAddNewAds(!addNewAds)
    getAdsList()
  }

  const handleOnSubmitRoleModel = async () => {
    let responseImage
    dispatch(uplodateLoading(true))
    if (adsFormData.id) {
      if (adsContentFile) {
        responseImage = await uploadFile()
      } else {
        responseImage = adsFormData?.adsContentUrl
      }
    } else {
      if (adsContentFile) {
        responseImage = await uploadFile()
      } else {
        showDangerToast('Please upload video/image')
      }
    }
    if (responseImage) {
      const responseImage = await uploadFile()
      if (tabValue === 0) {
        try {
          let postJson = {
            title: adsFormData?.title,
            adsType: 'MAIN_ADS',
            type: adsFormData?.type,
            viewsNeeded: adsFormData?.viewsNeeded,
            urlEnabled: adsFormData?.urlEnabled,
            url: adsFormData?.url,
            adsContentUrl: responseImage,
            startDate: adsFormData?.startDate,
            endDate: adsFormData?.endDate,
            id: adsFormData?.id
          }
          dispatch(uplodateLoading(true))
          let path = urls.addAds
          var res = await ApiCaller.post(postJson, path, userData?.access_token)
          const response = await res.response.json()
          if (response.status == 200) {
            dispatch(uplodateLoading(false))
            showToast(response?.message)
            setAddNewAds(false)
            getAdsList()
          } else {
            dispatch(uplodateLoading(false))
            showDangerToast(response.message)
          }
        } catch (error) {
          dispatch(uplodateLoading(false))
          console.error(error)
          showDangerToast(error.message)
        }
      } else if (tabValue === 1) {
        try {
          let postJson = {
            title: adsFormData?.title,
            adsType: 'SMALL_ADS',
            type: 'IMAGE',
            adsContentUrl: responseImage,
            viewsNeeded: adsFormData?.viewsNeeded,
            urlEnabled: adsFormData?.urlEnabled,
            url: adsFormData?.url,
            startDate: adsFormData?.startDate,
            endDate: adsFormData?.endDate,
            id: adsFormData?.id
          }
          dispatch(uplodateLoading(true))
          let path = urls.addAds
          var res = await ApiCaller.post(postJson, path, userData?.access_token)
          const response = await res.response.json()
          if (response.status == 200) {
            dispatch(uplodateLoading(false))
            showToast(response?.message)
            setAddNewAds(false)
            getAdsList()
          } else {
            dispatch(uplodateLoading(false))
            showDangerToast(response.message)
          }
        } catch (error) {
          dispatch(uplodateLoading(false))
          console.error(error)
          showDangerToast(error.message)
        }
      }
    }
  }

  const uploadFile = async () => {
    // S3 Bucket Name
    const S3_BUCKET = 'praxo-dev-cloud-front'

    // S3 Region
    const REGION = 'ap-south-1'

    // S3 Credentials
    AWS.config.update({
      accessKeyId: 'AKIAQLSIVLYXUCEHSXOK',
      secretAccessKey: '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'
    })
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION
    })
    // Files Parameters
    const fileName = 'ads/' + adsContentFile?.name
    console.log(fileName)
    const params = {
      Bucket: S3_BUCKET,
      Key: fileName,
      Body: adsContentFile
    }

    // Uploading file to s3

    var upload = await s3
      .putObject(params)
      .on('httpUploadProgress', evt => {
        // File uploading progress
        console.log(
          'Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%'
        )
      })
      .promise()
    // await upload.then((err, data) => {
    //   alert("File upload successfully.")
    //   console.log("Image URL: " + "https://dzv0fidwp2q1d.cloudfront.net/" + fileName)
    // });
    return 'https://supa-dev-bucket.s3.af-south-1.amazonaws.com/' + fileName
  }

  const handleAddAds = async () => {
    setAdsFormData({
      id: '',
      title: '',
      type: '',
      viewsNeeded: '',
      urlEnabled: false,
      url: '',
      startDate: new Date(),
      endDate: null
    })
    setAddNewAds(true)
  }

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
        <div className='container-fluid mt--7'>
          <div className='row'>
            <div className='col text-left'>
              <div className='card shadow'>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col'>
                      <h3 className='mb-0'>Ad Management</h3>
                    </div>
                    <div className='col text-right'>
                      {userData.admin && tabValue !== 2 ? (
                        <a
                          onClick={handleAddAds}
                          className='btn btn-sm btn-primary text-dark'
                        >
                          <AddIcon />
                          Add Ads
                        </a>
                      ) : edit && tabValue !== 2 ? (
                        <a
                          onClick={handleAddAds}
                          className='btn btn-sm btn-primary text-dark'
                        >
                          <AddIcon />
                          Add Ads
                        </a>
                      ) : (
                        <></>
                      )}

                      {/* <a href='#!' className='btn btn-sm btn-primary'>
                      See all
                    </a> */}
                    </div>
                  </div>
                </div>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col-md-3'>
                      <InputGroup
                        className='mb-2'
                        style={{ borderRadius: '10px' }}
                      >
                        <Form.Control
                          placeholder='Search by Keyword'
                          value={searchText}
                          onChange={e => setSearchText(e.target.value)}
                          aria-label='Amount (to the nearest dollar)'
                        />
                        <InputGroup.Text
                          style={{
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px',
                            borderTopLeftRadius: '0px',
                            borderBottomLeftRadius: '0px'
                          }}
                        >
                          <SearchIcon />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    {tabValue === 0 ? (
                      <div className='col-md-2 mb-2 '>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              border: '1px solid #cad1d7',
                              boxShadow: 'none',
                              transform: 'none'
                            }}
                            id='dropdown-basic'
                          >
                            {adTypeDropdownText}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                setAdTypeDropdownText('Select Type')
                              }
                            >
                              Select Type
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setAdTypeDropdownText('Video')}
                            >
                              Video
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setAdTypeDropdownText('Image')}
                            >
                              Image
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setAdTypeDropdownText('Ad Mob')}
                            >
                              Ad Mob
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className='col-md-2 mb-2 ml-2'>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='success'
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            border: '1px solid #cad1d7',
                            boxShadow: 'none',
                            transform: 'none'
                          }}
                          id='dropdown-basic'
                        >
                          {statusDropdownText}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              setStatusDropdownText('Select Status')
                            }
                          >
                            Select Status
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setStatusDropdownText('Active')}
                          >
                            Active
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setStatusDropdownText('In Active')}
                          >
                            In Active
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div
                      className='col-md-6 mb-3'
                      style={{ marginLeft: '40px' }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={['DatePicker', 'DatePicker']}
                        >
                          <DatePicker
                            disableFuture
                            className='custom-date'
                            label='From Date'
                            value={startDate}
                            onChange={newValue => setStartDate(newValue)}
                          />
                          <DatePicker
                            disableFuture
                            label='To Date'
                            value={endDate}
                            onChange={newValue => setEndDate(newValue)}
                            minDate={startDate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className='col-md-3 mb-2'>
                      <Button
                        variant='contained'
                        className='custom-filter-btn'
                        onClick={handleFilterClick}
                      >
                        Filter
                      </Button>
                      <Button
                        variant='contained'
                        className='ml-2 custom-filter-btn'
                        onClick={handleResetFilter}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </div>

                <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    indicatorColor='secondary'
                    textColor='inherit'
                    variant='fullWidth'
                    aria-label='full width tabs example'
                  >
                    <Tab label='Main Ads' {...a11yProps(0)} />
                    <Tab label='Small Ads' {...a11yProps(1)} />
                    <Tab label='Influencer' {...a11yProps(2)} />
                  </Tabs>
                </Box>
                {tabValue === 0 || tabValue === 1 ? (
                  <MainAdsTable
                    getAdsList={getAdsList}
                    handleEditAds={handleEditAds}
                    handleDeleteAds={handleDeleteAds}
                    admin={userData.admin}
                    active={active}
                    edit={edit}
                    datalist={datalist}
                    pagerecord={pagerecord}
                  />
                ) : (
                  <></>
                )}
                {/* {tabValue === 1 ? (
                  <SmallAdsTable
                    getAdsList={getAdsList}
                    handleEditAds={handleEditAds}
                    handleDeleteAds={handleDeleteAds}
                    admin={userData.admin}
                    active={active}
                    edit={edit}
                    datalist={datalist}
                    pagerecord={pagerecord}
                  />
                ) : (
                  <></>
                )} */}
                {tabValue === 2 ? (
                  <InfluencerAdsTable
                    getAdsList={getAdsList}
                    admin={userData.admin}
                    active={active}
                    edit={edit}
                    datalist={datalist}
                    pagerecord={pagerecord}
                    page={page}
                    handleChange={handleChange}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <AddAds
          setAdsContentFile={setAdsContentFile}
          show={addNewAds}
          tabValue={tabValue}
          adsFormData={adsFormData}
          setAdsFormData={setAdsFormData}
          onSubmit={() => handleOnSubmitRoleModel()}
          onHide={() => setAddNewAds(false)}
          onUploadAddSuccess={() => handleOnUploadAdsSuccess()}
          // defaultAdsType={defaultAdsType}
          defaultAdsTypeOptions={defaultAdsTypeOptions}
        />
      </div>
    </>
  )
}
