import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import $, { post } from 'jquery'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { saveAs } from 'file-saver' // For downloading files
import JSZip from 'jszip' // Library for working with zip files
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useCallback, useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import DropBox from '../../custom/DropBox'
import AWS from 'aws-sdk'
import ShowImage from '../../custom/ShowImage'
import { showDangerToast, showToast } from '../../constants/toaster'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { useDispatch, useSelector } from 'react-redux'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import { pull } from 'lodash'
const imageFileTypes = ['image/png', 'image/gif', 'image/jpeg']
const otherFileTypes = ['.arscene', '.videofx']

export const AddSticker = props => {
  const [inputValue, setInputValue] = useState('')
  const dispatch = useDispatch()

  const handleInputChangeTag = event => {
    setInputValue(event.target.value)
  }

  const handleInputKeyDown = event => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      props?.setTags([...props?.tags, inputValue.trim()])
      setInputValue('')
    }
  }

  const handleChipDelete = tagToDelete => () => {
    props?.setTags(prevTags => prevTags.filter(tag => tag !== tagToDelete))
  }

  const [value, setValue] = useState(dayjs())
  const [images, setImages] = useState([])
  const [files, setFiles] = useState([])
  const [startDate, setStartDate] = useState(new Date('2023-10-16T06:23:56'))
  const [endDate, setEndDate] = useState()
  const [startDateChanged, setStartDateChanged] = useState(false)
  const userData = useSelector(state => state?.userData)
  const [meisheAssetFile, setMeisheAssetFile] = useState(null)
  const [bannerFile, setBannerFile] = useState(null)
  const [assetInfoJson, setAssetInfoJson] = useState(null)

  const uploadFile = async assetFile => {
     // S3 Bucket Name
     const S3_BUCKET = 'praxo-dev-cloud-front'

     // S3 Region
     const REGION = 'ap-south-1'
 
     // S3 Credentials
     AWS.config.update({
       accessKeyId: 'AKIAQLSIVLYXUCEHSXOK',
       secretAccessKey: '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'
     })
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION
    })
    // Files Parameters
    const fileName = 'meishe-effects/client-account/' + assetFile?.name
    console.log(fileName)
    const params = {
      Bucket: S3_BUCKET,
      Key: fileName,
      Body: assetFile
    }

    // Uploading file to s3

    var upload = await s3
      .putObject(params)
      .on('httpUploadProgress', evt => {
        console.log(
          'Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%'
        )
      })
      .promise()
    return 'https://supa-dev-bucket.s3.af-south-1.amazonaws.com/' + fileName
  }

  const handleOnFileSelect = async event => {
    let validationDone = false
    const file = event.target.files[0]
    if (
      file &&
      (file.type === 'application/zip' ||
        file.type === 'application/x-zip-compressed')
    ) {
      setMeisheAssetFile(file)
      const zip = await JSZip.loadAsync(file)
      zip.forEach(async (relativePath, zipEntry) => {
        if (relativePath === 'info.json') {
          const fileData = await zip.file('info.json').async('text') // Read the content as text
          console.log(fileData)
          let postData = JSON.parse(fileData)
          setAssetInfoJson(postData)
        }
      })
    } else {
      showDangerToast('Please select a zip file.')
    }
  }
  const handleOnFileBannerSelect = async event => {
    const file = event.target.files[0]
    if (file) {
      setBannerFile(file)
    } else {
      showDangerToast('Please select a image file.')
    }
  }

  const callAPI = async postData => {
    // call API HERE
    try {
      let path = urls.addMeisheAssets
      var resBlog = await ApiCaller.post(postData, path, userData?.access_token)
      const responseBlog = await resBlog.response.json()
      if (responseBlog.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(responseBlog?.message)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(responseBlog.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleSubmit = async () => {
    if (meisheAssetFile && bannerFile) {
      const bannerImage = await uploadFile(bannerFile)
      let postData
      try {
        const zip = await JSZip.loadAsync(meisheAssetFile)
        zip.forEach(async (relativePath, zipEntry) => {
          if (relativePath === 'info.json') {
            const fileData = await zip.file('info.json').async('text') // Read the content as text
            console.log(fileData)
            postData = JSON.parse(fileData)
            const responseImage = await uploadFile(meisheAssetFile)
            postData.packageUrl = responseImage
            postData.meisheUuid = postData.uuid
            postData.cover = bannerImage
            console.log(postData)
            callAPI(postData)
          }
        })
      } catch (error) {
        console.error('Error reading zip file:', error)
      }
    } else {
      showDangerToast('Please upload Resource or Banner')
    }
  }

  useEffect(() => {
    props.setThumbanailControl({
      showError: false,
      errorMessage: '',
      showImage: true,
      imageData: { src: props?.bannerFormData?.imageUrl }
    })
    props.setFilterFileControl({
      showError: false,
      errorMessage: '',
      showImage: true,
      imageData: { src: props?.bannerFormData?.imageUrl }
    })
  }, [props?.show === true])

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.bannerFormData?.id ? 'Update Sticker' : 'Add Sticker'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-6'>
            <h4>Display Name</h4>
            <Form.Control
              value={assetInfoJson?.name}
              onChange={e =>
                setAssetInfoJson({
                  ...assetInfoJson,
                  name: e.target.value
                })
              }
              placeholder='Display Name'
              aria-label='Small'
              aria-describedby='inputGroup-sizing-sm'
            />
            {!assetInfoJson?.name ? (
              <label className='custom-error-label'>Name is required*</label>
            ) : (
              <></>
            )}
          </div>
          <div className='col-md-6'>
            <h4>Resource</h4>
            <input
              type='file'
              accept='.zip,.rar,.7zip'
              onChange={handleOnFileSelect}
            />
            {!meisheAssetFile ? (
              <label className='custom-error-label'>
                Resource is required*
              </label>
            ) : (
              <></>
            )}
          </div>
          <div className='col-md-6'>
            <h4>Display Banner</h4>
            <input
              type='file'
              accept='image/png, image/gif, image/jpeg'
              onChange={handleOnFileBannerSelect}
            />
            {!bannerFile ? (
              <label className='custom-error-label'>Banner is required*</label>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
