import Switch from '@mui/material/Switch'
import Swal from 'sweetalert2'
import NoDataImg from '../../../assets/img/no-data.gif'
import Pagination from '@mui/material/Pagination'
import Box from "@mui/material/Box";
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@mui/material/Button'
import dayjs, { Dayjs } from 'dayjs'
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import { AddFontStyle } from './AddFontStyle'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import { ViewFontStyle } from './ViewFontStyle'
import Utils from '../../constants/Utils'
import ShowLongText from '../../custom/ShowLongText';
const label = { inputProps: { 'aria-label': 'Size switch demo' } }

export const FontStyleList = props => {
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [tags, setTags] = useState([])
  const [coverImage, setCoverImage] = useState()
  const userData = useSelector(state => state?.userData)
  const [remotePackageFile, setRemotePackageFile] = useState()
  const [datalist, setDatalist] = useState([])
  const [statusDropdownText, setStatusDropdownText] = useState('Font')
  const [value, setValue] = useState(dayjs())
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [showAddFontStyle, setShowAddFontStyle] = useState(false)
  const [fontStyleFormData, setFontStyleFormData] = useState({
    id: '',
    name: '',
    type: '',
    description: '',
    coverImageUrl: '',
    remotePackageUrl: ''
  })
  const [thumbanailControl, setThumbanailControl] = useState({
    showError: true,
    errorMessage: "",
    showImage: false,
    imageData: null,
  });
  const [filterFileControl, setFilterFileControl] = useState({
    showError: true,
    errorMessage: "",
    showImage: false,
    imageData: null,
  });

  const [showViewFontStyle, setShowViewFontStyle] = useState(false)
  const [fontStyleViewFormData, setFontStyleViewFormData] = useState({
    remotePackage: '',
    image: '',
    name: '',
    active: '',
    widgetName: '',
    createdAt: '',
    description: '',
    duration: '',
    followerCount: '',
    likeCount: '',
    playCount: '',
    tags: '',
    type: '',
    upDatedAt: '',
    viewCount: 0
  })

  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
  }

  const handleFilterClick = () => {
    getFontStyleList(searchText, startDate, endDate)
  }

  const handleResetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setSearchText('')
    setStatusDropdownText('Font')
    getFontStyleList('', null, null, 'FONT')
  }

  const handleOnSubmitFilterModel = async () => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        name: fontStyleFormData?.name,
        description: fontStyleFormData?.description,
        // type: statusDropdownText === 'Font' ? 'FONT' : 'STYLE',
        type: statusDropdownText === 'Font'
          ? 'FONT'
          : statusDropdownText === 'Style'
            ? 'STYLE'
            : 'FONT',
        tags: tags,
        id: fontStyleFormData?.id
      }
      let form_data = new FormData()
      form_data.append(
        'fontAndStyleRequest',
        new Blob([JSON.stringify(postJson)], {
          type: 'application/json'
        })
      )
      form_data.append('image', coverImage)
      form_data.append('remote', remotePackageFile)
      let path = urls.addFontStyle
      var res = await ApiCaller.post_formdata(
        form_data,
        path,
        userData?.access_token
      )

      const response = await res.response.json()
      if (response.status == 200) {
        setShowAddFontStyle(false)
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getFontStyleList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
      setTags([])
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
      setTags([])
    }
  }
  const handleAddFilter = () => {
    setFontStyleFormData({})
    setCoverImage(null)
    setRemotePackageFile(null)
    setShowAddFontStyle(true)
  }
  const handleEditFontStyle = item => {
    setFontStyleFormData(item)
    setTags(item?.tags)
    setShowAddFontStyle(true)
    setCoverImage(item?.image)
    setRemotePackageFile(item?.remotePackageUrl)
  }


  const handleDeleteFontStyle = data => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async result => {
      if (result.isConfirmed) {
        deleteApiCall(data?.id)
      }
    })
  }

  const deleteApiCall = async id => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.deleteFontStyle + '/' + id
      let res = await ApiCaller.delete({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        Swal.fire('Deleted!', response?.message, 'success')
        getFontStyleList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }


  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeFontStyleStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getFontStyleList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleFontStyleView = id => {
    getFontStyleWithId(id)
  }
  const getFontStyleWithId = async id => {
    try {
      dispatch(uplodateLoading(true))

      let path = urls.getFontStyle + '/' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setFontStyleViewFormData(prevState => {
          return {
            ...prevState,
            ...response.data
          }
        })
        setShowViewFontStyle(true)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  useEffect(() => {
    getFontStyleList('', startDate, endDate)
  }, [startDate, endDate, statusDropdownText])

  useEffect(() => {
    getFontStyleList()
  }, [sort_order, sort_type, statusDropdownText, props, page])

  const getFontStyleList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    typeRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchTextRef,
        pageNumber: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        type:
          typeRef !== undefined
            ? typeRef
            : statusDropdownText === 'Font'
              ? 'FONT'
              : statusDropdownText === 'Style'
                ? 'STYLE'
                : 'FONT',
        startDate:
          startDateRef && startDateRef !== null
            ? startDateRef.toDate()
            : '',
        endDate:
          endDateRef && endDateRef !== null ? endDateRef.toDate() : ''
      }
      let path = urls.fontStyleList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.response?.items)
        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === 'EDIT') {
            setEdit(module.isEnable)
          } else if (module.permissionName === 'ACTIVE') {
            setActive(module.isEnable)
          } else if (module.permissionName === 'DELETE') {
            setDeletePermission(module.isEnable)
          } else if (module.permissionName === 'VIEW') {
            setView(module.isEnable)
          }
        })
        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  useEffect(() => {
    dispatch(updateTopHeader('Font-Style List'))
  })

  const handleHideModel = () => {
    setShowAddFontStyle(false)
    setThumbanailControl({
      showError: true,
      errorMessage: "",
      showImage: false,
      imageData: null,
    })
    setFilterFileControl({
      showError: true,
      errorMessage: "",
      showImage: false,
      imageData: null,
    })
    setTags([])
  }

  return (
    <>
      <>
        <NavBar />
        <div className='main-content'>
          <NavBarTop />
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
            </div>
          </div>
          <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Font & Style List</h3>
                      </div>
                      <div className='col text-right'>
                        {userData.admin ? (
                          <a
                            onClick={handleAddFilter}
                            className='btn btn-sm btn-primary text-dark'
                          >
                            <AddIcon />
                            Add {statusDropdownText}
                          </a>
                        ) : edit ? (
                          <a
                            onClick={handleAddFilter}
                            className='btn btn-sm btn-primary text-dark'
                          >
                            <AddIcon />
                            Add {statusDropdownText}
                          </a>
                        ) : (
                          <></>
                        )}

                        {/* <a href='#!' className='btn btn-sm btn-primary'>
                        See all
                      </a> */}
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            placeholder='Search by Asset Name'
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            aria-label='Amount (to the nearest dollar)'
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '10px',
                              borderTopLeftRadius: '0px',
                              borderBottomLeftRadius: '0px'
                            }}
                          >
                            <SearchIcon />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div
                        className='col-md-4 mb-3'
                        style={{ marginLeft: '20px' }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker
                              disableFuture
                              className='custom-date'
                              label='From Date'
                              value={startDate}
                              onChange={newValue => setStartDate(newValue)}
                            />
                            <DatePicker
                              disableFuture
                              label='To Date'
                              value={endDate}
                              onChange={newValue => setEndDate(newValue)}
                              minDate={startDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-1 mb-2 '>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              border: '1px solid #cad1d7',
                              boxShadow: 'none',
                              transform: 'none'
                            }}
                            id='dropdown-basic'
                          >
                            {statusDropdownText}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('Font')}
                            >
                              Font
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('Style')}
                            >
                              Style
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {datalist && datalist.length > 0 ? (
                      <>
                        <table className='table align-items-center table-flush'>
                          <thead className='thead-light'>
                            <tr>
                              <th scope='col'>S.NO</th>
                              <th scope='col'>Asset Name</th>
                              <th scope='col'>Type</th>
                              <th scope='col'>Description</th>
                              <th scope='col'>Tags</th>
                              <th scope='col'>Cover Image</th>
                              <th scope='col'>Created At</th>
                              <th scope='col'>Status</th>
                              <th scope='col' />
                            </tr>
                          </thead>
                          <tbody>
                            {datalist &&
                              datalist?.map((data, i) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{data?.name}</td>
                                      <td>{data?.type}</td>
                                      <td className='custom-td-width'>
                                      <ShowLongText text={data?.description} header='Description' /></td>
                                      <td>
                                        {data?.tags && data?.tags.length ? (
                                          <>{data?.tags.join(', ')}</>
                                        ) : (
                                          <>-</>
                                        )}
                                      </td>
                                      <td>
                                        <Image
                                          image={{
                                            id: 1,
                                            src: data?.image
                                          }}
                                          width='80px'
                                          height='auto'
                                        />
                                      </td>
                                      <td>
                                        {Utils.formatDate(data?.createdAt)}
                                      </td>
                                      <td>
                                        {userData.admin ? (
                                          <Switch
                                            {...label}
                                            checked={data?.active}
                                            onChange={e =>
                                              handleStatusChange(e, data?.id)
                                            }
                                            size='small'
                                          />
                                        ) : active ? (
                                          <Switch
                                            {...label}
                                            checked={data?.active}
                                            onChange={e =>
                                              handleStatusChange(e, data?.id)
                                            }
                                            size='small'
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                      <td className='text-right'>
                                        <div className='dropdown'>
                                          <a
                                            className='btn btn-sm btn-icon-only text-light'
                                            href='#'
                                            role='button'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                          >
                                            <i className='fas fa-ellipsis-v' />
                                          </a>
                                          <div
                                            className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                            x-placement='bottom-end'
                                            style={{
                                              position: 'absolute',
                                              willChange: 'transform',
                                              top: '0px',
                                              left: '0px',
                                              transform:
                                                'translate3d(32px, 32px, 0px)'
                                            }}
                                          >
                                            <a
                                              className='dropdown-item'
                                              onClick={() =>
                                                handleFontStyleView(data.id)
                                              }
                                            >
                                              View
                                            </a>

                                            {userData.admin ? (
                                              <a
                                                className='dropdown-item cursor'
                                                onClick={() =>
                                                  handleDeleteFontStyle(data)
                                                }
                                              >
                                                Delete
                                              </a>
                                            ) : deletePermission ? (
                                              <a
                                                className='dropdown-item cursor'
                                                onClick={() =>
                                                  handleDeleteFontStyle(data)
                                                }
                                              >
                                                Delete
                                              </a>
                                            ) : (
                                              <></>
                                            )}
                                            {userData.admin ? (
                                              <a
                                                className='dropdown-item cursor'
                                                onClick={() =>
                                                  handleEditFontStyle(data)
                                                }
                                              >
                                                Edit
                                              </a>
                                            ) : edit ? (
                                              <a
                                                className='dropdown-item cursor'
                                                onClick={() =>
                                                  handleEditFontStyle(data)
                                                }
                                              >
                                                Edit
                                              </a>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <div className='text-center'>
                          <img width={'25%'} src={NoDataImg}></img>
                          <br />
                          <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  {datalist && datalist?.length > 0 ? (
                    <>
                      <div className='card-footer py-4'>
                      <Box display="flex" justifyContent="right" alignItems='center'>
                        <span>Total record: {pagerecord?.totalItems}</span>
                        <Pagination
                          className='custom-pagination-class'
                          count={pagerecord?.totalPages}
                          page={page}
                          onChange={handleChange}
                          color='secondary'
                        />
                        </Box>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </div>

          <ViewFontStyle
            show={showViewFontStyle}
            fontStyleViewFormData={fontStyleViewFormData}
            onHide={() => setShowViewFontStyle(false)}
          />
          <AddFontStyle
            setTags={setTags}
            tags={tags}
            show={showAddFontStyle}
            fontStyleFormData={fontStyleFormData}
            setFontStyleFormData={setFontStyleFormData}
            onSubmit={() => handleOnSubmitFilterModel()}
            onHide={() => handleHideModel()}
            setRemotePackageFile={setRemotePackageFile}
            setCoverImage={setCoverImage}
            coverImage={coverImage}
            remotePackageFile={remotePackageFile}
            statusDropdownText={statusDropdownText}
            setFilterFileControl={setFilterFileControl}
            filterFileControl={filterFileControl}
            setThumbanailControl={setThumbanailControl}
            thumbanailControl={thumbanailControl}
          />
        </div>
      </>
    </>
  )
}
