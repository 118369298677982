import { useEffect, useReducer, useState } from "react";
import NoDataImg from "../../../assets/img/no-data.gif";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import InputGroup from "react-bootstrap/InputGroup";
import Pagination from "@mui/material/Pagination";
import NavBar from "../../structure/NavBar";
import dayjs, { Dayjs } from "dayjs";
import AppleIcon from '@mui/icons-material/Apple'
import AndroidIcon from '@mui/icons-material/Android'
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Form from "react-bootstrap/Form";
import { NavBarTop } from "../../structure/NavBarTop";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTopHeader,
  uplodateLoading,
} from "../../../redux/features/common/commonSlice";
import { AddPackage, AddRole } from "./AddPackage";
import AddIcon from "@mui/icons-material/Add";
import urls from "../../constants/apiurl";
import ApiCaller from "../../constants/ApiCaller";
import { showDangerToast, showToast } from "../../constants/toaster";
import Switch from "@mui/material/Switch";
import ShowLongText from "../../custom/ShowLongText";
import Utils from "../../constants/Utils";
const label = { inputProps: { "aria-label": "Size switch demo" } };
export const PackagesList = (props) => {
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [view, setView] = useState(false);
  const userData = useSelector((state) => state?.userData);
  const [showAddPackage, setShowAddPackage] = useState(false);
  const [datalist, setDatalist] = useState([]);
  const [viewPermissionControl, setViewPermissionControl] = useState(false);
  const [viewSubAdminList, setViewSubAdminList] = useState(false);
  const [sort_type, setSortType] = useState("createdAt");
  const [sort_order, setSortOrder] = useState(false);
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
  });
  const [packageFormData, setPackageFormData] = useReducer(
    (roleFormData, newItem) => {
      return { ...roleFormData, ...newItem };
    },
    { storeType: "", id: "", productId: "", amount: 0.0, chips: 0.0, description: '' }
  );
  const [permissionSubmitData, setPermissionSubmitData] = useState([]);
  const [rolePermissionData, setRolePermissionData] = useState({});
  const [subAdminListByRole, setSubAdminListByRole] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10,
    });
  };

  const handleFilterClick = () => {
    getPackagesList(searchText, startDate, endDate);
  };
  const handleResetFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchText("");
    getPackagesList("", null, null);
  };


  useEffect(() => {
    dispatch(updateTopHeader("In App Purchase Manager"));
  }, []);
  useEffect(() => {
    getPackagesList();
  }, [sort_order, sort_type, props, page]);

  const getGetRolePermssions = async (id) => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.rolePermissionDetails + "/" + id;
      let res = await ApiCaller.get({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        setRolePermissionData(response?.data);
        setViewPermissionControl(true);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };
  const getSubAdminsByRoleId = async (id) => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.subAdminByRole + "/" + id;
      let res = await ApiCaller.get({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        setSubAdminListByRole(response?.data);
        setViewSubAdminList(true);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.changeRoleStatus + "/" + id;
      let res = await ApiCaller.put({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        showToast(response?.message);
        getPackagesList(searchText, startDate, endDate);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const getPackagesList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true));
      let postJson = {
        searchText: searchTextRef?.trim(),
        pageNumber: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        startDate:
          startDateRef && startDateRef !== null
            ? startDateRef.toDate()
            : "",
        endDate:
          endDateRef && endDateRef !== null ? endDateRef.toDate() : "",
      };
      let path = urls.packagesList;
      let res = await ApiCaller.post(postJson, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        setDatalist(response?.data?.items);
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pageNo,
          pageSize: 10,
        });
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const handleEditPackage = (item) => {
    setPackageFormData(item);
    setShowAddPackage(true);
  };

  const handleAddRole = () => {
    setPackageFormData({
      id: "",
      storeType: "",
      productId: "",
      amount: 0.0
    });
    setShowAddPackage(true);
  };

  const handleOnSubmitPackageModel = async () => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.addPackage;
      let res = await ApiCaller.post(
        packageFormData,
        path,
        userData?.access_token
      );
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        showToast(response?.message);
        setShowAddPackage(false);
        getPackagesList();
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const submitPermissionControl = async () => {
    try {
      dispatch(uplodateLoading(true));
      let postData = {
        permissions: permissionSubmitData,
      };
      let path = urls.rolePermissionUpdate;
      let res = await ApiCaller.post(postData, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        showToast(response?.message);
        setViewPermissionControl(false);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  return (
    <>
      <NavBar />
      <div className="main-content">
        <NavBarTop />
        <div className="header">
          <div className="logo">
            <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8"></div>
          </div>
        </div>
        <div className="container-fluid mt--7">
          <div className="row">
            <div className="col text-left">
              <div className="card shadow">
                <div className="card-header border-0">
                  <div className="row align-items-center text-left">
                    <div className="col">
                      <h3 className="mb-0">IN APP PURCHASE MANAGER                      </h3>
                    </div>
                    <div className="col text-right">
                      {userData.admin ? (
                        <a
                          onClick={handleAddRole}
                          className="btn btn-sm btn-primary text-dark"
                        >
                          <AddIcon />
                          Add New
                        </a>
                      ) : edit ? (
                        <a
                          onClick={handleAddRole}
                          className="btn btn-sm btn-primary text-dark"
                        >
                          <AddIcon />
                          Add New
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-header border-0">
                  <div className="row align-items-center text-left">
                    <div className="col-md-3">
                      <InputGroup
                        className="mb-2"
                        style={{ borderRadius: "10px" }}
                      >
                        <Form.Control
                          placeholder="Search by Title"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          aria-label="Amount (to the nearest dollar)"
                        />
                        <InputGroup.Text
                          style={{
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                          }}
                        >
                          {/* <SearchIcon onClick={handleFilterClick}/> */}
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="col-md-6 mb-3">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker", "DatePicker"]}
                        >
                          <DatePicker
                            disableFuture
                            className="custom-date"
                            label="From Date"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                          />
                          <DatePicker
                            disableFuture
                            label="To Date"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            minDate={startDate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className="col-md-3 mb-2">
                      <Button
                        variant="contained"
                        className="custom-filter-btn"
                        onClick={handleFilterClick}
                      >
                        Filter
                      </Button>
                      <Button
                        variant="contained"
                        className="ml-2 custom-filter-btn"
                        onClick={handleResetFilter}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  {datalist?.length ? (
                    <>
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Product ID</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Chips</th>
                            <th scope="col">Store Type</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Status</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {datalist?.map((data, i) => {
                            return (
                              <tr>
                                <th scope="row">
                                  <div className="media align-items-center">
                                    <div className="media-body">
                                      <span className="mb-0 text-sm">
                                        {data?.productId}
                                      </span>
                                    </div>
                                  </div>
                                </th>
                                <th scope="row">
                                  <div className="media align-items-center">
                                    <div className="media-body">
                                      <span className="mb-0 text-sm">
                                        {data?.amount}
                                      </span>
                                    </div>
                                  </div>
                                </th>
                                <th scope="row">
                                  <div className="media align-items-center">
                                    <div className="media-body">
                                      <span className="mb-0 text-sm">
                                        {data?.chips}
                                      </span>
                                    </div>
                                  </div>
                                </th>
                                <td>
                                  {data?.storeType === 'ios' ? <>
                                    <span className='badge badge-dot mr-4'>
                                      <AppleIcon /> iOS
                                    </span>
                                  </>
                                    : data?.storeType === 'android' ? <>
                                      <span className='badge badge-dot mr-4'>
                                        <AndroidIcon /> Android
                                      </span>
                                    </> : <></>}

                                </td>
                                <td>{Utils.formatDate(data?.createdAt)}</td>
                                <td>
                                  {userData.admin ? (
                                    <Switch
                                      {...label}
                                      checked={data?.active}
                                      onChange={(e) =>
                                        handleStatusChange(e, data?.id)
                                      }
                                      size="small"
                                    />
                                  ) : active ? (
                                    <Switch
                                      {...label}
                                      checked={data?.active}
                                      onChange={(e) =>
                                        handleStatusChange(e, data?.id)
                                      }
                                      size="small"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </td>
                                <td className="text-right">
                                  <div className="dropdown">
                                    <a
                                      className="btn btn-sm btn-icon-only text-light"
                                      href="#"
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-v" />
                                    </a>
                                    <div
                                      className="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                                      x-placement="bottom-end"
                                      style={{
                                        position: "absolute",
                                        willChange: "transform",
                                        top: "0px",
                                        left: "0px",
                                        transform:
                                          "translate3d(32px, 32px, 0px)",
                                      }}
                                    >
                                      {userData.admin ? (
                                        <a
                                          className="dropdown-item cursor"
                                          onClick={() => handleEditPackage(data)}
                                        >
                                          Edit
                                        </a>
                                      ) : edit ? (
                                        <a
                                          className="dropdown-item cursor"
                                          onClick={() => handleEditPackage(data)}
                                        >
                                          Edit
                                        </a>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <>
                      <div className="text-center">
                        <img width={"25%"} src={NoDataImg}></img>
                        <br />
                        <label style={{ fontWeight: 'bold' }}>
                          {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
                        </label>
                      </div>
                    </>
                  )}
                </div>
                {datalist && datalist.length > 0 ? (
                  <div className="card-footer py-4">
                    <Box display="flex" justifyContent="right" alignItems='center'>
                      <span>Total record: {pagerecord?.totalItems}</span>
                      <Pagination
                        className="custom-pagination-class"
                        count={pagerecord?.totalPages}
                        page={page}
                        onChange={handleChange}
                        color="secondary"
                      />
                    </Box>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <AddPackage
          show={showAddPackage}
          packageFormData={packageFormData}
          setPackageFormData={setPackageFormData}
          onSubmit={() => handleOnSubmitPackageModel()}
          onHide={() => setShowAddPackage(false)}
        />
      </div>
    </>
  );
};
