import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import $ from "jquery";
import dayjs, { Dayjs } from "dayjs";
import "react-dropdown/style.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useCallback, useEffect, useState } from "react";
import DropBox from "../../custom/DropBox";
import { showDangerToast } from "../../constants/toaster";
const imageFileTypes = ["image/png", "image/gif", "image/jpeg"];

const editorConfiguration = {
  toolbar: {
    items: [
      'heading', '|',
      'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',
      'blockQuote', 'insertTable', '|',
      'undo', 'redo'
    ]
  },
  // removePlugins: ['Image', 'ImageToolbar', 'ImageUpload', 'ImageCaption', 'ImageStyle', 'ImageResize']
};

export const AddBlog = (props) => {
  useEffect(() => {
    console.log(props.widgetKeyValueData);
  }, [props]);
  const [value, setValue] = useState(dayjs());
  const [images, setImages] = useState([]);
  const [startDateChanged, setStartDateChanged] = useState(false);
  const [files, setFiles] = useState([]);

  const [filterFileControl, setFilterFileControl] = useState({
    showError: false,
    errorMessage: "",
    showImage: false,
    imageData: null,
  });

  const handleSubmit = () => {
    if (props?.blogFormData?.id) {
      if (props?.blogFormData?.name && props?.blogFormData?.description) {
        props.onSubmit();
      } else {
        showDangerToast("Please fill all required Fields");
      }
    } else {
      if (
        props?.blogFormData?.name &&
        props?.blogFormData?.description &&
        props?.thumbnailFile
      ) {
        props.onSubmit();
      } else {
        showDangerToast("Please fill all required Fields");
      }
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      if (imageFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = function (e) {
          setImages((prevState) => [
            ...prevState,
            { id: index, src: e.target.result },
          ]);
          props?.setThumbnailFile(file);
          props.setThumbanailControl({
            showError: false,
            errorMessage: "",
            showImage: true,
            imageData: { id: index, src: e.target.result },
          });
        };
        reader.readAsDataURL(file);

        return file;
      } else {
        props.setThumbanailControl({
          showError: true,
          errorMessage: "Only Image file is accept. ",
          showImage: false,
          imageData: [],
        });
      }
    });
  }, []);

  const handleCloseBlogModel = () => {
    props.onHide();
    props.setThumbanailControl({
      showError: false,
      errorMessage: "",
      showImage: true,
      imageData: null,
    });
  };

  useEffect(() => {
    if (props?.blogFormData?.id === "") {
      props.setThumbanailControl({
        showError: false,
        errorMessage: "",
        showImage: false,
        imageData: null,
      });
    } else {
      if (props?.thumbnailFile) {
        props.setThumbanailControl({
          showError: false,
          errorMessage: "",
          showImage: true,
          imageData: { src: props?.blogFormData?.imageUrl },
        });
      }
    }
  }, [props?.show === true]);

  const clickToRemove = () => {
    props?.setThumbnailFile(null)
  }

  return (
    <Modal
      enforceFocus={false}
      {...props}
      dialogClassName="fixed-height-modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.blogFormData?.id ? "Update Blog" : "Add Blog"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <label>Blog Name</label>
            <Form.Control
              value={props.blogFormData?.name}
              onChange={(e) =>
                props.setBlogFormData({
                  ...props?.blogFormData,
                  name: e.target.value,
                })
              }
              placeholder="Blog Name"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
            {props?.blogFormData?.name ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">Name is required*</label>
              </>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <label>Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={props.blogFormData?.description}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                props.setBlogFormData({
                  ...props?.blogFormData,
                  description: data,
                });
                console.log({ event, editor, data });
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
              config={editorConfiguration}
            />
            {props?.blogFormData?.description ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                  Description is required*
                </label>
              </>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            Upload Thumbnail
            <DropBox
              onDrop={onDrop}
              // acceptType='image/*'
              isImage={true}
              thumbanailControl={props.thumbanailControl}
              setThumbanailControl={props.setThumbanailControl}
              setThumbnailFile={props?.setThumbnailFile}
              clickToRemove={clickToRemove}
            />
            {props?.thumbanailControl?.showError ? (
              <label className="custom-error-label" style={{ color: "red" }}>
                {props.thumbanailControl?.errorMessage}
              </label>
            ) : (
              <></>
            )}
            {props?.blogFormData?.id ? (
              <></>
            ) : props?.thumbnailFile === null ? (
              <label className="custom-error-label">
                Thumbnail is required*
              </label>
            ) : (
              <></>
            )}
          </div>
          <div className="col-md-6">
            Date Duration
            <div className="row col-md-12 mt-2">
              <DatePicker
                selected={props.blogFormData?.startDate}
                onChange={(date) => {
                  props?.setBlogFormData({
                    ...props?.blogFormData,
                    startDate: date,
                  });
                  setStartDateChanged(true);
                }}
                dateFormat="MM/dd/yyyy"
                minDate={new Date()}
              />
            </div>
            <div className="row col-md-12 mt-4">
              <DatePicker
                selected={
                  props.blogFormData?.endDate
                    ? props.blogFormData?.endDate
                    : null
                }
                onChange={(date) => {
                  props?.setBlogFormData({
                    ...props?.blogFormData,
                    endDate: date,
                  });
                }}
                dateFormat="MM/dd/yyyy"
                minDate={props.blogFormData?.startDate}
                disabled={!startDateChanged}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={() => handleCloseBlogModel()}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};
