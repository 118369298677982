import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useEffect, useState } from 'react'
export const PayStackAvailableBalanceModal = props => {
  useEffect(() => {
    console.log(props?.datalist)
  }, [])
  const [otp, setOtp] = useState('')

  return (
    <Modal
      {...props}
      size='sm'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title
          id='contained-modal-title-vcenter'
          style={{ justifyContent: 'center' }}
        >
          Available Balance List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className='table align-items-center table-flush'>
          <thead className='thead-light'>
            <tr>
              <th scope='col'>S/No.</th>
              <th scope='col'>Currency</th>
              <th scope='col'>Balance</th>
            </tr>
          </thead>
          <tbody>
            {props?.datalist &&
              props?.datalist?.map((data, i) => {
                return (
                  <>
                    <tr>
                      <td>{i + 1}</td>
                      <td>{data?.currency}</td>
                      <td>{data?.balance}</td>
                    </tr>
                  </>
                )
              })}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
