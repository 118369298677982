import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import Form from 'react-bootstrap/Form'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import ReplyIcon from '@mui/icons-material/Reply'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { useCallback, useState } from 'react'

import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from './loadOptions'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { useSelector } from 'react-redux'
import { showDangerToast, showToast } from '../../constants/toaster'
import { TextAreaCustom } from '../../custom/TextAreaCustom'



const options = [
  { value: '', label: 'Select User' },
  { value: '1', label: 'Ganesh' }
]
const defaultOption = options[0]

export const AddNotification = props => {
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  });
  const [notificationTitle, setNotificationTitle] = useState('')
  const [notificationBody, setNotificationBody] = useState('')
  const userData = useSelector((state) => state?.userData);
  const [value, setValue] = useState('all')
  const [valueDropdown, setValueDropdown] = useState(null);
  const [showUserDropdown, setShowUserDropdown] = useState(false)
  const handleChange = event => {
    setValue(event.target.value)
    if (event.target.value === 'toUser') {
      setShowUserDropdown(true)
    } else {
      setShowUserDropdown(false)
    }
  }

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined);
      }, ms);
    });

  const wrappedLoadOptions = useCallback(function (...args) {
    return loadOptionsTest(...args);
  }, []);



  const sendPushNotification = async () => {
    try {
      let postJson = {
        all: !showUserDropdown,
        title: notificationTitle?.trim(),
        body: "Testinggggggg",
        // body: notificationBody?.trim(),
        users: [valueDropdown?.value],
      }
      let path = urls.sendBatchPushNotification
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        showToast(response?.message)
        props?.setAddNewNotification(false)
      } else {
        showDangerToast(response.message)
      }
    } catch (error) {
      console.error(error)
      showDangerToast(error.message)
    }
  }



  const loadOptionsTest = async (
    search,
    prevOptions
  ) => {
    console.log("1")
    await sleep(1000);
    try {
      let postJson = {
        searchText: search?.trim(),
        pageNumber: search?.trim() ? 0 : pagerecord.pageNumber - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: "createdAt",
        asc: false,
      }
      let path = urls.userSearchDropdownList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        console.log(response?.data?.items)
        let tempArray = []
        response?.data?.items.forEach(element => {
          tempArray.push({
            value: element?.key,
            label: element?.value
          })
        });
        setPagerecord({
          ...pagerecord,
          pageNumber: pagerecord?.pageNumber + 1
        })
        const hasMore = response?.data?.totalPages > 0 && response?.data?.totalPages > pagerecord?.pageNumber;
        return {
          options: tempArray,
          hasMore
        };

      } else {
        showDangerToast(response.message)
      }
    } catch (error) {
      console.error(error)
      showDangerToast(error.message)
    }

  };




  return (
    <>
      <div className='container-fluid mt--7'>
        <div className='row'>
          <div className='col text-left'>
            <div className='card shadow'>
              <div className='card-header border-0'>
                <div className='row align-items-center '>
                  <div className='col text-left'>
                    <a
                      onClick={() => props.setAddNewNotification(false)}
                      className='btn btn-sm btn-primary text-dark'
                    >
                      <ReplyIcon />
                      Cancel
                    </a>
                  </div>
                  <div className='col text-center'>
                    <h3 className='mb-0'>Add Notification</h3>
                  </div>
                  <div className='col text-right'>
                    <a
                      onClick={() => sendPushNotification()}
                      // onClick={props.sendNotification}
                      className='btn btn-sm btn-primary text-dark'
                    >
                      Send
                    </a>
                  </div>
                </div>
              </div>

              <div className='card-header border-0'>
                <div className='row align-items-center justify-content-center '>
                  <div className='col-md-6'>
                    <Form>
                      <Form.Group
                        className='mb-3'
                        controlId='exampleForm.ControlInput1'
                      >
                        <Form.Label>Notification Title</Form.Label>
                        <Form.Control type='text' placeholder='Title' value={notificationTitle} onChange={(e) => setNotificationTitle(e.target.value)} />
                      </Form.Group>
                    </Form>
                  </div>
                </div>
                <div className='row align-items-center justify-content-center '>
                  <div className='col-md-6'>
                    <FormControl>
                      <FormLabel id='demo-row-radio-buttons-group-label'>
                        Users
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='row-radio-buttons-group'
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value='all'
                          control={<Radio />}
                          label='All'
                        />
                        <FormControlLabel
                          value='toUser'
                          control={<Radio />}
                          label='Any Particular User'
                        />
                        {/* <FormControlLabel
                          value='allSubAdmins'
                          control={<Radio />}
                          label='All Sub Admins'
                        /> */}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>

                {showUserDropdown ? (
                  <>
                    <div className='row align-items-center justify-content-center '>
                      <div className='col-md-6'>
                        <AsyncPaginate
                          debounceTimeout={300}
                          value={valueDropdown}
                          // loadOptions={loadOptions}
                          loadOptions={wrappedLoadOptions}
                          onChange={(e) => setValueDropdown(e)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className='row align-items-center justify-content-center mt-3'>
                  <div className='col-md-6'>
                    <TextAreaCustom value={notificationBody} onChange={(e) => setNotificationBody(e.target.value)} />
                    {/* <CKEditor
                      editor={ClassicEditor}
                      data='<p>Hello from CKEditor&nbsp;5!</p>'
                      onReady={editor => {
                        console.log('Editor is ready to use!', editor)
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        console.log({ event, editor, data })
                      }}
                      onBlur={(event, editor) => {
                        console.log('Blur.', editor)
                      }}
                      onFocus={(event, editor) => {
                        console.log('Focus.', editor)
                      }}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
