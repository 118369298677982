import Button from 'react-bootstrap/Button'
import TextField from '@mui/material/TextField'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Chip from '@mui/material/Chip'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useState } from 'react'
export const EditStaticContent = props => {

  const [inputValue, setInputValue] = useState('')

  const handleInputChangeTag = event => {
    setInputValue(event.target.value)
  }

  const handleInputKeyDown = event => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      props?.setTags([...props?.tags, inputValue.trim()])
      setInputValue('')
    }
  }

  const handleChipDelete = tagToDelete => () => {
    props?.setTags(prevTags => prevTags.filter(tag => tag !== tagToDelete))
  }

  return (
    <Modal
    enforceFocus={false}
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          Update Static Content
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-6'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Static Page Title*</Form.Label>
              <Form.Control
                type='text'
                placeholder=''
                value={props.staticContentFormData.title}
                onChange={e =>
                  props.setStaticContentFormData({
                    ...props?.staticContentFormData,
                    title: e.target.value
                  })
                }
              />
            </Form.Group>
          </div>
          <div className='col-md-6'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Meta Title*</Form.Label>
              <Form.Control
                type='text'
                placeholder=''
                value={props.staticContentFormData.metaTitle}
                onChange={e =>
                  props.setStaticContentFormData({
                    ...props?.staticContentFormData,
                    metaTitle: e.target.value
                  })
                }
              />
            </Form.Group>
          </div>
          <div className='col-md-12'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Meta Keywords*</Form.Label>
              <TextField
              className='custom-tag-input'
              label='Tags'
              variant='outlined'
              value={inputValue}
              onChange={handleInputChangeTag}
              onKeyDown={handleInputKeyDown}
            />
            <div className='custom-tag-chip-overflow'>
              {props?.tags.map(tag => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={handleChipDelete(tag)}
                  style={{ margin: '4px' }}
                />
              ))}
            </div>
            </Form.Group>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Static Page Description*</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={props.staticContentFormData?.description}
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor)
                }}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  props.setStaticContentFormData({
                    ...props?.staticContentFormData,
                    description: data
                  })
                  console.log({ event, editor, data })
                }}
                onBlur={(event, editor) => {
                  console.log('Blur.', editor)
                }}
                onFocus={(event, editor) => {
                  console.log('Focus.', editor)
                }}
              />
            </Form.Group>
          </div>
          <div className='col-md-6'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Meta Description*</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={props.staticContentFormData?.metaDescription}
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor)
                }}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  props.setStaticContentFormData({
                    ...props?.staticContentFormData,
                    metaDescription: data
                  })
                  console.log({ event, editor, data })
                }}
                onBlur={(event, editor) => {
                  console.log('Blur.', editor)
                }}
                onFocus={(event, editor) => {
                  console.log('Focus.', editor)
                }}
              />
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={props.onSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
