import { useState } from 'react'

import Dropdown from 'react-bootstrap/Dropdown'


const transactionType = [
  {
    value: 'All',
    key: 'ALL'
  }, {
    value: 'Withdraw Commission',
    key: 'WITHDRAW_COMMISSION'
  }, {
    value: 'Chips Purchase',
    key: 'CHIPS_PURCHASE'
  }
]


export const AmountTransactionFilter = props => {
  const [typeDropdownText, setTypeDropdownText] = useState({
    value: 'All',
    key: 'ALL'
  })

  return (
    <>
      <div className='col-md-1 mb-2 '>
        <Dropdown>
          <Dropdown.Toggle
            variant='success'
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #cad1d7',
              boxShadow: 'none',
              transform: 'none'
            }}
            id='dropdown-basic'
          >
            {typeDropdownText?.value}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {transactionType?.map((item, i) => {
              return <><Dropdown.Item eventKey={item?.key}
                onClick={() => {
                  setTypeDropdownText(item)
                  props?.setTransactionType(item)
                }
                }
              >
                {item?.value}
              </Dropdown.Item></>
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  )
}
