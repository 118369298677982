import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { showDangerToast } from '../../constants/toaster'
export const AddLanguage = props => {
  const handleSubmit = () => {
    if (props?.languageFormData?.name && props?.languageFormData?.code) {
      props.onSubmit()
    } else {
      showDangerToast('Please fill all required Fields')
    }
  }

  const handleInputChange = (fieldName, e) => {
    let value = e.target.value.replace(/\s{2,}/g, ' ')
    props.setLanguageFormData({
      ...props?.languageFormData,
      [fieldName]: value
    })
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.languageFormData?.id ? 'Update Language' : 'Add Language'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-6'>
            <Form.Control
              value={props.languageFormData.name}
              onChange={e => handleInputChange('name', e)}
              placeholder='Enter Name'
              aria-label='Small'
              aria-describedby='inputGroup-sizing-sm'
            />
            {props?.languageFormData?.name ? (
              <></>
            ) : (
              <>
                <label className='custom-error-label'>Name is required*</label>
              </>
            )}
          </div>
          <div className='col-md-6'>

            <Form.Control
              value={props.languageFormData.code}
              onChange={e => handleInputChange('code', e)}
              placeholder='Enter Code'
              aria-label='Small'
              aria-describedby='inputGroup-sizing-sm'
            />
            {props?.languageFormData?.code ? (
              <></>
            ) : (
              <>
                <label className='custom-error-label'>Code is required*</label>
              </>
            )}
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
