import "react-dropdown/style.css";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
export const ViewSound = (props) => {
  console.log(props);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header> 
        <Modal.Title id="contained-modal-title-vcenter">
          Sound Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mt-1">
          <div className="col-md-6">
            Image :
            <img
              src={props.effectViewFormData.imageUrl}
              alt="Thumbnail"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          <div className="col-md-6">
            Sound :
            <a
              href={props.effectViewFormData.soundUrl}
              target="_blank"
              rel="noreferrer"
            >
              Listen Sound
            </a>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-6">
            <p>name : {props.effectViewFormData.name}</p>
          </div>
          <div className="col-md-6">
            <p>Active : {props.effectViewFormData.active ? "Yes" : "No"}</p>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-6">
            <p> Display Name : {props.effectViewFormData.displayName}</p>
          </div>
          <div className="col-md-6">
            <p>keyWords : {props.effectViewFormData.tags?.join(", ")} </p>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-6">
            <p> PlayList Name : {props.effectViewFormData.playListName}</p>
          </div>
          <div className="col-md-6">
            <p>Duration : {props.effectViewFormData.duration}</p>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-6">
            <p>Created At : {props.effectViewFormData.createdAt}</p>
          </div>
          <div className="col-md-6">
            <p>Updated At : {props.effectViewFormData.upDatedAt}</p>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-6">
            <p>Used Count : {props.effectViewFormData.noOfTimeUsed}</p>
          </div>
          <div className="col-md-6">
            <p>Artist Name : {props.effectViewFormData.artistName}</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-md-6">
            <p>Description : {props.effectViewFormData.description}</p>
          </div>
          <div className="col-md-6">
            <p>Artist Name : {props.effectViewFormData.artistName}</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
