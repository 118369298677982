import $ from "jquery";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useCallback, useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DropBox from "../../custom/DropBox";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import ShowImage from "../../custom/ShowImage";
import { showDangerToast } from "../../constants/toaster";
const imageFileTypes = ["image/png", "image/gif", "image/jpeg"];
// const otherFileTypes = ['image/png', 'image/gif', 'image/jpeg']
const otherFileTypes = ["audio/mpeg"];

export const AddSound = (props) => {
  const handleInputChangeTag = (event) => {
    props.setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" && props.inputValue.trim() !== "") {
      props?.setTags([...props?.tags, props.inputValue.trim()]);
      props.setInputValue("");
    }
  };

  const clickToRemoveImage = () => {
    props?.setThumbnailFile(null)
  }

  const clickToRemoveSoundFile = () => {
    props?.setSoundFile(null)
  }

  const handleChipDelete = (tagToDelete) => () => {
    props?.setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  useEffect(() => {}, []);
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      if (imageFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = function (e) {
          setImages((prevState) => [
            ...prevState,
            { id: index, src: e.target.result },
          ]);
          props?.setThumbnailFile(file);
          props.setThumbanailControl({
            showError: false,
            errorMessage: "",
            showImage: true,
            imageData: { id: index, src: e.target.result },
          });
        };
        reader.readAsDataURL(file);

        return file;
      } else {
        props.setThumbanailControl({
          showError: true,
          errorMessage: "Only Image file is accept. ",
          showImage: false,
          imageData: [],
        });
      }
    });
  }, []);

  useEffect(() => {
    console.log("props======>>>>", props);
    if (props?.soundFormData?.id === "") {
      props.setThumbanailControl({
        showError: false,
        errorMessage: "",
        showImage: false,
        imageData: null,
      });
      props.setFilterFileControl({
        showError: false,
        errorMessage: "",
        showImage: false,
        imageData: null,
      });
    } else {
      if (props?.thumbnailFile) {
        props.setThumbanailControl({
          showError: false,
          errorMessage: "",
          showImage: true,
          imageData: { src: props?.soundFormData?.imageUrl },
        });
      }
      if (props?.soundFile) {
        props.setFilterFileControl({
          showError: false,
          errorMessage: "",
          showImage: true,
          imageData: { src: props?.soundFormData?.soundUrl },
        });
      }
    }
  }, [props?.show === true]);

  console.log("props?.soundFormData?.imageUrl", props?.soundFormData?.imageUrl);

  const onDropFile = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      console.log('file.type', file.type);
      if (otherFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = function (e) {
          setFiles((prevState) => [
            ...prevState,
            { id: index, src: e.target.result },
          ]);
          props?.setSoundFile(file);
          props.setFilterFileControl({
            showError: false,
            errorMessage: "",
            showImage: true,
            imageData: { id: index, src: e.target.result },
          });
          // console.log('e.target.result', e.target.result);
        };
        reader.readAsDataURL(file);
        return file;
      } else {
        props.setFilterFileControl({
          showError: true,
          errorMessage: "Only .mp4, .mp3 file is accept.",
          showImage: false,
          imageData: {},
        });
      }
    });
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, "");
    props.setSoundFormData({
      ...props?.soundFormData,
      duration: newValue,
    });
  };

  const handleSubmit = () => {
    if (props?.soundFormData?.id) {
      if (
        props?.soundFormData?.name &&
        props?.soundFormData?.displayName &&
        props?.soundFormData?.playListName &&
        props?.soundFormData?.duration &&
        props?.soundFormData?.artistName &&
        props?.soundFormData?.widgetId &&
        props?.soundFormData?.description
      ) {
        props.onSubmit();
      } else {
        showDangerToast("Please fill all required Fields");
      }
    } else {
      if (
        props?.soundFormData?.name &&
        props?.soundFormData?.displayName &&
        props?.soundFormData?.playListName &&
        props?.soundFormData?.duration &&
        props?.soundFormData?.artistName &&
        props?.soundFormData?.widgetId &&
        props?.soundFormData?.description &&
        props?.thumbnailFile &&
        props?.soundFile
      ) {
        props.onSubmit();
      } else {
        showDangerToast("Please fill all required Fields");
      }
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.soundFormData?.id ? "Update Sound" : "Add Sound"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <Form.Control
              value={props.soundFormData?.name}
              onChange={(e) =>
                props.setSoundFormData({
                  ...props?.soundFormData,
                  name: e.target.value,
                })
              }
              placeholder="Sound Name"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
            {props?.soundFormData?.name ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                  Sound Name is required*
                </label>
              </>
            )}
          </div>
          <div className="col-md-6">
            <Form.Control
              value={props.soundFormData?.displayName}
              onChange={(e) =>
                props.setSoundFormData({
                  ...props?.soundFormData,
                  displayName: e.target.value,
                })
              }
              placeholder="Display Name"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
            {props?.soundFormData?.displayName ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                  Display Name is required*
                </label>
              </>
            )}
          </div>
          <div className="col-md-6 mt-3">
            <Form.Control
              value={props.soundFormData?.playListName}
              onChange={(e) =>
                props.setSoundFormData({
                  ...props?.soundFormData,
                  playListName: e.target.value,
                })
              }
              placeholder="Playlist Name"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
            {props?.soundFormData?.playListName ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                  Playlist Name is required*
                </label>
              </>
            )}
          </div>
          <div className="col-md-6 mt-3">
            <Form.Control
              maxLength={4}
              value={props.soundFormData?.duration}
              onChange={handleInputChange}
              placeholder="Duration"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
            {props?.soundFormData?.duration ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                  Duration is required*
                </label>
              </>
            )}
          </div>
          <div className="col-md-6 mt-3">
            <Form.Control
              value={props.soundFormData?.artistName}
              onChange={(e) =>
                props.setSoundFormData({
                  ...props?.soundFormData,
                  artistName: e.target.value,
                })
              }
              placeholder="Artist Name"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
            {props?.soundFormData?.artistName ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                  Artist Name is required*
                </label>
              </>
            )}
          </div>
          <div className="col-md-6 mt-3">
            <Dropdown
              options={props?.widgetKeyValueData}
              onChange={(e) =>
                props.setSoundFormData({
                  ...props.soundFormData,
                  widgetId: e.value,
                })
              }
              value={props?.defaultWidgetOption}
              placeholder="Select Widget"
            />
            {props?.soundFormData?.widgetId ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                  Widget is required*
                </label>
              </>
            )}
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-12">
            <Form.Control
              value={props.soundFormData?.description}
              onChange={(e) =>
                props.setSoundFormData({
                  ...props.soundFormData,
                  description: e.target.value,
                })
              }
              as="textarea"
              className="mt-3"
              placeholder="Description"
            />
            {props?.soundFormData?.description ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                  Description is required*
                </label>
              </>
            )}
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-12">
            <TextField
              className="custom-tag-input"
              label="Tags"
              variant="outlined"
              value={props.inputValue}
              onChange={handleInputChangeTag}
              onKeyDown={handleInputKeyDown}
            />
            <div className="custom-tag-chip-overflow">
              {props?.tags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={handleChipDelete(tag)}
                  style={{ margin: "4px" }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            Upload Image for Song*
            <DropBox
              onDrop={onDrop}
              acceptType="image/*"
              isImage={true}
              thumbanailControl={props.thumbanailControl}
              setThumbanailControl={props.setThumbanailControl}
              clickToRemove={clickToRemoveImage}
            />
          </div>
          <div className="col-md-6">
            Upload Sound*
            <DropBox
              onDrop={onDropFile}
              acceptType="audio/*"
              isImage={false}
              filterFileControl={props.filterFileControl}
              setFilterFileControl={props.setFilterFileControl}
              clickToRemove={clickToRemoveSoundFile}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};
