import { AdsList } from '../pages/AdsManagement/AdsList'
import { BannerList } from '../pages/Banner/BannerList'
import { BeadsList } from '../pages/BeadsManager/BeadsList'
import { BlogList } from '../pages/Blog/BlogList'
import { CountryList } from '../pages/Country/CountryList'
import { Dashboard } from '../pages/Dashboard'
import { EffectsList } from '../pages/Effects/EffectsList'
import { EmailList } from '../pages/Email/EmailList'
import { PackagesList } from '../pages/InAppPurchase/PackagesList'
import { FaqList } from '../pages/FAQ/FaqList'
import { FilterList } from '../pages/Filter/FilterList'
import { FontStyleList } from '../pages/FontStyle/FontStyleList'
import { GeoLocationList } from '../pages/GeoLocation/GeoLocationList'
import { GlobalSettingList } from '../pages/GlobalSetting/GlobalSettingList'
import { Login } from '../pages/Login'
import { NotificationList } from '../pages/Notification/NotificationList'
import { EditProfile } from '../pages/Profile/EditProfile'
import { RateList } from '../pages/Rate/RateList'
import { ReportAbuseList } from '../pages/ReportAbuse/ReportAbuseList'
import { RoleList } from '../pages/Role/RoleList'
import { SoundList } from '../pages/Sound/SoundList'
import { StateList } from '../pages/StateManager/StateList'
import { StaticList } from '../pages/Static/StaticList'
import { StickerList } from '../pages/Sticker/StickerList'
import { StreamingList } from '../pages/Streaming/StreamingList'
import { SubAdminList } from '../pages/SubAdmin/SubAdminList'
import { TagsList } from '../pages/Tags/TagsList'
import { TransactionList } from '../pages/Transaction/TransactionList'
import { UserDetails } from '../pages/User/UserDetails'
import { UserList } from '../pages/User/UserList'
import { VideoList } from '../pages/Video/VideoList'
import { WidgetList } from '../pages/Widget/WidgetList'
import { WithdrawList } from '../pages/Withdraw/WithdrawList'
import { CityList } from '../pages/CityManager/CityList'
import { DistrictList } from '../pages/DistrictManager/DistrictList'
import { LanguageList } from '../pages/Language/LanguageList'
import { InfluencerList } from '../pages/Influencer/InfluencerList'

export const nav = [
  {
    path: '/',
    name: 'Dashboard',
    element: <Dashboard />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/edit-profile',
    name: 'Edit Profile',
    element: <EditProfile />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/role-manager',
    name: 'Role Manager',
    element: <RoleList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/user-manager',
    name: 'User Manager',
    element: <UserList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/user-manager/:id',
    name: 'User Details Manager',
    element: <UserDetails />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/sub-admin-manager',
    name: 'Sub Admin Manager',
    element: <SubAdminList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/filter-manager',
    name: 'Filter Manager',
    element: <FilterList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/font-style-manager',
    name: 'Font & Style Manager',
    element: <FontStyleList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/effect-manager',
    name: 'Effect Manager',
    element: <EffectsList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/sound-manager',
    name: 'Sound Manager',
    element: <SoundList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/widget-manager',
    name: 'Widgets Manager',
    element: <WidgetList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/report-abuse-manager',
    name: 'Report Abuse Manager',
    element: <ReportAbuseList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/beads-purchase-manager',
    name: 'Chips Purchase Manager',
    element: <BeadsList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/video-manager',
    name: 'Video Manager',
    element: <VideoList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/streaming-manager',
    name: 'Video Manager',
    element: <StreamingList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/transaction-manager',
    name: 'Transaction Manager',
    element: <TransactionList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/app_packages-manager',
    name: 'In App Packages Manager',
    element: <PackagesList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/notification-manager',
    name: 'Notification Manager',
    element: <NotificationList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/country-manager',
    name: 'Country Manager',
    element: <CountryList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/state-manager',
    name: 'State Manager',
    element: <StateList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/city-manager',
    name: 'City Manager',
    element: <CityList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/district-manager',
    name: 'District Manager',
    element: <DistrictList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/email-manager',
    name: 'Email Manager',
    element: <EmailList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/global-setting-manager',
    name: 'Global Setting Manager',
    element: <GlobalSettingList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/static-content-manager',
    name: 'Static Content Manager',
    element: <StaticList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/geo-location-manager',
    name: 'Geo Location Manager',
    element: <GeoLocationList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/ads-manager',
    name: 'Ads Manager',
    element: <AdsList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/blog-manager',
    name: 'Blog Manager',
    element: <BlogList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/tag-manager',
    name: 'Tags Manager',
    element: <TagsList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/language-manager',
    name: 'Language Manager',
    element: <LanguageList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/influencer-manager',
    name: 'Influencer Manager',
    element: <InfluencerList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/faq-manager',
    name: 'FAQ Manager',
    element: <FaqList />,
    isMenu: true,
    isPrivate: true
  },
  // {
  //   path: '/rating-manager',
  //   name: 'Rate Application Manager',
  //   element: <RateList />,
  //   isMenu: true,
  //   isPrivate: true
  // },
  {
    path: '/withdraw-manager',
    name: 'Withdraw Manager',
    element: <WithdrawList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/banner-manager',
    name: 'Banner Manager',
    element: <BannerList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/meishe-assets-manager',
    name: 'Meishe Assets',
    element: <StickerList />,
    isMenu: true,
    isPrivate: true
  },
  {
    path: '/login',
    name: 'Login',
    element: <Login />,
    isMenu: true,
    isPrivate: false
  }
]
