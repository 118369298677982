import { StateList } from "../pages/StateManager/StateList"

var urls = {
  dashboard: '/api/admin-service/dashboard',

  forgotPassword: '/api/admin-service/forget/password',

  resetAdminPassword: '/api/admin-service/reset/password',
  adminLogin: '/api/admin-service/auth/login',
  profileDetail: '/api/admin-service/auth/get-profile-details',
  updateProfile: '/api/admin-service/update-profile',
  updatePassword: '/api/admin-service/update-password',
  addRole: '/api/admin-service/role/add-role',
  roleList: '/api/admin-service/role/role-list',
  changeRoleStatus: '/api/admin-service/role/change/status',
  rolePermissionDetails: '/api/admin-service/role-permission-details',
  roleKeyValuePair: '/api/admin-service/role/role-key-value',
  rolePermissionUpdate: '/api/admin-service/set-permission',
  fetchPermission: '/api/admin-service/role-permission-details',

  packagesList: '/api/admin-service/packages/list',
  addPackage: '/api/admin-service/packages/create',

  videoList: '/api/admin-service/video/list',
  activeInActiveVideo: '/api/admin-service/video/active-inactive',

  subAdminByRole: '/api/admin-service/subadmin/key-value-by-role',
  subAdminList: '/api/admin-service/subadmin/get/all/paged',
  subAdminAdd: '/api/admin-service/subadmin/create',
  subAdminChangeStatus: '/api/admin-service/subadmin/status',
  subAdminLogDetails: '/api/admin-service/subadmin/log-details',
  subAdminDelete: '/api/admin-service/subadmin/delete/by/id/',

  addFilter: '/api/admin-service/filter/add-filter',
  filterList: '/api/admin-service/filter/filter-list',
  changeFilterStatus: '/api/admin-service/filter/change/status',
  deleteFilter: '/api/admin-service/filter/delete/by/id',
  addNotification: '/api/admin-service/add/notification',
  notificationList: '/api/admin-service/notification-list',

  addEffect: '/api/admin-service/effects/add',
  effectList: '/api/admin-service/effects/effect-list',
  changeEffectStatus: '/api/admin-service/effects/change/status',
  getEffect: '/api/admin-service/effects/get/by',
  deleteEffect: '/api/admin-service/effects/delete/by/id',

  addFontStyle: '/api/admin-service/font-style/add',
  fontStyleList: '/api/admin-service/font-style/list',
  changeFontStyleStatus: '/api/admin-service/font-style/change/status',
  getFontStyle: '/api/admin-service/font-style/get/by',
  deleteFontStyle: '/api/admin-service/font-style/delete/by',

  addSound: '/api/admin-service/sounds/add',
  soundList: '/api/admin-service/sounds/sound-list',
  // soundList: '/api/admin-service/sound-stripe/sound-list',
  changeSoundStatus: '/api/admin-service/sound-stripe/change/status',
  getSound: '/api/admin-service/sounds/get/by',
  deleteSound: '/api/admin-service/sounds/delete/by/id',

  reportAbuseList: '/api/admin-service/report-abuse/list',
  abuseReportList: '/api/admin-service/report-abuse/reporters-list',

  activeInActiveReport: '/api/admin-service/report-abuse/active-inactive',

  userList: '/api/admin-service/users/user-list',
  userDetails: '/api/admin-service/users/user-by-id',
  userRecentTop3Videos: '/api/admin-service/video/recent-user-videos',
  userActiveInActive: '/api/admin-service/users/change/status',
  userCelebrityStatusChange: '/api/admin-service/users/celebrity/status',

  addWidget: '/api/admin-service/widget/add-widget',
  widgetList: '/api/admin-service/widget/widget-list',
  widgetKeyValuePair: '/api/admin-service/widget/key-value',
  changeWidgetStatus: '/api/admin-service/widget/change/status',
  getWidget: '/api/admin-service/widget/get/by',
  deleteWidget: '/api/admin-service/widget/delete/by/id',

  countryList: '/api/admin-service/country/country-list',
  addCountry: '/api/admin-service/country/add',
  changeCountryStatus: '/api/admin-service/country/change/status',
  countryCodeList: '/api/admin-service/country/country-code-list',

  stateList: '/api/admin-service/state/state-list',
  addState: '/api/admin-service/state/add',
  changeStateStatus: '/api/admin-service/state/change/status',
  stateCodeList: '/api/admin-service/state/state-key-value',
  stateCodeListByCountryId: '/api/admin-service/state/list-by-countryId',
  deleteState: '/api/admin-service/state/delete/by/id',


  getAllTypeOfMatrix: '/api/admin-service/globalAds/get-all-type',
  getAllTypeOfMatrixGlobalRate: '/api/admin-service/globalAds/get-config-global-matrix-rate',
  setMatrixGlobalWorldRate: '/api/admin-service/globalAds/set-config-global-matrix-rate',
  getConfigGLobalAdsMatrix: '/api/admin-service/globalAds/get-config-global-ads-matrix',
  configGlobalAdsMatrix: '/api/admin-service/globalAds/config-global-ads-matrix',
  adminUploadAds: '/api/admin-service/globalAds/admin-upload-ads',

  cityList: '/api/admin-service/city/city-list',
  addCity: '/api/admin-service/city/add',
  changeCityStatus: '/api/admin-service/city/change/status',
  cityCodeList: '/api/admin-service/city/state-key-value',
  cityCodeListByCountryId: '/api/admin-service/city/list-by-countryId',
  deleteCity: '/api/admin-service/city/delete/by/id',
  getCityCodeListByParent: '/api/admin-service/city/list-by-parentId',


  districtList: '/api/admin-service/district/district-list',
  addDistrict: '/api/admin-service/district/add',
  changeDistrictStatus: '/api/admin-service/district/change/status',
  districtCodeList: '/api/admin-service/district/district-key-value',
  deleteDistrict: '/api/admin-service/district/delete/by/id',
  getDistrictByStateId: '/api/admin-service/district/list-by-stateId',

  addTagInterest: '/api/admin-service/tags/add',
  tagInterestList: '/api/admin-service/tags/tag-list',
  changeTagInterestStatus: '/api/admin-service/tags/change/status',

  addLanguage: '/api/admin-service/language/add',
  languageList: '/api/admin-service/language/language-list',
  changeLanguageStatus: '/api/admin-service/language/change/status',
  deleteLanguage: '/api/admin-service/language/delete/by/id',

  addBanner: '/api/admin-service/banners/add',
  bannerList: '/api/admin-service/banners/banner-list',
  changeBannerStatus: '/api/admin-service/banners/change/status',

  addSticker: '/api/admin-service/stickers/add',
  stickerList: '/api/admin-service/stickers/list',
  meisheAssetsList: '/api/admin-service/meishe/list-admin',
  changeMeisheAssetsStatus: '/api/admin-service/meishe/status',
  deleteSticker: '/api/admin-service/stickers/delete/by/id',

  addFaq: '/api/admin-service/faqs/add',
  faqList: '/api/admin-service/faqs/faq-list',
  changeFaqStatus: '/api/admin-service/faqs/change/status',
  updateSequence: '/api/admin-service/faqs/update-seq',
  faqById: '/api/admin-service/faqs/get/by',

  uploadImage: '/api/admin-service/blogs/upload-image',
  addBlog: '/api/admin-service/blogs/add',
  blogList: '/api/admin-service/blogs/blog-list',
  changeBlogStatus: '/api/admin-service/blogs/change/status',

  addAds: '/api/admin-service/ads/add',
  adsList: '/api/admin-service/ads/ad-list',
  changeAdsStatus: '/api/admin-service/ads/change/status',
  changeInfluencerAdsStatus: '/api/admin-service/ads/influencer-change/status',
  deleteAds: '/api/admin-service/ads/delete/by/id',


  globalAdsList: '/api/admin-service/globalAds/admin-ads-list',

  influencerRequestList: '/api/admin-service/users/influencer/request-list',
  influencerRequestAcceptOrReject: '/api/admin-service/users/influencer/accept',

  userSearchDropdownList:'/api/admin-service/users/user-list-search',
  sendBatchPushNotification:'/api/admin-service/users/send-batch-push-notification-user',

  updateStaticContent: '/api/admin-service/contents/add',
  staticContentList: '/api/admin-service/contents/static-list',

  setGlobalSetting: '/api/admin-service/settings/add',
  getGlobalSetting: '/api/admin-service/settings/get-details',

  getTypeOfAdvertisement: '/api/admin-service/globalAds/typeOfAdvertisement',

  updateEmail: '/api/admin-service/email-template/add',
  emailList: '/api/admin-service/email-template/template-list',
  testAddUser: '/api/user-service/users/signup-complete',

  withdrawTransactioNList: '/api/admin-service/withdraw/transaction-list',
  totalWithdrawCommission:
    '/api/admin-service/withdraw/get-total-withdraw-commission',
  enableDisableMfaPayStack:
    '/api/admin-service/bank/enable-disable-otp-on-transaction',
  verifyMfaPayStackOtp: '/api/admin-service/bank/verify-disable-otp',
  paystackAvailableBalance: '/api/admin-service/bank/balance',
  approveDeclineTransaction:
    '/api/admin-service/withdraw/approve-denied',
  finalApproveTransactionVerification:
    '/api/admin-service/bank/final-approve-transaction',
  topUpUserWallet: '/api/admin-service/beads/top-up',

  purchaseBeadsList: '/api/admin-service/beads/purchase-history',
  liveManagerList: '/api/admin-service/live/list',
  // beadsTransactionHistory: '/api/admin-service/beads/transaction-history',
  beadsTransactionHistory: '/api/admin-service/transaction/history',
  amountTransactionHistory: '/api/admin-service/transaction/amount-history',
  addMeisheAssets: '/api/admin-service/meishe/add',
  updateMeisheAssets: '/api/admin-service/meishe/update'
}
export default urls
