import AddIcon from '@mui/icons-material/Add'
import ReplyIcon from '@mui/icons-material/Reply'
import Checkbox from '@mui/material/Checkbox'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } }
export const RolePermissions = ({
  submitPermissionControl,
  setViewPermissionControl,
  setPermissionSubmitData,
  permissionSubmitData,
  rolePermissionData
}) => {
  const handlePermissionClick = (e, item) => {
    const isEnabled = e.target.checked
    const roleModulePermissionId = item?.permissionId
    const index = permissionSubmitData.findIndex(
      obj => obj.roleModulePermissionId === roleModulePermissionId
    )
    if (index !== -1) {
      permissionSubmitData[index].isEnabled = isEnabled
    } else {
      let data = {
        isEnabled,
        roleModulePermissionId
      }

      setPermissionSubmitData([...permissionSubmitData, data])
    }
  }

  return (
    <>
      <div className='container-fluid mt--7'>
        <div className='row'>
          <div className='col text-left'>
            <div className='card shadow'>
              <div className='card-header border-0'>
                <div className='row align-items-center text-left'>
                  <div className='col text-left'>
                    <a
                      onClick={() => setViewPermissionControl(false)}
                      className='btn btn-sm btn-primary text-dark'
                    >
                      <ReplyIcon />
                       Cancel
                    </a>
                  </div>
                  <div className='col'>
                    <h3 className='mb-0'>Permission Control</h3>
                  </div>
                  <div className='col text-right'>
                    <a
                      onClick={submitPermissionControl}
                      className='btn btn-sm btn-primary text-dark'
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
              <div className='table-responsive'>
                <table className='table align-items-center table-flush'>
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Manager</th>
                      <th scope='col'>Active/InActive</th>
                      <th scope='col'>Delete</th>
                      <th scope='col'>Add/Edit</th>
                      <th scope='col'>View</th>
                      {/* <th scope='col'>All</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {rolePermissionData?.modules?.map((data, i) => {
                      return (
                        <>
                          <tr>
                            <th>{data?.name}</th>
                            {data?.permissions?.map((per, i) => {
                              return (
                                <td>
                                  <Checkbox
                                    {...label}
                                    onChange={e =>
                                      handlePermissionClick(e, per)
                                    }
                                    defaultChecked={per?.isEnable}
                                  />
                                </td>
                              )
                            })}
                            {/* <td>
                              <Checkbox {...label} defaultChecked />
                            </td> */}
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
